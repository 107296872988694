import apolloClient from '../apolloClient';
import {
  CreatePaymentIntentDocument,
  CreatePaymentIntentMutationVariables,
} from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function createPaymentIntent({
  products,
}: CreatePaymentIntentMutationVariables) {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: CreatePaymentIntentDocument,
    variables: { products },
  });

  if (!data) {
    throw new ApiError('Unknown placing order');
  }

  return data.createPaymentIntent;
}

export default throwKnownApiErrors(createPaymentIntent);
