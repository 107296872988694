/* eslint-disable import/no-extraneous-dependencies, import/newline-after-import, @typescript-eslint/naming-convention */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { [key: string]: string };
  _rgb_int: [number, number, number];
  citext: string;
  dimensions: { height: number; width: number };
  geography:
    | {
        coordinates: [[number, number], [number, number]][];
        type: 'MultiLineString';
      }
    | { coordinates: [number, number][]; type: 'MultiPoint' };
  geometry:
    | {
        coordinates: [[number, number], [number, number]][];
        type: 'MultiLineString';
      }
    | { coordinates: [number, number][]; type: 'MultiPoint' };
  jsonb: [number, number, number];
  numeric: number;
  rectangle_spacing: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  rgba_color: { color: [number, number, number]; alpha: number };
  smallint: number;
  timestamptz: string;
  uuid: string;
};

export type BooleanResponse = {
  __typename?: 'BooleanResponse';
  success: Scalars['Boolean'];
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export enum CardBrands {
  Amex = 'amex',
  Diners = 'diners',
  Discover = 'discover',
  Jcb = 'jcb',
  Mastercard = 'mastercard',
  Unionpay = 'unionpay',
  Unknown = 'unknown',
  Visa = 'visa',
}

export type CheckEmailResponse = {
  __typename?: 'CheckEmailResponse';
  exists: Scalars['Boolean'];
};

export type CompleteFileUploadResponse = {
  __typename?: 'CompleteFileUploadResponse';
  id: Scalars['String'];
};

export type CreatePaymentIntentResponse = {
  __typename?: 'CreatePaymentIntentResponse';
  clientSecret: Scalars['String'];
  orderId: Scalars['String'];
};

export type CurrentUserRoleResponse = {
  __typename?: 'CurrentUserRoleResponse';
  role: UserRoles;
};

export type FileInput = {
  data: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type FileUploadPresignedPostResponse = {
  __typename?: 'FileUploadPresignedPostResponse';
  fields: Scalars['JSONObject'];
  key: Scalars['String'];
  url: Scalars['String'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelPaymentIntent: BooleanResponse;
  checkSubscriptionStatus: BooleanResponse;
  claimProducts: BooleanResponse;
  completeFileUpload: CompleteFileUploadResponse;
  createFirstTimeSession: UserResponse;
  createPaymentIntent: CreatePaymentIntentResponse;
  endSession: BooleanResponse;
  refreshOrderStatus: BooleanResponse;
  sendAuthCode: BooleanResponse;
  signUp: User;
  verifyAuthCode: VerifyAuthCodeResponse;
};

export type MutationClaimProductsArgs = {
  productIds: Array<Scalars['String']>;
};

export type MutationCompleteFileUploadArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
};

export type MutationCreateFirstTimeSessionArgs = {
  email: Scalars['String'];
};

export type MutationCreatePaymentIntentArgs = {
  products: Array<ProductInput>;
};

export type MutationRefreshOrderStatusArgs = {
  id: Scalars['String'];
};

export type MutationSendAuthCodeArgs = {
  email: Scalars['String'];
  intent: Scalars['String'];
};

export type MutationSignUpArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  productIds: Array<Scalars['String']>;
};

export type MutationVerifyAuthCodeArgs = {
  code: Scalars['Int'];
  email: Scalars['String'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  brand: CardBrands;
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
};

export type ProductInput = {
  currency: Scalars['String'];
  id: Scalars['String'];
  price: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  checkEmail: CheckEmailResponse;
  currentUserRole: CurrentUserRoleResponse;
  fileUploadPresignedPost?: Maybe<FileUploadPresignedPostResponse>;
  paymentMethods: Array<PaymentMethod>;
};

export type QueryCheckEmailArgs = {
  email: Scalars['String'];
};

export type QueryFileUploadPresignedPostArgs = {
  fileId?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  user: User;
};

export enum UserRoles {
  Admin = 'admin',
  Anonymous = 'anonymous',
  User = 'user',
}

export type VerifyAuthCodeResponse = {
  __typename?: 'VerifyAuthCodeResponse';
  intent: Scalars['String'];
  user: User;
};

/** expression to compare columns of type _rgb_int. All fields are combined with logical 'AND'. */
export type RgbIntComparisonExp = {
  _eq?: Maybe<Scalars['_rgb_int']>;
  _gt?: Maybe<Scalars['_rgb_int']>;
  _gte?: Maybe<Scalars['_rgb_int']>;
  _in?: Maybe<Array<Scalars['_rgb_int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_rgb_int']>;
  _lte?: Maybe<Scalars['_rgb_int']>;
  _neq?: Maybe<Scalars['_rgb_int']>;
  _nin?: Maybe<Array<Scalars['_rgb_int']>>;
};

/** columns and relationships of "auth_codes" */
export type AuthCodes = {
  __typename?: 'auth_codes';
  attempts_made: Scalars['Int'];
  code: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  intent: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  valid_until: Scalars['timestamptz'];
};

/** aggregated selection of "auth_codes" */
export type AuthCodesAggregate = {
  __typename?: 'auth_codes_aggregate';
  aggregate?: Maybe<AuthCodesAggregateFields>;
  nodes: Array<AuthCodes>;
};

/** aggregate fields of "auth_codes" */
export type AuthCodesAggregateFields = {
  __typename?: 'auth_codes_aggregate_fields';
  avg?: Maybe<AuthCodesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<AuthCodesMaxFields>;
  min?: Maybe<AuthCodesMinFields>;
  stddev?: Maybe<AuthCodesStddevFields>;
  stddev_pop?: Maybe<AuthCodesStddevPopFields>;
  stddev_samp?: Maybe<AuthCodesStddevSampFields>;
  sum?: Maybe<AuthCodesSumFields>;
  var_pop?: Maybe<AuthCodesVarPopFields>;
  var_samp?: Maybe<AuthCodesVarSampFields>;
  variance?: Maybe<AuthCodesVarianceFields>;
};

/** aggregate fields of "auth_codes" */
export type AuthCodesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<AuthCodesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth_codes" */
export type AuthCodesAggregateOrderBy = {
  avg?: Maybe<AuthCodesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<AuthCodesMaxOrderBy>;
  min?: Maybe<AuthCodesMinOrderBy>;
  stddev?: Maybe<AuthCodesStddevOrderBy>;
  stddev_pop?: Maybe<AuthCodesStddevPopOrderBy>;
  stddev_samp?: Maybe<AuthCodesStddevSampOrderBy>;
  sum?: Maybe<AuthCodesSumOrderBy>;
  var_pop?: Maybe<AuthCodesVarPopOrderBy>;
  var_samp?: Maybe<AuthCodesVarSampOrderBy>;
  variance?: Maybe<AuthCodesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "auth_codes" */
export type AuthCodesArrRelInsertInput = {
  data: Array<AuthCodesInsertInput>;
  on_conflict?: Maybe<AuthCodesOnConflict>;
};

/** aggregate avg on columns */
export type AuthCodesAvgFields = {
  __typename?: 'auth_codes_avg_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "auth_codes" */
export type AuthCodesAvgOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "auth_codes". All fields are combined with a logical 'AND'. */
export type AuthCodesBoolExp = {
  _and?: Maybe<Array<Maybe<AuthCodesBoolExp>>>;
  _not?: Maybe<AuthCodesBoolExp>;
  _or?: Maybe<Array<Maybe<AuthCodesBoolExp>>>;
  attempts_made?: Maybe<IntComparisonExp>;
  code?: Maybe<IntComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  intent?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
  valid_until?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "auth_codes" */
export enum AuthCodesConstraint {
  /** unique or primary key constraint */
  AuthCodesPkey = 'auth_codes_pkey',
  /** unique or primary key constraint */
  AuthCodesUserIdKey = 'auth_codes_user_id_key',
}

/** input type for incrementing integer column in table "auth_codes" */
export type AuthCodesIncInput = {
  attempts_made?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "auth_codes" */
export type AuthCodesInsertInput = {
  attempts_made?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intent?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type AuthCodesMaxFields = {
  __typename?: 'auth_codes_max_fields';
  attempts_made?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intent?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth_codes" */
export type AuthCodesMaxOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intent?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  valid_until?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthCodesMinFields = {
  __typename?: 'auth_codes_min_fields';
  attempts_made?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intent?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth_codes" */
export type AuthCodesMinOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intent?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
  valid_until?: Maybe<OrderBy>;
};

/** response of any mutation on the table "auth_codes" */
export type AuthCodesMutationResponse = {
  __typename?: 'auth_codes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<AuthCodes>;
};

/** input type for inserting object relation for remote table "auth_codes" */
export type AuthCodesObjRelInsertInput = {
  data: AuthCodesInsertInput;
  on_conflict?: Maybe<AuthCodesOnConflict>;
};

/** on conflict condition type for table "auth_codes" */
export type AuthCodesOnConflict = {
  constraint: AuthCodesConstraint;
  update_columns: Array<AuthCodesUpdateColumn>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** ordering options when selecting data from "auth_codes" */
export type AuthCodesOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  intent?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
  valid_until?: Maybe<OrderBy>;
};

/** primary key columns input for table: "auth_codes" */
export type AuthCodesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "auth_codes" */
export enum AuthCodesSelectColumn {
  /** column name */
  AttemptsMade = 'attempts_made',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Intent = 'intent',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidUntil = 'valid_until',
}

/** input type for updating data in table "auth_codes" */
export type AuthCodesSetInput = {
  attempts_made?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  intent?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  valid_until?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type AuthCodesStddevFields = {
  __typename?: 'auth_codes_stddev_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "auth_codes" */
export type AuthCodesStddevOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type AuthCodesStddevPopFields = {
  __typename?: 'auth_codes_stddev_pop_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "auth_codes" */
export type AuthCodesStddevPopOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type AuthCodesStddevSampFields = {
  __typename?: 'auth_codes_stddev_samp_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "auth_codes" */
export type AuthCodesStddevSampOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type AuthCodesSumFields = {
  __typename?: 'auth_codes_sum_fields';
  attempts_made?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "auth_codes" */
export type AuthCodesSumOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** update columns of table "auth_codes" */
export enum AuthCodesUpdateColumn {
  /** column name */
  AttemptsMade = 'attempts_made',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Intent = 'intent',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidUntil = 'valid_until',
}

/** aggregate var_pop on columns */
export type AuthCodesVarPopFields = {
  __typename?: 'auth_codes_var_pop_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "auth_codes" */
export type AuthCodesVarPopOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type AuthCodesVarSampFields = {
  __typename?: 'auth_codes_var_samp_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "auth_codes" */
export type AuthCodesVarSampOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type AuthCodesVarianceFields = {
  __typename?: 'auth_codes_variance_fields';
  attempts_made?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "auth_codes" */
export type AuthCodesVarianceOrderBy = {
  attempts_made?: Maybe<OrderBy>;
  code?: Maybe<OrderBy>;
};

/** columns and relationships of "brand_colors" */
export type BrandColors = {
  __typename?: 'brand_colors';
  /** An object relationship */
  brand: Brands;
  brand_id: Scalars['uuid'];
  color: Scalars['_rgb_int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  reference_id: Scalars['String'];
  /** An array relationship */
  threads: Array<Threads>;
  /** An aggregated array relationship */
  threads_aggregate: ThreadsAggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "brand_colors" */
export type BrandColorsThreadsArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** columns and relationships of "brand_colors" */
export type BrandColorsThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** aggregated selection of "brand_colors" */
export type BrandColorsAggregate = {
  __typename?: 'brand_colors_aggregate';
  aggregate?: Maybe<BrandColorsAggregateFields>;
  nodes: Array<BrandColors>;
};

/** aggregate fields of "brand_colors" */
export type BrandColorsAggregateFields = {
  __typename?: 'brand_colors_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<BrandColorsMaxFields>;
  min?: Maybe<BrandColorsMinFields>;
};

/** aggregate fields of "brand_colors" */
export type BrandColorsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BrandColorsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "brand_colors" */
export type BrandColorsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<BrandColorsMaxOrderBy>;
  min?: Maybe<BrandColorsMinOrderBy>;
};

/** input type for inserting array relation for remote table "brand_colors" */
export type BrandColorsArrRelInsertInput = {
  data: Array<BrandColorsInsertInput>;
  on_conflict?: Maybe<BrandColorsOnConflict>;
};

/** Boolean expression to filter rows from the table "brand_colors". All fields are combined with a logical 'AND'. */
export type BrandColorsBoolExp = {
  _and?: Maybe<Array<Maybe<BrandColorsBoolExp>>>;
  _not?: Maybe<BrandColorsBoolExp>;
  _or?: Maybe<Array<Maybe<BrandColorsBoolExp>>>;
  brand?: Maybe<BrandsBoolExp>;
  brand_id?: Maybe<UuidComparisonExp>;
  color?: Maybe<RgbIntComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  reference_id?: Maybe<StringComparisonExp>;
  threads?: Maybe<ThreadsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "brand_colors" */
export enum BrandColorsConstraint {
  /** unique or primary key constraint */
  BrandColorsPkey = 'brand_colors_pkey',
  /** unique or primary key constraint */
  BrandColorsReferenceIdBrandIdKey = 'brand_colors_reference_id_brand_id_key',
}

/** input type for inserting data into table "brand_colors" */
export type BrandColorsInsertInput = {
  brand?: Maybe<BrandsObjRelInsertInput>;
  brand_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['_rgb_int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['String']>;
  threads?: Maybe<ThreadsArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BrandColorsMaxFields = {
  __typename?: 'brand_colors_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "brand_colors" */
export type BrandColorsMaxOrderBy = {
  brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  reference_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BrandColorsMinFields = {
  __typename?: 'brand_colors_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "brand_colors" */
export type BrandColorsMinOrderBy = {
  brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  reference_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "brand_colors" */
export type BrandColorsMutationResponse = {
  __typename?: 'brand_colors_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<BrandColors>;
};

/** input type for inserting object relation for remote table "brand_colors" */
export type BrandColorsObjRelInsertInput = {
  data: BrandColorsInsertInput;
  on_conflict?: Maybe<BrandColorsOnConflict>;
};

/** on conflict condition type for table "brand_colors" */
export type BrandColorsOnConflict = {
  constraint: BrandColorsConstraint;
  update_columns: Array<BrandColorsUpdateColumn>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** ordering options when selecting data from "brand_colors" */
export type BrandColorsOrderBy = {
  brand?: Maybe<BrandsOrderBy>;
  brand_id?: Maybe<OrderBy>;
  color?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  reference_id?: Maybe<OrderBy>;
  threads_aggregate?: Maybe<ThreadsAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "brand_colors" */
export type BrandColorsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "brand_colors" */
export enum BrandColorsSelectColumn {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "brand_colors" */
export type BrandColorsSetInput = {
  brand_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['_rgb_int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  reference_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "brand_colors" */
export enum BrandColorsUpdateColumn {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ReferenceId = 'reference_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "brand_lines" */
export type BrandLines = {
  __typename?: 'brand_lines';
  /** An object relationship */
  brand: Brands;
  brand_id: Scalars['uuid'];
  /** A computed field, executes function "brand_line_brand_name" */
  brand_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  threads: Array<Threads>;
  /** An aggregated array relationship */
  threads_aggregate: ThreadsAggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "brand_lines" */
export type BrandLinesThreadsArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** columns and relationships of "brand_lines" */
export type BrandLinesThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** aggregated selection of "brand_lines" */
export type BrandLinesAggregate = {
  __typename?: 'brand_lines_aggregate';
  aggregate?: Maybe<BrandLinesAggregateFields>;
  nodes: Array<BrandLines>;
};

/** aggregate fields of "brand_lines" */
export type BrandLinesAggregateFields = {
  __typename?: 'brand_lines_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<BrandLinesMaxFields>;
  min?: Maybe<BrandLinesMinFields>;
};

/** aggregate fields of "brand_lines" */
export type BrandLinesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BrandLinesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "brand_lines" */
export type BrandLinesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<BrandLinesMaxOrderBy>;
  min?: Maybe<BrandLinesMinOrderBy>;
};

/** input type for inserting array relation for remote table "brand_lines" */
export type BrandLinesArrRelInsertInput = {
  data: Array<BrandLinesInsertInput>;
  on_conflict?: Maybe<BrandLinesOnConflict>;
};

/** Boolean expression to filter rows from the table "brand_lines". All fields are combined with a logical 'AND'. */
export type BrandLinesBoolExp = {
  _and?: Maybe<Array<Maybe<BrandLinesBoolExp>>>;
  _not?: Maybe<BrandLinesBoolExp>;
  _or?: Maybe<Array<Maybe<BrandLinesBoolExp>>>;
  brand?: Maybe<BrandsBoolExp>;
  brand_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  threads?: Maybe<ThreadsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "brand_lines" */
export enum BrandLinesConstraint {
  /** unique or primary key constraint */
  BrandLinesBrandIdNameKey = 'brand_lines_brand_id_name_key',
  /** unique or primary key constraint */
  BrandLinesPkey = 'brand_lines_pkey',
}

/** input type for inserting data into table "brand_lines" */
export type BrandLinesInsertInput = {
  brand?: Maybe<BrandsObjRelInsertInput>;
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  threads?: Maybe<ThreadsArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BrandLinesMaxFields = {
  __typename?: 'brand_lines_max_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "brand_lines" */
export type BrandLinesMaxOrderBy = {
  brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BrandLinesMinFields = {
  __typename?: 'brand_lines_min_fields';
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "brand_lines" */
export type BrandLinesMinOrderBy = {
  brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "brand_lines" */
export type BrandLinesMutationResponse = {
  __typename?: 'brand_lines_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<BrandLines>;
};

/** input type for inserting object relation for remote table "brand_lines" */
export type BrandLinesObjRelInsertInput = {
  data: BrandLinesInsertInput;
  on_conflict?: Maybe<BrandLinesOnConflict>;
};

/** on conflict condition type for table "brand_lines" */
export type BrandLinesOnConflict = {
  constraint: BrandLinesConstraint;
  update_columns: Array<BrandLinesUpdateColumn>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** ordering options when selecting data from "brand_lines" */
export type BrandLinesOrderBy = {
  brand?: Maybe<BrandsOrderBy>;
  brand_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  threads_aggregate?: Maybe<ThreadsAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "brand_lines" */
export type BrandLinesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "brand_lines" */
export enum BrandLinesSelectColumn {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "brand_lines" */
export type BrandLinesSetInput = {
  brand_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "brand_lines" */
export enum BrandLinesUpdateColumn {
  /** column name */
  BrandId = 'brand_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "brands" */
export type Brands = {
  __typename?: 'brands';
  /** An array relationship */
  brand_colors: Array<BrandColors>;
  /** An aggregated array relationship */
  brand_colors_aggregate: BrandColorsAggregate;
  /** An array relationship */
  brand_lines: Array<BrandLines>;
  /** An aggregated array relationship */
  brand_lines_aggregate: BrandLinesAggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  long_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "brands" */
export type BrandsBrandColorsArgs = {
  distinct_on?: Maybe<Array<BrandColorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandColorsOrderBy>>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** columns and relationships of "brands" */
export type BrandsBrandColorsAggregateArgs = {
  distinct_on?: Maybe<Array<BrandColorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandColorsOrderBy>>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** columns and relationships of "brands" */
export type BrandsBrandLinesArgs = {
  distinct_on?: Maybe<Array<BrandLinesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandLinesOrderBy>>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** columns and relationships of "brands" */
export type BrandsBrandLinesAggregateArgs = {
  distinct_on?: Maybe<Array<BrandLinesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandLinesOrderBy>>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** aggregated selection of "brands" */
export type BrandsAggregate = {
  __typename?: 'brands_aggregate';
  aggregate?: Maybe<BrandsAggregateFields>;
  nodes: Array<Brands>;
};

/** aggregate fields of "brands" */
export type BrandsAggregateFields = {
  __typename?: 'brands_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<BrandsMaxFields>;
  min?: Maybe<BrandsMinFields>;
};

/** aggregate fields of "brands" */
export type BrandsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<BrandsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "brands" */
export type BrandsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<BrandsMaxOrderBy>;
  min?: Maybe<BrandsMinOrderBy>;
};

/** input type for inserting array relation for remote table "brands" */
export type BrandsArrRelInsertInput = {
  data: Array<BrandsInsertInput>;
  on_conflict?: Maybe<BrandsOnConflict>;
};

/** Boolean expression to filter rows from the table "brands". All fields are combined with a logical 'AND'. */
export type BrandsBoolExp = {
  _and?: Maybe<Array<Maybe<BrandsBoolExp>>>;
  _not?: Maybe<BrandsBoolExp>;
  _or?: Maybe<Array<Maybe<BrandsBoolExp>>>;
  brand_colors?: Maybe<BrandColorsBoolExp>;
  brand_lines?: Maybe<BrandLinesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  long_name?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "brands" */
export enum BrandsConstraint {
  /** unique or primary key constraint */
  BrandsLongNameKey = 'brands_long_name_key',
  /** unique or primary key constraint */
  BrandsNameKey = 'brands_name_key',
  /** unique or primary key constraint */
  BrandsPkey = 'brands_pkey',
}

/** input type for inserting data into table "brands" */
export type BrandsInsertInput = {
  brand_colors?: Maybe<BrandColorsArrRelInsertInput>;
  brand_lines?: Maybe<BrandLinesArrRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type BrandsMaxFields = {
  __typename?: 'brands_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "brands" */
export type BrandsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  long_name?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type BrandsMinFields = {
  __typename?: 'brands_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "brands" */
export type BrandsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  long_name?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "brands" */
export type BrandsMutationResponse = {
  __typename?: 'brands_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Brands>;
};

/** input type for inserting object relation for remote table "brands" */
export type BrandsObjRelInsertInput = {
  data: BrandsInsertInput;
  on_conflict?: Maybe<BrandsOnConflict>;
};

/** on conflict condition type for table "brands" */
export type BrandsOnConflict = {
  constraint: BrandsConstraint;
  update_columns: Array<BrandsUpdateColumn>;
  where?: Maybe<BrandsBoolExp>;
};

/** ordering options when selecting data from "brands" */
export type BrandsOrderBy = {
  brand_colors_aggregate?: Maybe<BrandColorsAggregateOrderBy>;
  brand_lines_aggregate?: Maybe<BrandLinesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  long_name?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "brands" */
export type BrandsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "brands" */
export enum BrandsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LongName = 'long_name',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "brands" */
export type BrandsSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  long_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "brands" */
export enum BrandsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LongName = 'long_name',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "charts" */
export type Charts = {
  __typename?: 'charts';
  created_at: Scalars['timestamptz'];
  has_fractional_stitches: Scalars['Boolean'];
  height: Scalars['Int'];
  id: Scalars['uuid'];
  import_fabric_color: Scalars['_rgb_int'];
  instructions?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object relationship */
  product?: Maybe<Products>;
  /** An array relationship */
  stitch_groups: Array<StitchGroups>;
  /** An aggregated array relationship */
  stitch_groups_aggregate: StitchGroupsAggregate;
  /** An array relationship */
  themes: Array<Themes>;
  /** An aggregated array relationship */
  themes_aggregate: ThemesAggregate;
  updated_at: Scalars['timestamptz'];
  width: Scalars['Int'];
};

/** columns and relationships of "charts" */
export type ChartsStitchGroupsArgs = {
  distinct_on?: Maybe<Array<StitchGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchGroupsOrderBy>>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** columns and relationships of "charts" */
export type ChartsStitchGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<StitchGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchGroupsOrderBy>>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** columns and relationships of "charts" */
export type ChartsThemesArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** columns and relationships of "charts" */
export type ChartsThemesAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** aggregated selection of "charts" */
export type ChartsAggregate = {
  __typename?: 'charts_aggregate';
  aggregate?: Maybe<ChartsAggregateFields>;
  nodes: Array<Charts>;
};

/** aggregate fields of "charts" */
export type ChartsAggregateFields = {
  __typename?: 'charts_aggregate_fields';
  avg?: Maybe<ChartsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ChartsMaxFields>;
  min?: Maybe<ChartsMinFields>;
  stddev?: Maybe<ChartsStddevFields>;
  stddev_pop?: Maybe<ChartsStddevPopFields>;
  stddev_samp?: Maybe<ChartsStddevSampFields>;
  sum?: Maybe<ChartsSumFields>;
  var_pop?: Maybe<ChartsVarPopFields>;
  var_samp?: Maybe<ChartsVarSampFields>;
  variance?: Maybe<ChartsVarianceFields>;
};

/** aggregate fields of "charts" */
export type ChartsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ChartsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "charts" */
export type ChartsAggregateOrderBy = {
  avg?: Maybe<ChartsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ChartsMaxOrderBy>;
  min?: Maybe<ChartsMinOrderBy>;
  stddev?: Maybe<ChartsStddevOrderBy>;
  stddev_pop?: Maybe<ChartsStddevPopOrderBy>;
  stddev_samp?: Maybe<ChartsStddevSampOrderBy>;
  sum?: Maybe<ChartsSumOrderBy>;
  var_pop?: Maybe<ChartsVarPopOrderBy>;
  var_samp?: Maybe<ChartsVarSampOrderBy>;
  variance?: Maybe<ChartsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "charts" */
export type ChartsArrRelInsertInput = {
  data: Array<ChartsInsertInput>;
  on_conflict?: Maybe<ChartsOnConflict>;
};

/** aggregate avg on columns */
export type ChartsAvgFields = {
  __typename?: 'charts_avg_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "charts" */
export type ChartsAvgOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "charts". All fields are combined with a logical 'AND'. */
export type ChartsBoolExp = {
  _and?: Maybe<Array<Maybe<ChartsBoolExp>>>;
  _not?: Maybe<ChartsBoolExp>;
  _or?: Maybe<Array<Maybe<ChartsBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  has_fractional_stitches?: Maybe<BooleanComparisonExp>;
  height?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  import_fabric_color?: Maybe<RgbIntComparisonExp>;
  instructions?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  product?: Maybe<ProductsBoolExp>;
  stitch_groups?: Maybe<StitchGroupsBoolExp>;
  themes?: Maybe<ThemesBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  width?: Maybe<IntComparisonExp>;
};

/** unique or primary key constraints on table "charts" */
export enum ChartsConstraint {
  /** unique or primary key constraint */
  ChartsPkey = 'charts_pkey',
}

/** input type for incrementing integer column in table "charts" */
export type ChartsIncInput = {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "charts" */
export type ChartsInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  has_fractional_stitches?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  import_fabric_color?: Maybe<Scalars['_rgb_int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  product?: Maybe<ProductsObjRelInsertInput>;
  stitch_groups?: Maybe<StitchGroupsArrRelInsertInput>;
  themes?: Maybe<ThemesArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ChartsMaxFields = {
  __typename?: 'charts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "charts" */
export type ChartsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instructions?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ChartsMinFields = {
  __typename?: 'charts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "charts" */
export type ChartsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instructions?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** response of any mutation on the table "charts" */
export type ChartsMutationResponse = {
  __typename?: 'charts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Charts>;
};

/** input type for inserting object relation for remote table "charts" */
export type ChartsObjRelInsertInput = {
  data: ChartsInsertInput;
  on_conflict?: Maybe<ChartsOnConflict>;
};

/** on conflict condition type for table "charts" */
export type ChartsOnConflict = {
  constraint: ChartsConstraint;
  update_columns: Array<ChartsUpdateColumn>;
  where?: Maybe<ChartsBoolExp>;
};

/** ordering options when selecting data from "charts" */
export type ChartsOrderBy = {
  created_at?: Maybe<OrderBy>;
  has_fractional_stitches?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  import_fabric_color?: Maybe<OrderBy>;
  instructions?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  product?: Maybe<ProductsOrderBy>;
  stitch_groups_aggregate?: Maybe<StitchGroupsAggregateOrderBy>;
  themes_aggregate?: Maybe<ThemesAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** primary key columns input for table: "charts" */
export type ChartsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** columns and relationships of "charts_public" */
export type ChartsPublic = {
  __typename?: 'charts_public';
  created_at?: Maybe<Scalars['timestamptz']>;
  has_fractional_stitches?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  import_fabric_color?: Maybe<Scalars['_rgb_int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "charts_public" */
export type ChartsPublicAggregate = {
  __typename?: 'charts_public_aggregate';
  aggregate?: Maybe<ChartsPublicAggregateFields>;
  nodes: Array<ChartsPublic>;
};

/** aggregate fields of "charts_public" */
export type ChartsPublicAggregateFields = {
  __typename?: 'charts_public_aggregate_fields';
  avg?: Maybe<ChartsPublicAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ChartsPublicMaxFields>;
  min?: Maybe<ChartsPublicMinFields>;
  stddev?: Maybe<ChartsPublicStddevFields>;
  stddev_pop?: Maybe<ChartsPublicStddevPopFields>;
  stddev_samp?: Maybe<ChartsPublicStddevSampFields>;
  sum?: Maybe<ChartsPublicSumFields>;
  var_pop?: Maybe<ChartsPublicVarPopFields>;
  var_samp?: Maybe<ChartsPublicVarSampFields>;
  variance?: Maybe<ChartsPublicVarianceFields>;
};

/** aggregate fields of "charts_public" */
export type ChartsPublicAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ChartsPublicSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "charts_public" */
export type ChartsPublicAggregateOrderBy = {
  avg?: Maybe<ChartsPublicAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ChartsPublicMaxOrderBy>;
  min?: Maybe<ChartsPublicMinOrderBy>;
  stddev?: Maybe<ChartsPublicStddevOrderBy>;
  stddev_pop?: Maybe<ChartsPublicStddevPopOrderBy>;
  stddev_samp?: Maybe<ChartsPublicStddevSampOrderBy>;
  sum?: Maybe<ChartsPublicSumOrderBy>;
  var_pop?: Maybe<ChartsPublicVarPopOrderBy>;
  var_samp?: Maybe<ChartsPublicVarSampOrderBy>;
  variance?: Maybe<ChartsPublicVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "charts_public" */
export type ChartsPublicArrRelInsertInput = {
  data: Array<ChartsPublicInsertInput>;
};

/** aggregate avg on columns */
export type ChartsPublicAvgFields = {
  __typename?: 'charts_public_avg_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "charts_public" */
export type ChartsPublicAvgOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "charts_public". All fields are combined with a logical 'AND'. */
export type ChartsPublicBoolExp = {
  _and?: Maybe<Array<Maybe<ChartsPublicBoolExp>>>;
  _not?: Maybe<ChartsPublicBoolExp>;
  _or?: Maybe<Array<Maybe<ChartsPublicBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  has_fractional_stitches?: Maybe<BooleanComparisonExp>;
  height?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  import_fabric_color?: Maybe<RgbIntComparisonExp>;
  instructions?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  width?: Maybe<IntComparisonExp>;
};

/** input type for incrementing integer column in table "charts_public" */
export type ChartsPublicIncInput = {
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "charts_public" */
export type ChartsPublicInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  has_fractional_stitches?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  import_fabric_color?: Maybe<Scalars['_rgb_int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type ChartsPublicMaxFields = {
  __typename?: 'charts_public_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "charts_public" */
export type ChartsPublicMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instructions?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ChartsPublicMinFields = {
  __typename?: 'charts_public_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "charts_public" */
export type ChartsPublicMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  instructions?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** response of any mutation on the table "charts_public" */
export type ChartsPublicMutationResponse = {
  __typename?: 'charts_public_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ChartsPublic>;
};

/** input type for inserting object relation for remote table "charts_public" */
export type ChartsPublicObjRelInsertInput = {
  data: ChartsPublicInsertInput;
};

/** ordering options when selecting data from "charts_public" */
export type ChartsPublicOrderBy = {
  created_at?: Maybe<OrderBy>;
  has_fractional_stitches?: Maybe<OrderBy>;
  height?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  import_fabric_color?: Maybe<OrderBy>;
  instructions?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** select columns of table "charts_public" */
export enum ChartsPublicSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasFractionalStitches = 'has_fractional_stitches',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  ImportFabricColor = 'import_fabric_color',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width',
}

/** input type for updating data in table "charts_public" */
export type ChartsPublicSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  has_fractional_stitches?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  import_fabric_color?: Maybe<Scalars['_rgb_int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ChartsPublicStddevFields = {
  __typename?: 'charts_public_stddev_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "charts_public" */
export type ChartsPublicStddevOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChartsPublicStddevPopFields = {
  __typename?: 'charts_public_stddev_pop_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "charts_public" */
export type ChartsPublicStddevPopOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChartsPublicStddevSampFields = {
  __typename?: 'charts_public_stddev_samp_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "charts_public" */
export type ChartsPublicStddevSampOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ChartsPublicSumFields = {
  __typename?: 'charts_public_sum_fields';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "charts_public" */
export type ChartsPublicSumOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ChartsPublicVarPopFields = {
  __typename?: 'charts_public_var_pop_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "charts_public" */
export type ChartsPublicVarPopOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChartsPublicVarSampFields = {
  __typename?: 'charts_public_var_samp_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "charts_public" */
export type ChartsPublicVarSampOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChartsPublicVarianceFields = {
  __typename?: 'charts_public_variance_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "charts_public" */
export type ChartsPublicVarianceOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** select columns of table "charts" */
export enum ChartsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasFractionalStitches = 'has_fractional_stitches',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  ImportFabricColor = 'import_fabric_color',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width',
}

/** input type for updating data in table "charts" */
export type ChartsSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  has_fractional_stitches?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  import_fabric_color?: Maybe<Scalars['_rgb_int']>;
  instructions?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type ChartsStddevFields = {
  __typename?: 'charts_stddev_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "charts" */
export type ChartsStddevOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChartsStddevPopFields = {
  __typename?: 'charts_stddev_pop_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "charts" */
export type ChartsStddevPopOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChartsStddevSampFields = {
  __typename?: 'charts_stddev_samp_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "charts" */
export type ChartsStddevSampOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ChartsSumFields = {
  __typename?: 'charts_sum_fields';
  height?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "charts" */
export type ChartsSumOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** update columns of table "charts" */
export enum ChartsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasFractionalStitches = 'has_fractional_stitches',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  ImportFabricColor = 'import_fabric_color',
  /** column name */
  Instructions = 'instructions',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Width = 'width',
}

/** aggregate var_pop on columns */
export type ChartsVarPopFields = {
  __typename?: 'charts_var_pop_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "charts" */
export type ChartsVarPopOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChartsVarSampFields = {
  __typename?: 'charts_var_samp_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "charts" */
export type ChartsVarSampOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChartsVarianceFields = {
  __typename?: 'charts_variance_fields';
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "charts" */
export type ChartsVarianceOrderBy = {
  height?: Maybe<OrderBy>;
  width?: Maybe<OrderBy>;
};

/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type CitextComparisonExp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "collections" */
export type Collections = {
  __typename?: 'collections';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  products_collections: Array<ProductsCollections>;
  /** An aggregated array relationship */
  products_collections_aggregate: ProductsCollectionsAggregate;
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "collections" */
export type CollectionsProductsCollectionsArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** columns and relationships of "collections" */
export type CollectionsProductsCollectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** aggregated selection of "collections" */
export type CollectionsAggregate = {
  __typename?: 'collections_aggregate';
  aggregate?: Maybe<CollectionsAggregateFields>;
  nodes: Array<Collections>;
};

/** aggregate fields of "collections" */
export type CollectionsAggregateFields = {
  __typename?: 'collections_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<CollectionsMaxFields>;
  min?: Maybe<CollectionsMinFields>;
};

/** aggregate fields of "collections" */
export type CollectionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<CollectionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "collections" */
export type CollectionsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<CollectionsMaxOrderBy>;
  min?: Maybe<CollectionsMinOrderBy>;
};

/** input type for inserting array relation for remote table "collections" */
export type CollectionsArrRelInsertInput = {
  data: Array<CollectionsInsertInput>;
  on_conflict?: Maybe<CollectionsOnConflict>;
};

/** Boolean expression to filter rows from the table "collections". All fields are combined with a logical 'AND'. */
export type CollectionsBoolExp = {
  _and?: Maybe<Array<Maybe<CollectionsBoolExp>>>;
  _not?: Maybe<CollectionsBoolExp>;
  _or?: Maybe<Array<Maybe<CollectionsBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  products_collections?: Maybe<ProductsCollectionsBoolExp>;
  slug?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "collections" */
export enum CollectionsConstraint {
  /** unique or primary key constraint */
  CollectionsPkey = 'collections_pkey',
  /** unique or primary key constraint */
  CollectionsSlugKey = 'collections_slug_key',
}

/** input type for inserting data into table "collections" */
export type CollectionsInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  products_collections?: Maybe<ProductsCollectionsArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type CollectionsMaxFields = {
  __typename?: 'collections_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "collections" */
export type CollectionsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type CollectionsMinFields = {
  __typename?: 'collections_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "collections" */
export type CollectionsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "collections" */
export type CollectionsMutationResponse = {
  __typename?: 'collections_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Collections>;
};

/** input type for inserting object relation for remote table "collections" */
export type CollectionsObjRelInsertInput = {
  data: CollectionsInsertInput;
  on_conflict?: Maybe<CollectionsOnConflict>;
};

/** on conflict condition type for table "collections" */
export type CollectionsOnConflict = {
  constraint: CollectionsConstraint;
  update_columns: Array<CollectionsUpdateColumn>;
  where?: Maybe<CollectionsBoolExp>;
};

/** ordering options when selecting data from "collections" */
export type CollectionsOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  products_collections_aggregate?: Maybe<ProductsCollectionsAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "collections" */
export type CollectionsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "collections" */
export enum CollectionsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "collections" */
export type CollectionsSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "collections" */
export enum CollectionsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type dimensions. All fields are combined with logical 'AND'. */
export type DimensionsComparisonExp = {
  _eq?: Maybe<Scalars['dimensions']>;
  _gt?: Maybe<Scalars['dimensions']>;
  _gte?: Maybe<Scalars['dimensions']>;
  _in?: Maybe<Array<Scalars['dimensions']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['dimensions']>;
  _lte?: Maybe<Scalars['dimensions']>;
  _neq?: Maybe<Scalars['dimensions']>;
  _nin?: Maybe<Array<Scalars['dimensions']>>;
};

/** columns and relationships of "fabric_types" */
export type FabricTypes = {
  __typename?: 'fabric_types';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  supports_fractional_stitches: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "fabric_types" */
export type FabricTypesAggregate = {
  __typename?: 'fabric_types_aggregate';
  aggregate?: Maybe<FabricTypesAggregateFields>;
  nodes: Array<FabricTypes>;
};

/** aggregate fields of "fabric_types" */
export type FabricTypesAggregateFields = {
  __typename?: 'fabric_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<FabricTypesMaxFields>;
  min?: Maybe<FabricTypesMinFields>;
};

/** aggregate fields of "fabric_types" */
export type FabricTypesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FabricTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fabric_types" */
export type FabricTypesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<FabricTypesMaxOrderBy>;
  min?: Maybe<FabricTypesMinOrderBy>;
};

/** input type for inserting array relation for remote table "fabric_types" */
export type FabricTypesArrRelInsertInput = {
  data: Array<FabricTypesInsertInput>;
  on_conflict?: Maybe<FabricTypesOnConflict>;
};

/** Boolean expression to filter rows from the table "fabric_types". All fields are combined with a logical 'AND'. */
export type FabricTypesBoolExp = {
  _and?: Maybe<Array<Maybe<FabricTypesBoolExp>>>;
  _not?: Maybe<FabricTypesBoolExp>;
  _or?: Maybe<Array<Maybe<FabricTypesBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  supports_fractional_stitches?: Maybe<BooleanComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "fabric_types" */
export enum FabricTypesConstraint {
  /** unique or primary key constraint */
  FabricTypesFileIdKey = 'fabric_types_file_id_key',
  /** unique or primary key constraint */
  FabricTypesNameKey = 'fabric_types_name_key',
  /** unique or primary key constraint */
  FabricTypesPkey = 'fabric_types_pkey',
}

/** input type for inserting data into table "fabric_types" */
export type FabricTypesInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  supports_fractional_stitches?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FabricTypesMaxFields = {
  __typename?: 'fabric_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "fabric_types" */
export type FabricTypesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type FabricTypesMinFields = {
  __typename?: 'fabric_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "fabric_types" */
export type FabricTypesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "fabric_types" */
export type FabricTypesMutationResponse = {
  __typename?: 'fabric_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<FabricTypes>;
};

/** input type for inserting object relation for remote table "fabric_types" */
export type FabricTypesObjRelInsertInput = {
  data: FabricTypesInsertInput;
  on_conflict?: Maybe<FabricTypesOnConflict>;
};

/** on conflict condition type for table "fabric_types" */
export type FabricTypesOnConflict = {
  constraint: FabricTypesConstraint;
  update_columns: Array<FabricTypesUpdateColumn>;
  where?: Maybe<FabricTypesBoolExp>;
};

/** ordering options when selecting data from "fabric_types" */
export type FabricTypesOrderBy = {
  created_at?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  supports_fractional_stitches?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "fabric_types" */
export type FabricTypesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "fabric_types" */
export enum FabricTypesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SupportsFractionalStitches = 'supports_fractional_stitches',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "fabric_types" */
export type FabricTypesSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  supports_fractional_stitches?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "fabric_types" */
export enum FabricTypesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  SupportsFractionalStitches = 'supports_fractional_stitches',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz'];
  extension: Scalars['String'];
  /** An object relationship */
  fabric_type?: Maybe<FabricTypes>;
  /** A computed field, executes function "filename" */
  filename?: Maybe<Scalars['String']>;
  /** An object relationship */
  finish_type?: Maybe<FinishTypes>;
  id: Scalars['uuid'];
  /** An object relationship */
  mottling_type?: Maybe<MottlingTypes>;
  name: Scalars['String'];
  /** An array relationship */
  products_images: Array<ProductsImages>;
  /** An aggregated array relationship */
  products_images_aggregate: ProductsImagesAggregate;
  s3_key: Scalars['String'];
  s3_url: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "files" */
export type FilesProductsImagesArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** columns and relationships of "files" */
export type FilesProductsImagesAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** aggregated selection of "files" */
export type FilesAggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<FilesAggregateFields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type FilesAggregateFields = {
  __typename?: 'files_aggregate_fields';
  avg?: Maybe<FilesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<FilesMaxFields>;
  min?: Maybe<FilesMinFields>;
  stddev?: Maybe<FilesStddevFields>;
  stddev_pop?: Maybe<FilesStddevPopFields>;
  stddev_samp?: Maybe<FilesStddevSampFields>;
  sum?: Maybe<FilesSumFields>;
  var_pop?: Maybe<FilesVarPopFields>;
  var_samp?: Maybe<FilesVarSampFields>;
  variance?: Maybe<FilesVarianceFields>;
};

/** aggregate fields of "files" */
export type FilesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FilesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files" */
export type FilesAggregateOrderBy = {
  avg?: Maybe<FilesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<FilesMaxOrderBy>;
  min?: Maybe<FilesMinOrderBy>;
  stddev?: Maybe<FilesStddevOrderBy>;
  stddev_pop?: Maybe<FilesStddevPopOrderBy>;
  stddev_samp?: Maybe<FilesStddevSampOrderBy>;
  sum?: Maybe<FilesSumOrderBy>;
  var_pop?: Maybe<FilesVarPopOrderBy>;
  var_samp?: Maybe<FilesVarSampOrderBy>;
  variance?: Maybe<FilesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "files" */
export type FilesArrRelInsertInput = {
  data: Array<FilesInsertInput>;
  on_conflict?: Maybe<FilesOnConflict>;
};

/** aggregate avg on columns */
export type FilesAvgFields = {
  __typename?: 'files_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "files" */
export type FilesAvgOrderBy = {
  size?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type FilesBoolExp = {
  _and?: Maybe<Array<Maybe<FilesBoolExp>>>;
  _not?: Maybe<FilesBoolExp>;
  _or?: Maybe<Array<Maybe<FilesBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  extension?: Maybe<StringComparisonExp>;
  fabric_type?: Maybe<FabricTypesBoolExp>;
  finish_type?: Maybe<FinishTypesBoolExp>;
  id?: Maybe<UuidComparisonExp>;
  mottling_type?: Maybe<MottlingTypesBoolExp>;
  name?: Maybe<StringComparisonExp>;
  products_images?: Maybe<ProductsImagesBoolExp>;
  s3_key?: Maybe<StringComparisonExp>;
  s3_url?: Maybe<StringComparisonExp>;
  size?: Maybe<IntComparisonExp>;
  type?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "files" */
export enum FilesConstraint {
  /** unique or primary key constraint */
  FilesPkey = 'files_pkey',
  /** unique or primary key constraint */
  FilesS3KeyKey = 'files_s3_key_key',
  /** unique or primary key constraint */
  FilesS3UrlKey = 'files_s3_url_key',
}

/** input type for incrementing integer column in table "files" */
export type FilesIncInput = {
  size?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "files" */
export type FilesInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  extension?: Maybe<Scalars['String']>;
  fabric_type?: Maybe<FabricTypesObjRelInsertInput>;
  finish_type?: Maybe<FinishTypesObjRelInsertInput>;
  id?: Maybe<Scalars['uuid']>;
  mottling_type?: Maybe<MottlingTypesObjRelInsertInput>;
  name?: Maybe<Scalars['String']>;
  products_images?: Maybe<ProductsImagesArrRelInsertInput>;
  s3_key?: Maybe<Scalars['String']>;
  s3_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FilesMaxFields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  s3_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "files" */
export type FilesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  extension?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  s3_key?: Maybe<OrderBy>;
  s3_url?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type FilesMinFields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  s3_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "files" */
export type FilesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  extension?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  s3_key?: Maybe<OrderBy>;
  s3_url?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "files" */
export type FilesMutationResponse = {
  __typename?: 'files_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type FilesObjRelInsertInput = {
  data: FilesInsertInput;
  on_conflict?: Maybe<FilesOnConflict>;
};

/** on conflict condition type for table "files" */
export type FilesOnConflict = {
  constraint: FilesConstraint;
  update_columns: Array<FilesUpdateColumn>;
  where?: Maybe<FilesBoolExp>;
};

/** ordering options when selecting data from "files" */
export type FilesOrderBy = {
  created_at?: Maybe<OrderBy>;
  extension?: Maybe<OrderBy>;
  fabric_type?: Maybe<FabricTypesOrderBy>;
  finish_type?: Maybe<FinishTypesOrderBy>;
  id?: Maybe<OrderBy>;
  mottling_type?: Maybe<MottlingTypesOrderBy>;
  name?: Maybe<OrderBy>;
  products_images_aggregate?: Maybe<ProductsImagesAggregateOrderBy>;
  s3_key?: Maybe<OrderBy>;
  s3_url?: Maybe<OrderBy>;
  size?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "files" */
export type FilesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum FilesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Extension = 'extension',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  S3Key = 's3_key',
  /** column name */
  S3Url = 's3_url',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "files" */
export type FilesSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  s3_key?: Maybe<Scalars['String']>;
  s3_url?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type FilesStddevFields = {
  __typename?: 'files_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "files" */
export type FilesStddevOrderBy = {
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type FilesStddevPopFields = {
  __typename?: 'files_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "files" */
export type FilesStddevPopOrderBy = {
  size?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type FilesStddevSampFields = {
  __typename?: 'files_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "files" */
export type FilesStddevSampOrderBy = {
  size?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type FilesSumFields = {
  __typename?: 'files_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "files" */
export type FilesSumOrderBy = {
  size?: Maybe<OrderBy>;
};

/** update columns of table "files" */
export enum FilesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Extension = 'extension',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  S3Key = 's3_key',
  /** column name */
  S3Url = 's3_url',
  /** column name */
  Size = 'size',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type FilesVarPopFields = {
  __typename?: 'files_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "files" */
export type FilesVarPopOrderBy = {
  size?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type FilesVarSampFields = {
  __typename?: 'files_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "files" */
export type FilesVarSampOrderBy = {
  size?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type FilesVarianceFields = {
  __typename?: 'files_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "files" */
export type FilesVarianceOrderBy = {
  size?: Maybe<OrderBy>;
};

/** columns and relationships of "finish_types" */
export type FinishTypes = {
  __typename?: 'finish_types';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "finish_types" */
export type FinishTypesAggregate = {
  __typename?: 'finish_types_aggregate';
  aggregate?: Maybe<FinishTypesAggregateFields>;
  nodes: Array<FinishTypes>;
};

/** aggregate fields of "finish_types" */
export type FinishTypesAggregateFields = {
  __typename?: 'finish_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<FinishTypesMaxFields>;
  min?: Maybe<FinishTypesMinFields>;
};

/** aggregate fields of "finish_types" */
export type FinishTypesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<FinishTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "finish_types" */
export type FinishTypesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<FinishTypesMaxOrderBy>;
  min?: Maybe<FinishTypesMinOrderBy>;
};

/** input type for inserting array relation for remote table "finish_types" */
export type FinishTypesArrRelInsertInput = {
  data: Array<FinishTypesInsertInput>;
  on_conflict?: Maybe<FinishTypesOnConflict>;
};

/** Boolean expression to filter rows from the table "finish_types". All fields are combined with a logical 'AND'. */
export type FinishTypesBoolExp = {
  _and?: Maybe<Array<Maybe<FinishTypesBoolExp>>>;
  _not?: Maybe<FinishTypesBoolExp>;
  _or?: Maybe<Array<Maybe<FinishTypesBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "finish_types" */
export enum FinishTypesConstraint {
  /** unique or primary key constraint */
  FinishTypesFileIdKey = 'finish_types_file_id_key',
  /** unique or primary key constraint */
  FinishTypesNameKey = 'finish_types_name_key',
  /** unique or primary key constraint */
  FinishTypesPkey = 'finish_types_pkey',
}

/** input type for inserting data into table "finish_types" */
export type FinishTypesInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type FinishTypesMaxFields = {
  __typename?: 'finish_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "finish_types" */
export type FinishTypesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type FinishTypesMinFields = {
  __typename?: 'finish_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "finish_types" */
export type FinishTypesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "finish_types" */
export type FinishTypesMutationResponse = {
  __typename?: 'finish_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<FinishTypes>;
};

/** input type for inserting object relation for remote table "finish_types" */
export type FinishTypesObjRelInsertInput = {
  data: FinishTypesInsertInput;
  on_conflict?: Maybe<FinishTypesOnConflict>;
};

/** on conflict condition type for table "finish_types" */
export type FinishTypesOnConflict = {
  constraint: FinishTypesConstraint;
  update_columns: Array<FinishTypesUpdateColumn>;
  where?: Maybe<FinishTypesBoolExp>;
};

/** ordering options when selecting data from "finish_types" */
export type FinishTypesOrderBy = {
  created_at?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "finish_types" */
export type FinishTypesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "finish_types" */
export enum FinishTypesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "finish_types" */
export type FinishTypesSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "finish_types" */
export enum FinishTypesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Expression to compare the result of casting a column of type geography. Multiple cast targets are combined with logical 'AND'. */
export type GeographyCastExp = {
  geometry?: Maybe<GeometryComparisonExp>;
};

/** expression to compare columns of type geography. All fields are combined with logical 'AND'. */
export type GeographyComparisonExp = {
  _cast?: Maybe<GeographyCastExp>;
  _eq?: Maybe<Scalars['geography']>;
  _gt?: Maybe<Scalars['geography']>;
  _gte?: Maybe<Scalars['geography']>;
  _in?: Maybe<Array<Scalars['geography']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geography']>;
  _lte?: Maybe<Scalars['geography']>;
  _neq?: Maybe<Scalars['geography']>;
  _nin?: Maybe<Array<Scalars['geography']>>;
  /** is the column within a distance from a geography value */
  _st_d_within?: Maybe<StDWithinGeographyInput>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: Maybe<Scalars['geography']>;
};

/** Expression to compare the result of casting a column of type geometry. Multiple cast targets are combined with logical 'AND'. */
export type GeometryCastExp = {
  geography?: Maybe<GeographyComparisonExp>;
};

/** expression to compare columns of type geometry. All fields are combined with logical 'AND'. */
export type GeometryComparisonExp = {
  _cast?: Maybe<GeometryCastExp>;
  _eq?: Maybe<Scalars['geometry']>;
  _gt?: Maybe<Scalars['geometry']>;
  _gte?: Maybe<Scalars['geometry']>;
  _in?: Maybe<Array<Scalars['geometry']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['geometry']>;
  _lte?: Maybe<Scalars['geometry']>;
  _neq?: Maybe<Scalars['geometry']>;
  _nin?: Maybe<Array<Scalars['geometry']>>;
  /** does the column contain the given geometry value */
  _st_contains?: Maybe<Scalars['geometry']>;
  /** does the column crosses the given geometry value */
  _st_crosses?: Maybe<Scalars['geometry']>;
  /** is the column within a distance from a geometry value */
  _st_d_within?: Maybe<StDWithinInput>;
  /** is the column equal to given geometry value. Directionality is ignored */
  _st_equals?: Maybe<Scalars['geometry']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: Maybe<Scalars['geometry']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: Maybe<Scalars['geometry']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: Maybe<Scalars['geometry']>;
  /** is the column contained in the given geometry value */
  _st_within?: Maybe<Scalars['geometry']>;
};

/** columns and relationships of "mottling_types" */
export type MottlingTypes = {
  __typename?: 'mottling_types';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "mottling_types" */
export type MottlingTypesAggregate = {
  __typename?: 'mottling_types_aggregate';
  aggregate?: Maybe<MottlingTypesAggregateFields>;
  nodes: Array<MottlingTypes>;
};

/** aggregate fields of "mottling_types" */
export type MottlingTypesAggregateFields = {
  __typename?: 'mottling_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<MottlingTypesMaxFields>;
  min?: Maybe<MottlingTypesMinFields>;
};

/** aggregate fields of "mottling_types" */
export type MottlingTypesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<MottlingTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mottling_types" */
export type MottlingTypesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<MottlingTypesMaxOrderBy>;
  min?: Maybe<MottlingTypesMinOrderBy>;
};

/** input type for inserting array relation for remote table "mottling_types" */
export type MottlingTypesArrRelInsertInput = {
  data: Array<MottlingTypesInsertInput>;
  on_conflict?: Maybe<MottlingTypesOnConflict>;
};

/** Boolean expression to filter rows from the table "mottling_types". All fields are combined with a logical 'AND'. */
export type MottlingTypesBoolExp = {
  _and?: Maybe<Array<Maybe<MottlingTypesBoolExp>>>;
  _not?: Maybe<MottlingTypesBoolExp>;
  _or?: Maybe<Array<Maybe<MottlingTypesBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "mottling_types" */
export enum MottlingTypesConstraint {
  /** unique or primary key constraint */
  MottlingTypesFileIdKey = 'mottling_types_file_id_key',
  /** unique or primary key constraint */
  MottlingTypesNameKey = 'mottling_types_name_key',
  /** unique or primary key constraint */
  MottlingTypesPkey = 'mottling_types_pkey',
}

/** input type for inserting data into table "mottling_types" */
export type MottlingTypesInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type MottlingTypesMaxFields = {
  __typename?: 'mottling_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "mottling_types" */
export type MottlingTypesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type MottlingTypesMinFields = {
  __typename?: 'mottling_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "mottling_types" */
export type MottlingTypesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "mottling_types" */
export type MottlingTypesMutationResponse = {
  __typename?: 'mottling_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<MottlingTypes>;
};

/** input type for inserting object relation for remote table "mottling_types" */
export type MottlingTypesObjRelInsertInput = {
  data: MottlingTypesInsertInput;
  on_conflict?: Maybe<MottlingTypesOnConflict>;
};

/** on conflict condition type for table "mottling_types" */
export type MottlingTypesOnConflict = {
  constraint: MottlingTypesConstraint;
  update_columns: Array<MottlingTypesUpdateColumn>;
  where?: Maybe<MottlingTypesBoolExp>;
};

/** ordering options when selecting data from "mottling_types" */
export type MottlingTypesOrderBy = {
  created_at?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "mottling_types" */
export type MottlingTypesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "mottling_types" */
export enum MottlingTypesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "mottling_types" */
export type MottlingTypesSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "mottling_types" */
export enum MottlingTypesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  cancelPaymentIntent: BooleanResponse;
  checkSubscriptionStatus: BooleanResponse;
  claimProducts: BooleanResponse;
  completeFileUpload: CompleteFileUploadResponse;
  createFirstTimeSession: UserResponse;
  createPaymentIntent: CreatePaymentIntentResponse;
  /** delete data from the table: "auth_codes" */
  delete_auth_codes?: Maybe<AuthCodesMutationResponse>;
  /** delete single row from the table: "auth_codes" */
  delete_auth_codes_by_pk?: Maybe<AuthCodes>;
  /** delete data from the table: "brand_colors" */
  delete_brand_colors?: Maybe<BrandColorsMutationResponse>;
  /** delete single row from the table: "brand_colors" */
  delete_brand_colors_by_pk?: Maybe<BrandColors>;
  /** delete data from the table: "brand_lines" */
  delete_brand_lines?: Maybe<BrandLinesMutationResponse>;
  /** delete single row from the table: "brand_lines" */
  delete_brand_lines_by_pk?: Maybe<BrandLines>;
  /** delete data from the table: "brands" */
  delete_brands?: Maybe<BrandsMutationResponse>;
  /** delete single row from the table: "brands" */
  delete_brands_by_pk?: Maybe<Brands>;
  /** delete data from the table: "charts" */
  delete_charts?: Maybe<ChartsMutationResponse>;
  /** delete single row from the table: "charts" */
  delete_charts_by_pk?: Maybe<Charts>;
  /** delete data from the table: "charts_public" */
  delete_charts_public?: Maybe<ChartsPublicMutationResponse>;
  /** delete data from the table: "collections" */
  delete_collections?: Maybe<CollectionsMutationResponse>;
  /** delete single row from the table: "collections" */
  delete_collections_by_pk?: Maybe<Collections>;
  /** delete data from the table: "fabric_types" */
  delete_fabric_types?: Maybe<FabricTypesMutationResponse>;
  /** delete single row from the table: "fabric_types" */
  delete_fabric_types_by_pk?: Maybe<FabricTypes>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<FilesMutationResponse>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "finish_types" */
  delete_finish_types?: Maybe<FinishTypesMutationResponse>;
  /** delete single row from the table: "finish_types" */
  delete_finish_types_by_pk?: Maybe<FinishTypes>;
  /** delete data from the table: "mottling_types" */
  delete_mottling_types?: Maybe<MottlingTypesMutationResponse>;
  /** delete single row from the table: "mottling_types" */
  delete_mottling_types_by_pk?: Maybe<MottlingTypes>;
  /** delete data from the table: "order_statuses" */
  delete_order_statuses?: Maybe<OrderStatusesMutationResponse>;
  /** delete single row from the table: "order_statuses" */
  delete_order_statuses_by_pk?: Maybe<OrderStatuses>;
  /** delete data from the table: "orders" */
  delete_orders?: Maybe<OrdersMutationResponse>;
  /** delete single row from the table: "orders" */
  delete_orders_by_pk?: Maybe<Orders>;
  /** delete data from the table: "orders_items" */
  delete_orders_items?: Maybe<OrdersItemsMutationResponse>;
  /** delete single row from the table: "orders_items" */
  delete_orders_items_by_pk?: Maybe<OrdersItems>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<ProductsMutationResponse>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "products_collections" */
  delete_products_collections?: Maybe<ProductsCollectionsMutationResponse>;
  /** delete single row from the table: "products_collections" */
  delete_products_collections_by_pk?: Maybe<ProductsCollections>;
  /** delete data from the table: "products_images" */
  delete_products_images?: Maybe<ProductsImagesMutationResponse>;
  /** delete single row from the table: "products_images" */
  delete_products_images_by_pk?: Maybe<ProductsImages>;
  /** delete data from the table: "products_tags" */
  delete_products_tags?: Maybe<ProductsTagsMutationResponse>;
  /** delete single row from the table: "products_tags" */
  delete_products_tags_by_pk?: Maybe<ProductsTags>;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<ProjectsMutationResponse>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "shops" */
  delete_shops?: Maybe<ShopsMutationResponse>;
  /** delete single row from the table: "shops" */
  delete_shops_by_pk?: Maybe<Shops>;
  /** delete data from the table: "stitch_groups" */
  delete_stitch_groups?: Maybe<StitchGroupsMutationResponse>;
  /** delete single row from the table: "stitch_groups" */
  delete_stitch_groups_by_pk?: Maybe<StitchGroups>;
  /** delete data from the table: "stitch_types" */
  delete_stitch_types?: Maybe<StitchTypesMutationResponse>;
  /** delete single row from the table: "stitch_types" */
  delete_stitch_types_by_pk?: Maybe<StitchTypes>;
  /** delete data from the table: "stripe_payment_intent_statuses" */
  delete_stripe_payment_intent_statuses?: Maybe<
    StripePaymentIntentStatusesMutationResponse
  >;
  /** delete single row from the table: "stripe_payment_intent_statuses" */
  delete_stripe_payment_intent_statuses_by_pk?: Maybe<
    StripePaymentIntentStatuses
  >;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<TagsMutationResponse>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "themes" */
  delete_themes?: Maybe<ThemesMutationResponse>;
  /** delete single row from the table: "themes" */
  delete_themes_by_pk?: Maybe<Themes>;
  /** delete data from the table: "themes_public" */
  delete_themes_public?: Maybe<ThemesPublicMutationResponse>;
  /** delete data from the table: "themes_stitch_groups_threads" */
  delete_themes_stitch_groups_threads?: Maybe<
    ThemesStitchGroupsThreadsMutationResponse
  >;
  /** delete single row from the table: "themes_stitch_groups_threads" */
  delete_themes_stitch_groups_threads_by_pk?: Maybe<ThemesStitchGroupsThreads>;
  /** delete data from the table: "threads" */
  delete_threads?: Maybe<ThreadsMutationResponse>;
  /** delete single row from the table: "threads" */
  delete_threads_by_pk?: Maybe<Threads>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<UsersMutationResponse>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  endSession: BooleanResponse;
  /** insert data into the table: "auth_codes" */
  insert_auth_codes?: Maybe<AuthCodesMutationResponse>;
  /** insert a single row into the table: "auth_codes" */
  insert_auth_codes_one?: Maybe<AuthCodes>;
  /** insert data into the table: "brand_colors" */
  insert_brand_colors?: Maybe<BrandColorsMutationResponse>;
  /** insert a single row into the table: "brand_colors" */
  insert_brand_colors_one?: Maybe<BrandColors>;
  /** insert data into the table: "brand_lines" */
  insert_brand_lines?: Maybe<BrandLinesMutationResponse>;
  /** insert a single row into the table: "brand_lines" */
  insert_brand_lines_one?: Maybe<BrandLines>;
  /** insert data into the table: "brands" */
  insert_brands?: Maybe<BrandsMutationResponse>;
  /** insert a single row into the table: "brands" */
  insert_brands_one?: Maybe<Brands>;
  /** insert data into the table: "charts" */
  insert_charts?: Maybe<ChartsMutationResponse>;
  /** insert a single row into the table: "charts" */
  insert_charts_one?: Maybe<Charts>;
  /** insert data into the table: "charts_public" */
  insert_charts_public?: Maybe<ChartsPublicMutationResponse>;
  /** insert a single row into the table: "charts_public" */
  insert_charts_public_one?: Maybe<ChartsPublic>;
  /** insert data into the table: "collections" */
  insert_collections?: Maybe<CollectionsMutationResponse>;
  /** insert a single row into the table: "collections" */
  insert_collections_one?: Maybe<Collections>;
  /** insert data into the table: "fabric_types" */
  insert_fabric_types?: Maybe<FabricTypesMutationResponse>;
  /** insert a single row into the table: "fabric_types" */
  insert_fabric_types_one?: Maybe<FabricTypes>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<FilesMutationResponse>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "finish_types" */
  insert_finish_types?: Maybe<FinishTypesMutationResponse>;
  /** insert a single row into the table: "finish_types" */
  insert_finish_types_one?: Maybe<FinishTypes>;
  /** insert data into the table: "mottling_types" */
  insert_mottling_types?: Maybe<MottlingTypesMutationResponse>;
  /** insert a single row into the table: "mottling_types" */
  insert_mottling_types_one?: Maybe<MottlingTypes>;
  /** insert data into the table: "order_statuses" */
  insert_order_statuses?: Maybe<OrderStatusesMutationResponse>;
  /** insert a single row into the table: "order_statuses" */
  insert_order_statuses_one?: Maybe<OrderStatuses>;
  /** insert data into the table: "orders" */
  insert_orders?: Maybe<OrdersMutationResponse>;
  /** insert data into the table: "orders_items" */
  insert_orders_items?: Maybe<OrdersItemsMutationResponse>;
  /** insert a single row into the table: "orders_items" */
  insert_orders_items_one?: Maybe<OrdersItems>;
  /** insert a single row into the table: "orders" */
  insert_orders_one?: Maybe<Orders>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<ProductsMutationResponse>;
  /** insert data into the table: "products_collections" */
  insert_products_collections?: Maybe<ProductsCollectionsMutationResponse>;
  /** insert a single row into the table: "products_collections" */
  insert_products_collections_one?: Maybe<ProductsCollections>;
  /** insert data into the table: "products_images" */
  insert_products_images?: Maybe<ProductsImagesMutationResponse>;
  /** insert a single row into the table: "products_images" */
  insert_products_images_one?: Maybe<ProductsImages>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "products_tags" */
  insert_products_tags?: Maybe<ProductsTagsMutationResponse>;
  /** insert a single row into the table: "products_tags" */
  insert_products_tags_one?: Maybe<ProductsTags>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<ProjectsMutationResponse>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "shops" */
  insert_shops?: Maybe<ShopsMutationResponse>;
  /** insert a single row into the table: "shops" */
  insert_shops_one?: Maybe<Shops>;
  /** insert data into the table: "stitch_groups" */
  insert_stitch_groups?: Maybe<StitchGroupsMutationResponse>;
  /** insert a single row into the table: "stitch_groups" */
  insert_stitch_groups_one?: Maybe<StitchGroups>;
  /** insert data into the table: "stitch_types" */
  insert_stitch_types?: Maybe<StitchTypesMutationResponse>;
  /** insert a single row into the table: "stitch_types" */
  insert_stitch_types_one?: Maybe<StitchTypes>;
  /** insert data into the table: "stripe_payment_intent_statuses" */
  insert_stripe_payment_intent_statuses?: Maybe<
    StripePaymentIntentStatusesMutationResponse
  >;
  /** insert a single row into the table: "stripe_payment_intent_statuses" */
  insert_stripe_payment_intent_statuses_one?: Maybe<
    StripePaymentIntentStatuses
  >;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<TagsMutationResponse>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "themes" */
  insert_themes?: Maybe<ThemesMutationResponse>;
  /** insert a single row into the table: "themes" */
  insert_themes_one?: Maybe<Themes>;
  /** insert data into the table: "themes_public" */
  insert_themes_public?: Maybe<ThemesPublicMutationResponse>;
  /** insert a single row into the table: "themes_public" */
  insert_themes_public_one?: Maybe<ThemesPublic>;
  /** insert data into the table: "themes_stitch_groups_threads" */
  insert_themes_stitch_groups_threads?: Maybe<
    ThemesStitchGroupsThreadsMutationResponse
  >;
  /** insert a single row into the table: "themes_stitch_groups_threads" */
  insert_themes_stitch_groups_threads_one?: Maybe<ThemesStitchGroupsThreads>;
  /** insert data into the table: "threads" */
  insert_threads?: Maybe<ThreadsMutationResponse>;
  /** insert a single row into the table: "threads" */
  insert_threads_one?: Maybe<Threads>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<UsersMutationResponse>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  refreshOrderStatus: BooleanResponse;
  sendAuthCode: BooleanResponse;
  signUp: User;
  /** update data of the table: "auth_codes" */
  update_auth_codes?: Maybe<AuthCodesMutationResponse>;
  /** update single row of the table: "auth_codes" */
  update_auth_codes_by_pk?: Maybe<AuthCodes>;
  /** update data of the table: "brand_colors" */
  update_brand_colors?: Maybe<BrandColorsMutationResponse>;
  /** update single row of the table: "brand_colors" */
  update_brand_colors_by_pk?: Maybe<BrandColors>;
  /** update data of the table: "brand_lines" */
  update_brand_lines?: Maybe<BrandLinesMutationResponse>;
  /** update single row of the table: "brand_lines" */
  update_brand_lines_by_pk?: Maybe<BrandLines>;
  /** update data of the table: "brands" */
  update_brands?: Maybe<BrandsMutationResponse>;
  /** update single row of the table: "brands" */
  update_brands_by_pk?: Maybe<Brands>;
  /** update data of the table: "charts" */
  update_charts?: Maybe<ChartsMutationResponse>;
  /** update single row of the table: "charts" */
  update_charts_by_pk?: Maybe<Charts>;
  /** update data of the table: "charts_public" */
  update_charts_public?: Maybe<ChartsPublicMutationResponse>;
  /** update data of the table: "collections" */
  update_collections?: Maybe<CollectionsMutationResponse>;
  /** update single row of the table: "collections" */
  update_collections_by_pk?: Maybe<Collections>;
  /** update data of the table: "fabric_types" */
  update_fabric_types?: Maybe<FabricTypesMutationResponse>;
  /** update single row of the table: "fabric_types" */
  update_fabric_types_by_pk?: Maybe<FabricTypes>;
  /** update data of the table: "files" */
  update_files?: Maybe<FilesMutationResponse>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update data of the table: "finish_types" */
  update_finish_types?: Maybe<FinishTypesMutationResponse>;
  /** update single row of the table: "finish_types" */
  update_finish_types_by_pk?: Maybe<FinishTypes>;
  /** update data of the table: "mottling_types" */
  update_mottling_types?: Maybe<MottlingTypesMutationResponse>;
  /** update single row of the table: "mottling_types" */
  update_mottling_types_by_pk?: Maybe<MottlingTypes>;
  /** update data of the table: "order_statuses" */
  update_order_statuses?: Maybe<OrderStatusesMutationResponse>;
  /** update single row of the table: "order_statuses" */
  update_order_statuses_by_pk?: Maybe<OrderStatuses>;
  /** update data of the table: "orders" */
  update_orders?: Maybe<OrdersMutationResponse>;
  /** update single row of the table: "orders" */
  update_orders_by_pk?: Maybe<Orders>;
  /** update data of the table: "orders_items" */
  update_orders_items?: Maybe<OrdersItemsMutationResponse>;
  /** update single row of the table: "orders_items" */
  update_orders_items_by_pk?: Maybe<OrdersItems>;
  /** update data of the table: "products" */
  update_products?: Maybe<ProductsMutationResponse>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update data of the table: "products_collections" */
  update_products_collections?: Maybe<ProductsCollectionsMutationResponse>;
  /** update single row of the table: "products_collections" */
  update_products_collections_by_pk?: Maybe<ProductsCollections>;
  /** update data of the table: "products_images" */
  update_products_images?: Maybe<ProductsImagesMutationResponse>;
  /** update single row of the table: "products_images" */
  update_products_images_by_pk?: Maybe<ProductsImages>;
  /** update data of the table: "products_tags" */
  update_products_tags?: Maybe<ProductsTagsMutationResponse>;
  /** update single row of the table: "products_tags" */
  update_products_tags_by_pk?: Maybe<ProductsTags>;
  /** update data of the table: "projects" */
  update_projects?: Maybe<ProjectsMutationResponse>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update data of the table: "shops" */
  update_shops?: Maybe<ShopsMutationResponse>;
  /** update single row of the table: "shops" */
  update_shops_by_pk?: Maybe<Shops>;
  /** update data of the table: "stitch_groups" */
  update_stitch_groups?: Maybe<StitchGroupsMutationResponse>;
  /** update single row of the table: "stitch_groups" */
  update_stitch_groups_by_pk?: Maybe<StitchGroups>;
  /** update data of the table: "stitch_types" */
  update_stitch_types?: Maybe<StitchTypesMutationResponse>;
  /** update single row of the table: "stitch_types" */
  update_stitch_types_by_pk?: Maybe<StitchTypes>;
  /** update data of the table: "stripe_payment_intent_statuses" */
  update_stripe_payment_intent_statuses?: Maybe<
    StripePaymentIntentStatusesMutationResponse
  >;
  /** update single row of the table: "stripe_payment_intent_statuses" */
  update_stripe_payment_intent_statuses_by_pk?: Maybe<
    StripePaymentIntentStatuses
  >;
  /** update data of the table: "tags" */
  update_tags?: Maybe<TagsMutationResponse>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update data of the table: "themes" */
  update_themes?: Maybe<ThemesMutationResponse>;
  /** update single row of the table: "themes" */
  update_themes_by_pk?: Maybe<Themes>;
  /** update data of the table: "themes_public" */
  update_themes_public?: Maybe<ThemesPublicMutationResponse>;
  /** update data of the table: "themes_stitch_groups_threads" */
  update_themes_stitch_groups_threads?: Maybe<
    ThemesStitchGroupsThreadsMutationResponse
  >;
  /** update single row of the table: "themes_stitch_groups_threads" */
  update_themes_stitch_groups_threads_by_pk?: Maybe<ThemesStitchGroupsThreads>;
  /** update data of the table: "threads" */
  update_threads?: Maybe<ThreadsMutationResponse>;
  /** update single row of the table: "threads" */
  update_threads_by_pk?: Maybe<Threads>;
  /** update data of the table: "users" */
  update_users?: Maybe<UsersMutationResponse>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  verifyAuthCode: VerifyAuthCodeResponse;
};

/** mutation root */
export type MutationRootClaimProductsArgs = {
  productIds: Array<Scalars['String']>;
};

/** mutation root */
export type MutationRootCompleteFileUploadArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootCreateFirstTimeSessionArgs = {
  email: Scalars['String'];
};

/** mutation root */
export type MutationRootCreatePaymentIntentArgs = {
  products: Array<ProductInput>;
};

/** mutation root */
export type MutationRootDeleteAuthCodesArgs = {
  where: AuthCodesBoolExp;
};

/** mutation root */
export type MutationRootDeleteAuthCodesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteBrandColorsArgs = {
  where: BrandColorsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBrandColorsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteBrandLinesArgs = {
  where: BrandLinesBoolExp;
};

/** mutation root */
export type MutationRootDeleteBrandLinesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteBrandsArgs = {
  where: BrandsBoolExp;
};

/** mutation root */
export type MutationRootDeleteBrandsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteChartsArgs = {
  where: ChartsBoolExp;
};

/** mutation root */
export type MutationRootDeleteChartsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteChartsPublicArgs = {
  where: ChartsPublicBoolExp;
};

/** mutation root */
export type MutationRootDeleteCollectionsArgs = {
  where: CollectionsBoolExp;
};

/** mutation root */
export type MutationRootDeleteCollectionsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteFabricTypesArgs = {
  where: FabricTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteFabricTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteFilesArgs = {
  where: FilesBoolExp;
};

/** mutation root */
export type MutationRootDeleteFilesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteFinishTypesArgs = {
  where: FinishTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteFinishTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteMottlingTypesArgs = {
  where: MottlingTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteMottlingTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteOrderStatusesArgs = {
  where: OrderStatusesBoolExp;
};

/** mutation root */
export type MutationRootDeleteOrderStatusesByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteOrdersArgs = {
  where: OrdersBoolExp;
};

/** mutation root */
export type MutationRootDeleteOrdersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteOrdersItemsArgs = {
  where: OrdersItemsBoolExp;
};

/** mutation root */
export type MutationRootDeleteOrdersItemsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteProductsArgs = {
  where: ProductsBoolExp;
};

/** mutation root */
export type MutationRootDeleteProductsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteProductsCollectionsArgs = {
  where: ProductsCollectionsBoolExp;
};

/** mutation root */
export type MutationRootDeleteProductsCollectionsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteProductsImagesArgs = {
  where: ProductsImagesBoolExp;
};

/** mutation root */
export type MutationRootDeleteProductsImagesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteProductsTagsArgs = {
  where: ProductsTagsBoolExp;
};

/** mutation root */
export type MutationRootDeleteProductsTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteProjectsArgs = {
  where: ProjectsBoolExp;
};

/** mutation root */
export type MutationRootDeleteProjectsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteShopsArgs = {
  where: ShopsBoolExp;
};

/** mutation root */
export type MutationRootDeleteShopsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteStitchGroupsArgs = {
  where: StitchGroupsBoolExp;
};

/** mutation root */
export type MutationRootDeleteStitchGroupsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteStitchTypesArgs = {
  where: StitchTypesBoolExp;
};

/** mutation root */
export type MutationRootDeleteStitchTypesByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteStripePaymentIntentStatusesArgs = {
  where: StripePaymentIntentStatusesBoolExp;
};

/** mutation root */
export type MutationRootDeleteStripePaymentIntentStatusesByPkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type MutationRootDeleteTagsArgs = {
  where: TagsBoolExp;
};

/** mutation root */
export type MutationRootDeleteTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteThemesArgs = {
  where: ThemesBoolExp;
};

/** mutation root */
export type MutationRootDeleteThemesByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteThemesPublicArgs = {
  where: ThemesPublicBoolExp;
};

/** mutation root */
export type MutationRootDeleteThemesStitchGroupsThreadsArgs = {
  where: ThemesStitchGroupsThreadsBoolExp;
};

/** mutation root */
export type MutationRootDeleteThemesStitchGroupsThreadsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteThreadsArgs = {
  where: ThreadsBoolExp;
};

/** mutation root */
export type MutationRootDeleteThreadsByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootDeleteUsersArgs = {
  where: UsersBoolExp;
};

/** mutation root */
export type MutationRootDeleteUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type MutationRootInsertAuthCodesArgs = {
  objects: Array<AuthCodesInsertInput>;
  on_conflict?: Maybe<AuthCodesOnConflict>;
};

/** mutation root */
export type MutationRootInsertAuthCodesOneArgs = {
  object: AuthCodesInsertInput;
  on_conflict?: Maybe<AuthCodesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBrandColorsArgs = {
  objects: Array<BrandColorsInsertInput>;
  on_conflict?: Maybe<BrandColorsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBrandColorsOneArgs = {
  object: BrandColorsInsertInput;
  on_conflict?: Maybe<BrandColorsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBrandLinesArgs = {
  objects: Array<BrandLinesInsertInput>;
  on_conflict?: Maybe<BrandLinesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBrandLinesOneArgs = {
  object: BrandLinesInsertInput;
  on_conflict?: Maybe<BrandLinesOnConflict>;
};

/** mutation root */
export type MutationRootInsertBrandsArgs = {
  objects: Array<BrandsInsertInput>;
  on_conflict?: Maybe<BrandsOnConflict>;
};

/** mutation root */
export type MutationRootInsertBrandsOneArgs = {
  object: BrandsInsertInput;
  on_conflict?: Maybe<BrandsOnConflict>;
};

/** mutation root */
export type MutationRootInsertChartsArgs = {
  objects: Array<ChartsInsertInput>;
  on_conflict?: Maybe<ChartsOnConflict>;
};

/** mutation root */
export type MutationRootInsertChartsOneArgs = {
  object: ChartsInsertInput;
  on_conflict?: Maybe<ChartsOnConflict>;
};

/** mutation root */
export type MutationRootInsertChartsPublicArgs = {
  objects: Array<ChartsPublicInsertInput>;
};

/** mutation root */
export type MutationRootInsertChartsPublicOneArgs = {
  object: ChartsPublicInsertInput;
};

/** mutation root */
export type MutationRootInsertCollectionsArgs = {
  objects: Array<CollectionsInsertInput>;
  on_conflict?: Maybe<CollectionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertCollectionsOneArgs = {
  object: CollectionsInsertInput;
  on_conflict?: Maybe<CollectionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertFabricTypesArgs = {
  objects: Array<FabricTypesInsertInput>;
  on_conflict?: Maybe<FabricTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFabricTypesOneArgs = {
  object: FabricTypesInsertInput;
  on_conflict?: Maybe<FabricTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFilesArgs = {
  objects: Array<FilesInsertInput>;
  on_conflict?: Maybe<FilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFilesOneArgs = {
  object: FilesInsertInput;
  on_conflict?: Maybe<FilesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFinishTypesArgs = {
  objects: Array<FinishTypesInsertInput>;
  on_conflict?: Maybe<FinishTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertFinishTypesOneArgs = {
  object: FinishTypesInsertInput;
  on_conflict?: Maybe<FinishTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMottlingTypesArgs = {
  objects: Array<MottlingTypesInsertInput>;
  on_conflict?: Maybe<MottlingTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertMottlingTypesOneArgs = {
  object: MottlingTypesInsertInput;
  on_conflict?: Maybe<MottlingTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrderStatusesArgs = {
  objects: Array<OrderStatusesInsertInput>;
  on_conflict?: Maybe<OrderStatusesOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrderStatusesOneArgs = {
  object: OrderStatusesInsertInput;
  on_conflict?: Maybe<OrderStatusesOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrdersArgs = {
  objects: Array<OrdersInsertInput>;
  on_conflict?: Maybe<OrdersOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrdersItemsArgs = {
  objects: Array<OrdersItemsInsertInput>;
  on_conflict?: Maybe<OrdersItemsOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrdersItemsOneArgs = {
  object: OrdersItemsInsertInput;
  on_conflict?: Maybe<OrdersItemsOnConflict>;
};

/** mutation root */
export type MutationRootInsertOrdersOneArgs = {
  object: OrdersInsertInput;
  on_conflict?: Maybe<OrdersOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsArgs = {
  objects: Array<ProductsInsertInput>;
  on_conflict?: Maybe<ProductsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsCollectionsArgs = {
  objects: Array<ProductsCollectionsInsertInput>;
  on_conflict?: Maybe<ProductsCollectionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsCollectionsOneArgs = {
  object: ProductsCollectionsInsertInput;
  on_conflict?: Maybe<ProductsCollectionsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsImagesArgs = {
  objects: Array<ProductsImagesInsertInput>;
  on_conflict?: Maybe<ProductsImagesOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsImagesOneArgs = {
  object: ProductsImagesInsertInput;
  on_conflict?: Maybe<ProductsImagesOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsOneArgs = {
  object: ProductsInsertInput;
  on_conflict?: Maybe<ProductsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsTagsArgs = {
  objects: Array<ProductsTagsInsertInput>;
  on_conflict?: Maybe<ProductsTagsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProductsTagsOneArgs = {
  object: ProductsTagsInsertInput;
  on_conflict?: Maybe<ProductsTagsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProjectsArgs = {
  objects: Array<ProjectsInsertInput>;
  on_conflict?: Maybe<ProjectsOnConflict>;
};

/** mutation root */
export type MutationRootInsertProjectsOneArgs = {
  object: ProjectsInsertInput;
  on_conflict?: Maybe<ProjectsOnConflict>;
};

/** mutation root */
export type MutationRootInsertShopsArgs = {
  objects: Array<ShopsInsertInput>;
  on_conflict?: Maybe<ShopsOnConflict>;
};

/** mutation root */
export type MutationRootInsertShopsOneArgs = {
  object: ShopsInsertInput;
  on_conflict?: Maybe<ShopsOnConflict>;
};

/** mutation root */
export type MutationRootInsertStitchGroupsArgs = {
  objects: Array<StitchGroupsInsertInput>;
  on_conflict?: Maybe<StitchGroupsOnConflict>;
};

/** mutation root */
export type MutationRootInsertStitchGroupsOneArgs = {
  object: StitchGroupsInsertInput;
  on_conflict?: Maybe<StitchGroupsOnConflict>;
};

/** mutation root */
export type MutationRootInsertStitchTypesArgs = {
  objects: Array<StitchTypesInsertInput>;
  on_conflict?: Maybe<StitchTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertStitchTypesOneArgs = {
  object: StitchTypesInsertInput;
  on_conflict?: Maybe<StitchTypesOnConflict>;
};

/** mutation root */
export type MutationRootInsertStripePaymentIntentStatusesArgs = {
  objects: Array<StripePaymentIntentStatusesInsertInput>;
  on_conflict?: Maybe<StripePaymentIntentStatusesOnConflict>;
};

/** mutation root */
export type MutationRootInsertStripePaymentIntentStatusesOneArgs = {
  object: StripePaymentIntentStatusesInsertInput;
  on_conflict?: Maybe<StripePaymentIntentStatusesOnConflict>;
};

/** mutation root */
export type MutationRootInsertTagsArgs = {
  objects: Array<TagsInsertInput>;
  on_conflict?: Maybe<TagsOnConflict>;
};

/** mutation root */
export type MutationRootInsertTagsOneArgs = {
  object: TagsInsertInput;
  on_conflict?: Maybe<TagsOnConflict>;
};

/** mutation root */
export type MutationRootInsertThemesArgs = {
  objects: Array<ThemesInsertInput>;
  on_conflict?: Maybe<ThemesOnConflict>;
};

/** mutation root */
export type MutationRootInsertThemesOneArgs = {
  object: ThemesInsertInput;
  on_conflict?: Maybe<ThemesOnConflict>;
};

/** mutation root */
export type MutationRootInsertThemesPublicArgs = {
  objects: Array<ThemesPublicInsertInput>;
};

/** mutation root */
export type MutationRootInsertThemesPublicOneArgs = {
  object: ThemesPublicInsertInput;
};

/** mutation root */
export type MutationRootInsertThemesStitchGroupsThreadsArgs = {
  objects: Array<ThemesStitchGroupsThreadsInsertInput>;
  on_conflict?: Maybe<ThemesStitchGroupsThreadsOnConflict>;
};

/** mutation root */
export type MutationRootInsertThemesStitchGroupsThreadsOneArgs = {
  object: ThemesStitchGroupsThreadsInsertInput;
  on_conflict?: Maybe<ThemesStitchGroupsThreadsOnConflict>;
};

/** mutation root */
export type MutationRootInsertThreadsArgs = {
  objects: Array<ThreadsInsertInput>;
  on_conflict?: Maybe<ThreadsOnConflict>;
};

/** mutation root */
export type MutationRootInsertThreadsOneArgs = {
  object: ThreadsInsertInput;
  on_conflict?: Maybe<ThreadsOnConflict>;
};

/** mutation root */
export type MutationRootInsertUsersArgs = {
  objects: Array<UsersInsertInput>;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** mutation root */
export type MutationRootInsertUsersOneArgs = {
  object: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** mutation root */
export type MutationRootRefreshOrderStatusArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type MutationRootSendAuthCodeArgs = {
  email: Scalars['String'];
  intent: Scalars['String'];
};

/** mutation root */
export type MutationRootSignUpArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  productIds: Array<Scalars['String']>;
};

/** mutation root */
export type MutationRootUpdateAuthCodesArgs = {
  _inc?: Maybe<AuthCodesIncInput>;
  _set?: Maybe<AuthCodesSetInput>;
  where: AuthCodesBoolExp;
};

/** mutation root */
export type MutationRootUpdateAuthCodesByPkArgs = {
  _inc?: Maybe<AuthCodesIncInput>;
  _set?: Maybe<AuthCodesSetInput>;
  pk_columns: AuthCodesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBrandColorsArgs = {
  _set?: Maybe<BrandColorsSetInput>;
  where: BrandColorsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBrandColorsByPkArgs = {
  _set?: Maybe<BrandColorsSetInput>;
  pk_columns: BrandColorsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBrandLinesArgs = {
  _set?: Maybe<BrandLinesSetInput>;
  where: BrandLinesBoolExp;
};

/** mutation root */
export type MutationRootUpdateBrandLinesByPkArgs = {
  _set?: Maybe<BrandLinesSetInput>;
  pk_columns: BrandLinesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateBrandsArgs = {
  _set?: Maybe<BrandsSetInput>;
  where: BrandsBoolExp;
};

/** mutation root */
export type MutationRootUpdateBrandsByPkArgs = {
  _set?: Maybe<BrandsSetInput>;
  pk_columns: BrandsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateChartsArgs = {
  _inc?: Maybe<ChartsIncInput>;
  _set?: Maybe<ChartsSetInput>;
  where: ChartsBoolExp;
};

/** mutation root */
export type MutationRootUpdateChartsByPkArgs = {
  _inc?: Maybe<ChartsIncInput>;
  _set?: Maybe<ChartsSetInput>;
  pk_columns: ChartsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateChartsPublicArgs = {
  _inc?: Maybe<ChartsPublicIncInput>;
  _set?: Maybe<ChartsPublicSetInput>;
  where: ChartsPublicBoolExp;
};

/** mutation root */
export type MutationRootUpdateCollectionsArgs = {
  _set?: Maybe<CollectionsSetInput>;
  where: CollectionsBoolExp;
};

/** mutation root */
export type MutationRootUpdateCollectionsByPkArgs = {
  _set?: Maybe<CollectionsSetInput>;
  pk_columns: CollectionsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateFabricTypesArgs = {
  _set?: Maybe<FabricTypesSetInput>;
  where: FabricTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateFabricTypesByPkArgs = {
  _set?: Maybe<FabricTypesSetInput>;
  pk_columns: FabricTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateFilesArgs = {
  _inc?: Maybe<FilesIncInput>;
  _set?: Maybe<FilesSetInput>;
  where: FilesBoolExp;
};

/** mutation root */
export type MutationRootUpdateFilesByPkArgs = {
  _inc?: Maybe<FilesIncInput>;
  _set?: Maybe<FilesSetInput>;
  pk_columns: FilesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateFinishTypesArgs = {
  _set?: Maybe<FinishTypesSetInput>;
  where: FinishTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateFinishTypesByPkArgs = {
  _set?: Maybe<FinishTypesSetInput>;
  pk_columns: FinishTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateMottlingTypesArgs = {
  _set?: Maybe<MottlingTypesSetInput>;
  where: MottlingTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateMottlingTypesByPkArgs = {
  _set?: Maybe<MottlingTypesSetInput>;
  pk_columns: MottlingTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateOrderStatusesArgs = {
  _set?: Maybe<OrderStatusesSetInput>;
  where: OrderStatusesBoolExp;
};

/** mutation root */
export type MutationRootUpdateOrderStatusesByPkArgs = {
  _set?: Maybe<OrderStatusesSetInput>;
  pk_columns: OrderStatusesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateOrdersArgs = {
  _inc?: Maybe<OrdersIncInput>;
  _set?: Maybe<OrdersSetInput>;
  where: OrdersBoolExp;
};

/** mutation root */
export type MutationRootUpdateOrdersByPkArgs = {
  _inc?: Maybe<OrdersIncInput>;
  _set?: Maybe<OrdersSetInput>;
  pk_columns: OrdersPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateOrdersItemsArgs = {
  _inc?: Maybe<OrdersItemsIncInput>;
  _set?: Maybe<OrdersItemsSetInput>;
  where: OrdersItemsBoolExp;
};

/** mutation root */
export type MutationRootUpdateOrdersItemsByPkArgs = {
  _inc?: Maybe<OrdersItemsIncInput>;
  _set?: Maybe<OrdersItemsSetInput>;
  pk_columns: OrdersItemsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProductsArgs = {
  _inc?: Maybe<ProductsIncInput>;
  _set?: Maybe<ProductsSetInput>;
  where: ProductsBoolExp;
};

/** mutation root */
export type MutationRootUpdateProductsByPkArgs = {
  _inc?: Maybe<ProductsIncInput>;
  _set?: Maybe<ProductsSetInput>;
  pk_columns: ProductsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProductsCollectionsArgs = {
  _inc?: Maybe<ProductsCollectionsIncInput>;
  _set?: Maybe<ProductsCollectionsSetInput>;
  where: ProductsCollectionsBoolExp;
};

/** mutation root */
export type MutationRootUpdateProductsCollectionsByPkArgs = {
  _inc?: Maybe<ProductsCollectionsIncInput>;
  _set?: Maybe<ProductsCollectionsSetInput>;
  pk_columns: ProductsCollectionsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProductsImagesArgs = {
  _inc?: Maybe<ProductsImagesIncInput>;
  _set?: Maybe<ProductsImagesSetInput>;
  where: ProductsImagesBoolExp;
};

/** mutation root */
export type MutationRootUpdateProductsImagesByPkArgs = {
  _inc?: Maybe<ProductsImagesIncInput>;
  _set?: Maybe<ProductsImagesSetInput>;
  pk_columns: ProductsImagesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProductsTagsArgs = {
  _set?: Maybe<ProductsTagsSetInput>;
  where: ProductsTagsBoolExp;
};

/** mutation root */
export type MutationRootUpdateProductsTagsByPkArgs = {
  _set?: Maybe<ProductsTagsSetInput>;
  pk_columns: ProductsTagsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateProjectsArgs = {
  _set?: Maybe<ProjectsSetInput>;
  where: ProjectsBoolExp;
};

/** mutation root */
export type MutationRootUpdateProjectsByPkArgs = {
  _set?: Maybe<ProjectsSetInput>;
  pk_columns: ProjectsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateShopsArgs = {
  _set?: Maybe<ShopsSetInput>;
  where: ShopsBoolExp;
};

/** mutation root */
export type MutationRootUpdateShopsByPkArgs = {
  _set?: Maybe<ShopsSetInput>;
  pk_columns: ShopsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateStitchGroupsArgs = {
  _inc?: Maybe<StitchGroupsIncInput>;
  _set?: Maybe<StitchGroupsSetInput>;
  where: StitchGroupsBoolExp;
};

/** mutation root */
export type MutationRootUpdateStitchGroupsByPkArgs = {
  _inc?: Maybe<StitchGroupsIncInput>;
  _set?: Maybe<StitchGroupsSetInput>;
  pk_columns: StitchGroupsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateStitchTypesArgs = {
  _set?: Maybe<StitchTypesSetInput>;
  where: StitchTypesBoolExp;
};

/** mutation root */
export type MutationRootUpdateStitchTypesByPkArgs = {
  _set?: Maybe<StitchTypesSetInput>;
  pk_columns: StitchTypesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateStripePaymentIntentStatusesArgs = {
  _set?: Maybe<StripePaymentIntentStatusesSetInput>;
  where: StripePaymentIntentStatusesBoolExp;
};

/** mutation root */
export type MutationRootUpdateStripePaymentIntentStatusesByPkArgs = {
  _set?: Maybe<StripePaymentIntentStatusesSetInput>;
  pk_columns: StripePaymentIntentStatusesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateTagsArgs = {
  _set?: Maybe<TagsSetInput>;
  where: TagsBoolExp;
};

/** mutation root */
export type MutationRootUpdateTagsByPkArgs = {
  _set?: Maybe<TagsSetInput>;
  pk_columns: TagsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateThemesArgs = {
  _inc?: Maybe<ThemesIncInput>;
  _set?: Maybe<ThemesSetInput>;
  where: ThemesBoolExp;
};

/** mutation root */
export type MutationRootUpdateThemesByPkArgs = {
  _inc?: Maybe<ThemesIncInput>;
  _set?: Maybe<ThemesSetInput>;
  pk_columns: ThemesPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateThemesPublicArgs = {
  _inc?: Maybe<ThemesPublicIncInput>;
  _set?: Maybe<ThemesPublicSetInput>;
  where: ThemesPublicBoolExp;
};

/** mutation root */
export type MutationRootUpdateThemesStitchGroupsThreadsArgs = {
  _inc?: Maybe<ThemesStitchGroupsThreadsIncInput>;
  _set?: Maybe<ThemesStitchGroupsThreadsSetInput>;
  where: ThemesStitchGroupsThreadsBoolExp;
};

/** mutation root */
export type MutationRootUpdateThemesStitchGroupsThreadsByPkArgs = {
  _inc?: Maybe<ThemesStitchGroupsThreadsIncInput>;
  _set?: Maybe<ThemesStitchGroupsThreadsSetInput>;
  pk_columns: ThemesStitchGroupsThreadsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateThreadsArgs = {
  _set?: Maybe<ThreadsSetInput>;
  where: ThreadsBoolExp;
};

/** mutation root */
export type MutationRootUpdateThreadsByPkArgs = {
  _set?: Maybe<ThreadsSetInput>;
  pk_columns: ThreadsPkColumnsInput;
};

/** mutation root */
export type MutationRootUpdateUsersArgs = {
  _inc?: Maybe<UsersIncInput>;
  _set?: Maybe<UsersSetInput>;
  where: UsersBoolExp;
};

/** mutation root */
export type MutationRootUpdateUsersByPkArgs = {
  _inc?: Maybe<UsersIncInput>;
  _set?: Maybe<UsersSetInput>;
  pk_columns: UsersPkColumnsInput;
};

/** mutation root */
export type MutationRootVerifyAuthCodeArgs = {
  code: Scalars['Int'];
  email: Scalars['String'];
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum OrderBy {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "order_statuses" */
export type OrderStatuses = {
  __typename?: 'order_statuses';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "order_statuses" */
export type OrderStatusesAggregate = {
  __typename?: 'order_statuses_aggregate';
  aggregate?: Maybe<OrderStatusesAggregateFields>;
  nodes: Array<OrderStatuses>;
};

/** aggregate fields of "order_statuses" */
export type OrderStatusesAggregateFields = {
  __typename?: 'order_statuses_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrderStatusesMaxFields>;
  min?: Maybe<OrderStatusesMinFields>;
};

/** aggregate fields of "order_statuses" */
export type OrderStatusesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrderStatusesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "order_statuses" */
export type OrderStatusesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<OrderStatusesMaxOrderBy>;
  min?: Maybe<OrderStatusesMinOrderBy>;
};

/** input type for inserting array relation for remote table "order_statuses" */
export type OrderStatusesArrRelInsertInput = {
  data: Array<OrderStatusesInsertInput>;
  on_conflict?: Maybe<OrderStatusesOnConflict>;
};

/** Boolean expression to filter rows from the table "order_statuses". All fields are combined with a logical 'AND'. */
export type OrderStatusesBoolExp = {
  _and?: Maybe<Array<Maybe<OrderStatusesBoolExp>>>;
  _not?: Maybe<OrderStatusesBoolExp>;
  _or?: Maybe<Array<Maybe<OrderStatusesBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "order_statuses" */
export enum OrderStatusesConstraint {
  /** unique or primary key constraint */
  OrderStatusesPkey = 'order_statuses_pkey',
}

export enum OrderStatusesEnum {
  /** Canceled */
  Canceled = 'canceled',
  /** Complete */
  Complete = 'complete',
  /** Incomplete */
  Incomplete = 'incomplete',
}

/** expression to compare columns of type order_statuses_enum. All fields are combined with logical 'AND'. */
export type OrderStatusesEnumComparisonExp = {
  _eq?: Maybe<OrderStatusesEnum>;
  _in?: Maybe<Array<OrderStatusesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<OrderStatusesEnum>;
  _nin?: Maybe<Array<OrderStatusesEnum>>;
};

/** input type for inserting data into table "order_statuses" */
export type OrderStatusesInsertInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type OrderStatusesMaxFields = {
  __typename?: 'order_statuses_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "order_statuses" */
export type OrderStatusesMaxOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrderStatusesMinFields = {
  __typename?: 'order_statuses_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "order_statuses" */
export type OrderStatusesMinOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "order_statuses" */
export type OrderStatusesMutationResponse = {
  __typename?: 'order_statuses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrderStatuses>;
};

/** input type for inserting object relation for remote table "order_statuses" */
export type OrderStatusesObjRelInsertInput = {
  data: OrderStatusesInsertInput;
  on_conflict?: Maybe<OrderStatusesOnConflict>;
};

/** on conflict condition type for table "order_statuses" */
export type OrderStatusesOnConflict = {
  constraint: OrderStatusesConstraint;
  update_columns: Array<OrderStatusesUpdateColumn>;
  where?: Maybe<OrderStatusesBoolExp>;
};

/** ordering options when selecting data from "order_statuses" */
export type OrderStatusesOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** primary key columns input for table: "order_statuses" */
export type OrderStatusesPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "order_statuses" */
export enum OrderStatusesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "order_statuses" */
export type OrderStatusesSetInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "order_statuses" */
export enum OrderStatusesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** columns and relationships of "orders" */
export type Orders = {
  __typename?: 'orders';
  amount: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer: Users;
  customer_id: Scalars['uuid'];
  email_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  items: Array<OrdersItems>;
  /** An aggregated array relationship */
  items_aggregate: OrdersItemsAggregate;
  status?: Maybe<OrderStatusesEnum>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_status?: Maybe<StripePaymentIntentStatusesEnum>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "orders" */
export type OrdersItemsArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** columns and relationships of "orders" */
export type OrdersItemsAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** aggregated selection of "orders" */
export type OrdersAggregate = {
  __typename?: 'orders_aggregate';
  aggregate?: Maybe<OrdersAggregateFields>;
  nodes: Array<Orders>;
};

/** aggregate fields of "orders" */
export type OrdersAggregateFields = {
  __typename?: 'orders_aggregate_fields';
  avg?: Maybe<OrdersAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrdersMaxFields>;
  min?: Maybe<OrdersMinFields>;
  stddev?: Maybe<OrdersStddevFields>;
  stddev_pop?: Maybe<OrdersStddevPopFields>;
  stddev_samp?: Maybe<OrdersStddevSampFields>;
  sum?: Maybe<OrdersSumFields>;
  var_pop?: Maybe<OrdersVarPopFields>;
  var_samp?: Maybe<OrdersVarSampFields>;
  variance?: Maybe<OrdersVarianceFields>;
};

/** aggregate fields of "orders" */
export type OrdersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrdersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders" */
export type OrdersAggregateOrderBy = {
  avg?: Maybe<OrdersAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OrdersMaxOrderBy>;
  min?: Maybe<OrdersMinOrderBy>;
  stddev?: Maybe<OrdersStddevOrderBy>;
  stddev_pop?: Maybe<OrdersStddevPopOrderBy>;
  stddev_samp?: Maybe<OrdersStddevSampOrderBy>;
  sum?: Maybe<OrdersSumOrderBy>;
  var_pop?: Maybe<OrdersVarPopOrderBy>;
  var_samp?: Maybe<OrdersVarSampOrderBy>;
  variance?: Maybe<OrdersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "orders" */
export type OrdersArrRelInsertInput = {
  data: Array<OrdersInsertInput>;
  on_conflict?: Maybe<OrdersOnConflict>;
};

/** aggregate avg on columns */
export type OrdersAvgFields = {
  __typename?: 'orders_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders" */
export type OrdersAvgOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "orders". All fields are combined with a logical 'AND'. */
export type OrdersBoolExp = {
  _and?: Maybe<Array<Maybe<OrdersBoolExp>>>;
  _not?: Maybe<OrdersBoolExp>;
  _or?: Maybe<Array<Maybe<OrdersBoolExp>>>;
  amount?: Maybe<IntComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  customer?: Maybe<UsersBoolExp>;
  customer_id?: Maybe<UuidComparisonExp>;
  email_id?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  items?: Maybe<OrdersItemsBoolExp>;
  status?: Maybe<OrderStatusesEnumComparisonExp>;
  stripe_payment_intent_id?: Maybe<StringComparisonExp>;
  stripe_payment_intent_status?: Maybe<
    StripePaymentIntentStatusesEnumComparisonExp
  >;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "orders" */
export enum OrdersConstraint {
  /** unique or primary key constraint */
  OrdersPkey = 'orders_pkey',
  /** unique or primary key constraint */
  OrdersRequiringPaymentMethodIdx = 'orders_requiring_payment_method_idx',
  /** unique or primary key constraint */
  OrdersTransactionIdKey = 'orders_transaction_id_key',
}

/** input type for incrementing integer column in table "orders" */
export type OrdersIncInput = {
  amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "orders" */
export type OrdersInsertInput = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer?: Maybe<UsersObjRelInsertInput>;
  customer_id?: Maybe<Scalars['uuid']>;
  email_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  items?: Maybe<OrdersItemsArrRelInsertInput>;
  status?: Maybe<OrderStatusesEnum>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_status?: Maybe<StripePaymentIntentStatusesEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "orders_items" */
export type OrdersItems = {
  __typename?: 'orders_items';
  amount: Scalars['Int'];
  id: Scalars['uuid'];
  /** An object relationship */
  order: Orders;
  order_id: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  product_id: Scalars['uuid'];
};

/** aggregated selection of "orders_items" */
export type OrdersItemsAggregate = {
  __typename?: 'orders_items_aggregate';
  aggregate?: Maybe<OrdersItemsAggregateFields>;
  nodes: Array<OrdersItems>;
};

/** aggregate fields of "orders_items" */
export type OrdersItemsAggregateFields = {
  __typename?: 'orders_items_aggregate_fields';
  avg?: Maybe<OrdersItemsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<OrdersItemsMaxFields>;
  min?: Maybe<OrdersItemsMinFields>;
  stddev?: Maybe<OrdersItemsStddevFields>;
  stddev_pop?: Maybe<OrdersItemsStddevPopFields>;
  stddev_samp?: Maybe<OrdersItemsStddevSampFields>;
  sum?: Maybe<OrdersItemsSumFields>;
  var_pop?: Maybe<OrdersItemsVarPopFields>;
  var_samp?: Maybe<OrdersItemsVarSampFields>;
  variance?: Maybe<OrdersItemsVarianceFields>;
};

/** aggregate fields of "orders_items" */
export type OrdersItemsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<OrdersItemsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "orders_items" */
export type OrdersItemsAggregateOrderBy = {
  avg?: Maybe<OrdersItemsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<OrdersItemsMaxOrderBy>;
  min?: Maybe<OrdersItemsMinOrderBy>;
  stddev?: Maybe<OrdersItemsStddevOrderBy>;
  stddev_pop?: Maybe<OrdersItemsStddevPopOrderBy>;
  stddev_samp?: Maybe<OrdersItemsStddevSampOrderBy>;
  sum?: Maybe<OrdersItemsSumOrderBy>;
  var_pop?: Maybe<OrdersItemsVarPopOrderBy>;
  var_samp?: Maybe<OrdersItemsVarSampOrderBy>;
  variance?: Maybe<OrdersItemsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "orders_items" */
export type OrdersItemsArrRelInsertInput = {
  data: Array<OrdersItemsInsertInput>;
  on_conflict?: Maybe<OrdersItemsOnConflict>;
};

/** aggregate avg on columns */
export type OrdersItemsAvgFields = {
  __typename?: 'orders_items_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "orders_items" */
export type OrdersItemsAvgOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "orders_items". All fields are combined with a logical 'AND'. */
export type OrdersItemsBoolExp = {
  _and?: Maybe<Array<Maybe<OrdersItemsBoolExp>>>;
  _not?: Maybe<OrdersItemsBoolExp>;
  _or?: Maybe<Array<Maybe<OrdersItemsBoolExp>>>;
  amount?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  order?: Maybe<OrdersBoolExp>;
  order_id?: Maybe<UuidComparisonExp>;
  product?: Maybe<ProductsBoolExp>;
  product_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "orders_items" */
export enum OrdersItemsConstraint {
  /** unique or primary key constraint */
  OrdersItemsOrderIdProductIdKey = 'orders_items_order_id_product_id_key',
  /** unique or primary key constraint */
  OrdersItemsPkey = 'orders_items_pkey',
}

/** input type for incrementing integer column in table "orders_items" */
export type OrdersItemsIncInput = {
  amount?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "orders_items" */
export type OrdersItemsInsertInput = {
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<OrdersObjRelInsertInput>;
  order_id?: Maybe<Scalars['uuid']>;
  product?: Maybe<ProductsObjRelInsertInput>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type OrdersItemsMaxFields = {
  __typename?: 'orders_items_max_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "orders_items" */
export type OrdersItemsMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  order_id?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrdersItemsMinFields = {
  __typename?: 'orders_items_min_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "orders_items" */
export type OrdersItemsMinOrderBy = {
  amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  order_id?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "orders_items" */
export type OrdersItemsMutationResponse = {
  __typename?: 'orders_items_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<OrdersItems>;
};

/** input type for inserting object relation for remote table "orders_items" */
export type OrdersItemsObjRelInsertInput = {
  data: OrdersItemsInsertInput;
  on_conflict?: Maybe<OrdersItemsOnConflict>;
};

/** on conflict condition type for table "orders_items" */
export type OrdersItemsOnConflict = {
  constraint: OrdersItemsConstraint;
  update_columns: Array<OrdersItemsUpdateColumn>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** ordering options when selecting data from "orders_items" */
export type OrdersItemsOrderBy = {
  amount?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  order?: Maybe<OrdersOrderBy>;
  order_id?: Maybe<OrderBy>;
  product?: Maybe<ProductsOrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orders_items" */
export type OrdersItemsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "orders_items" */
export enum OrdersItemsSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
}

/** input type for updating data in table "orders_items" */
export type OrdersItemsSetInput = {
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  order_id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type OrdersItemsStddevFields = {
  __typename?: 'orders_items_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders_items" */
export type OrdersItemsStddevOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrdersItemsStddevPopFields = {
  __typename?: 'orders_items_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders_items" */
export type OrdersItemsStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrdersItemsStddevSampFields = {
  __typename?: 'orders_items_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders_items" */
export type OrdersItemsStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrdersItemsSumFields = {
  __typename?: 'orders_items_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "orders_items" */
export type OrdersItemsSumOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** update columns of table "orders_items" */
export enum OrdersItemsUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
}

/** aggregate var_pop on columns */
export type OrdersItemsVarPopFields = {
  __typename?: 'orders_items_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders_items" */
export type OrdersItemsVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrdersItemsVarSampFields = {
  __typename?: 'orders_items_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders_items" */
export type OrdersItemsVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrdersItemsVarianceFields = {
  __typename?: 'orders_items_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders_items" */
export type OrdersItemsVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate max on columns */
export type OrdersMaxFields = {
  __typename?: 'orders_max_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  email_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "orders" */
export type OrdersMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_id?: Maybe<OrderBy>;
  email_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  stripe_payment_intent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type OrdersMinFields = {
  __typename?: 'orders_min_fields';
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  email_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "orders" */
export type OrdersMinOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer_id?: Maybe<OrderBy>;
  email_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  stripe_payment_intent_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "orders" */
export type OrdersMutationResponse = {
  __typename?: 'orders_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Orders>;
};

/** input type for inserting object relation for remote table "orders" */
export type OrdersObjRelInsertInput = {
  data: OrdersInsertInput;
  on_conflict?: Maybe<OrdersOnConflict>;
};

/** on conflict condition type for table "orders" */
export type OrdersOnConflict = {
  constraint: OrdersConstraint;
  update_columns: Array<OrdersUpdateColumn>;
  where?: Maybe<OrdersBoolExp>;
};

/** ordering options when selecting data from "orders" */
export type OrdersOrderBy = {
  amount?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  customer?: Maybe<UsersOrderBy>;
  customer_id?: Maybe<OrderBy>;
  email_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  items_aggregate?: Maybe<OrdersItemsAggregateOrderBy>;
  status?: Maybe<OrderBy>;
  stripe_payment_intent_id?: Maybe<OrderBy>;
  stripe_payment_intent_status?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "orders" */
export type OrdersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "orders" */
export enum OrdersSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  StripePaymentIntentStatus = 'stripe_payment_intent_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "orders" */
export type OrdersSetInput = {
  amount?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['uuid']>;
  email_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<OrderStatusesEnum>;
  stripe_payment_intent_id?: Maybe<Scalars['String']>;
  stripe_payment_intent_status?: Maybe<StripePaymentIntentStatusesEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type OrdersStddevFields = {
  __typename?: 'orders_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "orders" */
export type OrdersStddevOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type OrdersStddevPopFields = {
  __typename?: 'orders_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "orders" */
export type OrdersStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type OrdersStddevSampFields = {
  __typename?: 'orders_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "orders" */
export type OrdersStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type OrdersSumFields = {
  __typename?: 'orders_sum_fields';
  amount?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "orders" */
export type OrdersSumOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** update columns of table "orders" */
export enum OrdersUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  StripePaymentIntentId = 'stripe_payment_intent_id',
  /** column name */
  StripePaymentIntentStatus = 'stripe_payment_intent_status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type OrdersVarPopFields = {
  __typename?: 'orders_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "orders" */
export type OrdersVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type OrdersVarSampFields = {
  __typename?: 'orders_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "orders" */
export type OrdersVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type OrdersVarianceFields = {
  __typename?: 'orders_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "orders" */
export type OrdersVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
};

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  /** An object relationship */
  chart: Charts;
  chart_id: Scalars['uuid'];
  /** An object relationship */
  chart_public?: Maybe<ChartsPublic>;
  /** An array relationship */
  collections: Array<ProductsCollections>;
  /** An aggregated array relationship */
  collections_aggregate: ProductsCollectionsAggregate;
  created_at: Scalars['timestamptz'];
  currency: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  images: Array<ProductsImages>;
  /** An aggregated array relationship */
  images_aggregate: ProductsImagesAggregate;
  name: Scalars['String'];
  /** An array relationship */
  order_items: Array<OrdersItems>;
  /** An aggregated array relationship */
  order_items_aggregate: OrdersItemsAggregate;
  price: Scalars['Int'];
  /** An array relationship */
  products_tags: Array<ProductsTags>;
  /** An aggregated array relationship */
  products_tags_aggregate: ProductsTagsAggregate;
  published_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  shop: Shops;
  shop_id: Scalars['uuid'];
  slug: Scalars['String'];
  /** An array relationship */
  themes: Array<Themes>;
  /** An aggregated array relationship */
  themes_aggregate: ThemesAggregate;
  /** An array relationship */
  themes_public: Array<ThemesPublic>;
  /** An aggregated array relationship */
  themes_public_aggregate: ThemesPublicAggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "products" */
export type ProductsCollectionsArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsCollectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsImagesArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsImagesAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsOrderItemsArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsOrderItemsAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsProductsTagsArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsProductsTagsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsThemesArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsThemesAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsThemesPublicArgs = {
  distinct_on?: Maybe<Array<ThemesPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesPublicOrderBy>>;
  where?: Maybe<ThemesPublicBoolExp>;
};

/** columns and relationships of "products" */
export type ProductsThemesPublicAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesPublicOrderBy>>;
  where?: Maybe<ThemesPublicBoolExp>;
};

/** aggregated selection of "products" */
export type ProductsAggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<ProductsAggregateFields>;
  nodes: Array<Products>;
};

/** aggregate fields of "products" */
export type ProductsAggregateFields = {
  __typename?: 'products_aggregate_fields';
  avg?: Maybe<ProductsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductsMaxFields>;
  min?: Maybe<ProductsMinFields>;
  stddev?: Maybe<ProductsStddevFields>;
  stddev_pop?: Maybe<ProductsStddevPopFields>;
  stddev_samp?: Maybe<ProductsStddevSampFields>;
  sum?: Maybe<ProductsSumFields>;
  var_pop?: Maybe<ProductsVarPopFields>;
  var_samp?: Maybe<ProductsVarSampFields>;
  variance?: Maybe<ProductsVarianceFields>;
};

/** aggregate fields of "products" */
export type ProductsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProductsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products" */
export type ProductsAggregateOrderBy = {
  avg?: Maybe<ProductsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductsMaxOrderBy>;
  min?: Maybe<ProductsMinOrderBy>;
  stddev?: Maybe<ProductsStddevOrderBy>;
  stddev_pop?: Maybe<ProductsStddevPopOrderBy>;
  stddev_samp?: Maybe<ProductsStddevSampOrderBy>;
  sum?: Maybe<ProductsSumOrderBy>;
  var_pop?: Maybe<ProductsVarPopOrderBy>;
  var_samp?: Maybe<ProductsVarSampOrderBy>;
  variance?: Maybe<ProductsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "products" */
export type ProductsArrRelInsertInput = {
  data: Array<ProductsInsertInput>;
  on_conflict?: Maybe<ProductsOnConflict>;
};

/** aggregate avg on columns */
export type ProductsAvgFields = {
  __typename?: 'products_avg_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products" */
export type ProductsAvgOrderBy = {
  price?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type ProductsBoolExp = {
  _and?: Maybe<Array<Maybe<ProductsBoolExp>>>;
  _not?: Maybe<ProductsBoolExp>;
  _or?: Maybe<Array<Maybe<ProductsBoolExp>>>;
  chart?: Maybe<ChartsBoolExp>;
  chart_id?: Maybe<UuidComparisonExp>;
  chart_public?: Maybe<ChartsPublicBoolExp>;
  collections?: Maybe<ProductsCollectionsBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  currency?: Maybe<StringComparisonExp>;
  description?: Maybe<StringComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  images?: Maybe<ProductsImagesBoolExp>;
  name?: Maybe<StringComparisonExp>;
  order_items?: Maybe<OrdersItemsBoolExp>;
  price?: Maybe<IntComparisonExp>;
  products_tags?: Maybe<ProductsTagsBoolExp>;
  published_at?: Maybe<TimestamptzComparisonExp>;
  shop?: Maybe<ShopsBoolExp>;
  shop_id?: Maybe<UuidComparisonExp>;
  slug?: Maybe<StringComparisonExp>;
  themes?: Maybe<ThemesBoolExp>;
  themes_public?: Maybe<ThemesPublicBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** columns and relationships of "products_collections" */
export type ProductsCollections = {
  __typename?: 'products_collections';
  /** An object relationship */
  collection: Collections;
  collection_id: Scalars['uuid'];
  id: Scalars['uuid'];
  position: Scalars['numeric'];
  /** An object relationship */
  product: Products;
  product_id: Scalars['uuid'];
};

/** aggregated selection of "products_collections" */
export type ProductsCollectionsAggregate = {
  __typename?: 'products_collections_aggregate';
  aggregate?: Maybe<ProductsCollectionsAggregateFields>;
  nodes: Array<ProductsCollections>;
};

/** aggregate fields of "products_collections" */
export type ProductsCollectionsAggregateFields = {
  __typename?: 'products_collections_aggregate_fields';
  avg?: Maybe<ProductsCollectionsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductsCollectionsMaxFields>;
  min?: Maybe<ProductsCollectionsMinFields>;
  stddev?: Maybe<ProductsCollectionsStddevFields>;
  stddev_pop?: Maybe<ProductsCollectionsStddevPopFields>;
  stddev_samp?: Maybe<ProductsCollectionsStddevSampFields>;
  sum?: Maybe<ProductsCollectionsSumFields>;
  var_pop?: Maybe<ProductsCollectionsVarPopFields>;
  var_samp?: Maybe<ProductsCollectionsVarSampFields>;
  variance?: Maybe<ProductsCollectionsVarianceFields>;
};

/** aggregate fields of "products_collections" */
export type ProductsCollectionsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products_collections" */
export type ProductsCollectionsAggregateOrderBy = {
  avg?: Maybe<ProductsCollectionsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductsCollectionsMaxOrderBy>;
  min?: Maybe<ProductsCollectionsMinOrderBy>;
  stddev?: Maybe<ProductsCollectionsStddevOrderBy>;
  stddev_pop?: Maybe<ProductsCollectionsStddevPopOrderBy>;
  stddev_samp?: Maybe<ProductsCollectionsStddevSampOrderBy>;
  sum?: Maybe<ProductsCollectionsSumOrderBy>;
  var_pop?: Maybe<ProductsCollectionsVarPopOrderBy>;
  var_samp?: Maybe<ProductsCollectionsVarSampOrderBy>;
  variance?: Maybe<ProductsCollectionsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "products_collections" */
export type ProductsCollectionsArrRelInsertInput = {
  data: Array<ProductsCollectionsInsertInput>;
  on_conflict?: Maybe<ProductsCollectionsOnConflict>;
};

/** aggregate avg on columns */
export type ProductsCollectionsAvgFields = {
  __typename?: 'products_collections_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products_collections" */
export type ProductsCollectionsAvgOrderBy = {
  position?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "products_collections". All fields are combined with a logical 'AND'. */
export type ProductsCollectionsBoolExp = {
  _and?: Maybe<Array<Maybe<ProductsCollectionsBoolExp>>>;
  _not?: Maybe<ProductsCollectionsBoolExp>;
  _or?: Maybe<Array<Maybe<ProductsCollectionsBoolExp>>>;
  collection?: Maybe<CollectionsBoolExp>;
  collection_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  position?: Maybe<NumericComparisonExp>;
  product?: Maybe<ProductsBoolExp>;
  product_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "products_collections" */
export enum ProductsCollectionsConstraint {
  /** unique or primary key constraint */
  ProductsCollectionsPkey = 'products_collections_pkey',
  /** unique or primary key constraint */
  ProductsCollectionsProductIdCollectionIdKey = 'products_collections_product_id_collection_id_key',
}

/** input type for incrementing integer column in table "products_collections" */
export type ProductsCollectionsIncInput = {
  position?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "products_collections" */
export type ProductsCollectionsInsertInput = {
  collection?: Maybe<CollectionsObjRelInsertInput>;
  collection_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product?: Maybe<ProductsObjRelInsertInput>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProductsCollectionsMaxFields = {
  __typename?: 'products_collections_max_fields';
  collection_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "products_collections" */
export type ProductsCollectionsMaxOrderBy = {
  collection_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductsCollectionsMinFields = {
  __typename?: 'products_collections_min_fields';
  collection_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "products_collections" */
export type ProductsCollectionsMinOrderBy = {
  collection_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "products_collections" */
export type ProductsCollectionsMutationResponse = {
  __typename?: 'products_collections_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ProductsCollections>;
};

/** input type for inserting object relation for remote table "products_collections" */
export type ProductsCollectionsObjRelInsertInput = {
  data: ProductsCollectionsInsertInput;
  on_conflict?: Maybe<ProductsCollectionsOnConflict>;
};

/** on conflict condition type for table "products_collections" */
export type ProductsCollectionsOnConflict = {
  constraint: ProductsCollectionsConstraint;
  update_columns: Array<ProductsCollectionsUpdateColumn>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** ordering options when selecting data from "products_collections" */
export type ProductsCollectionsOrderBy = {
  collection?: Maybe<CollectionsOrderBy>;
  collection_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  product?: Maybe<ProductsOrderBy>;
  product_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "products_collections" */
export type ProductsCollectionsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "products_collections" */
export enum ProductsCollectionsSelectColumn {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  ProductId = 'product_id',
}

/** input type for updating data in table "products_collections" */
export type ProductsCollectionsSetInput = {
  collection_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ProductsCollectionsStddevFields = {
  __typename?: 'products_collections_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products_collections" */
export type ProductsCollectionsStddevOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProductsCollectionsStddevPopFields = {
  __typename?: 'products_collections_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products_collections" */
export type ProductsCollectionsStddevPopOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProductsCollectionsStddevSampFields = {
  __typename?: 'products_collections_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products_collections" */
export type ProductsCollectionsStddevSampOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProductsCollectionsSumFields = {
  __typename?: 'products_collections_sum_fields';
  position?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "products_collections" */
export type ProductsCollectionsSumOrderBy = {
  position?: Maybe<OrderBy>;
};

/** update columns of table "products_collections" */
export enum ProductsCollectionsUpdateColumn {
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  ProductId = 'product_id',
}

/** aggregate var_pop on columns */
export type ProductsCollectionsVarPopFields = {
  __typename?: 'products_collections_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products_collections" */
export type ProductsCollectionsVarPopOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProductsCollectionsVarSampFields = {
  __typename?: 'products_collections_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products_collections" */
export type ProductsCollectionsVarSampOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProductsCollectionsVarianceFields = {
  __typename?: 'products_collections_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products_collections" */
export type ProductsCollectionsVarianceOrderBy = {
  position?: Maybe<OrderBy>;
};

/** unique or primary key constraints on table "products" */
export enum ProductsConstraint {
  /** unique or primary key constraint */
  ProductsChartIdKey = 'products_chart_id_key',
  /** unique or primary key constraint */
  ProductsIdKey = 'products_id_key',
  /** unique or primary key constraint */
  ProductsPkey = 'products_pkey',
  /** unique or primary key constraint */
  ProductsSlugKey = 'products_slug_key',
}

/** columns and relationships of "products_images" */
export type ProductsImages = {
  __typename?: 'products_images';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  position: Scalars['numeric'];
  /** An object relationship */
  product: Products;
  product_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "products_images" */
export type ProductsImagesAggregate = {
  __typename?: 'products_images_aggregate';
  aggregate?: Maybe<ProductsImagesAggregateFields>;
  nodes: Array<ProductsImages>;
};

/** aggregate fields of "products_images" */
export type ProductsImagesAggregateFields = {
  __typename?: 'products_images_aggregate_fields';
  avg?: Maybe<ProductsImagesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductsImagesMaxFields>;
  min?: Maybe<ProductsImagesMinFields>;
  stddev?: Maybe<ProductsImagesStddevFields>;
  stddev_pop?: Maybe<ProductsImagesStddevPopFields>;
  stddev_samp?: Maybe<ProductsImagesStddevSampFields>;
  sum?: Maybe<ProductsImagesSumFields>;
  var_pop?: Maybe<ProductsImagesVarPopFields>;
  var_samp?: Maybe<ProductsImagesVarSampFields>;
  variance?: Maybe<ProductsImagesVarianceFields>;
};

/** aggregate fields of "products_images" */
export type ProductsImagesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProductsImagesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products_images" */
export type ProductsImagesAggregateOrderBy = {
  avg?: Maybe<ProductsImagesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductsImagesMaxOrderBy>;
  min?: Maybe<ProductsImagesMinOrderBy>;
  stddev?: Maybe<ProductsImagesStddevOrderBy>;
  stddev_pop?: Maybe<ProductsImagesStddevPopOrderBy>;
  stddev_samp?: Maybe<ProductsImagesStddevSampOrderBy>;
  sum?: Maybe<ProductsImagesSumOrderBy>;
  var_pop?: Maybe<ProductsImagesVarPopOrderBy>;
  var_samp?: Maybe<ProductsImagesVarSampOrderBy>;
  variance?: Maybe<ProductsImagesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "products_images" */
export type ProductsImagesArrRelInsertInput = {
  data: Array<ProductsImagesInsertInput>;
  on_conflict?: Maybe<ProductsImagesOnConflict>;
};

/** aggregate avg on columns */
export type ProductsImagesAvgFields = {
  __typename?: 'products_images_avg_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "products_images" */
export type ProductsImagesAvgOrderBy = {
  position?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "products_images". All fields are combined with a logical 'AND'. */
export type ProductsImagesBoolExp = {
  _and?: Maybe<Array<Maybe<ProductsImagesBoolExp>>>;
  _not?: Maybe<ProductsImagesBoolExp>;
  _or?: Maybe<Array<Maybe<ProductsImagesBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  file?: Maybe<FilesBoolExp>;
  file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  position?: Maybe<NumericComparisonExp>;
  product?: Maybe<ProductsBoolExp>;
  product_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "products_images" */
export enum ProductsImagesConstraint {
  /** unique or primary key constraint */
  ProductsImagesPkey = 'products_images_pkey',
  /** unique or primary key constraint */
  ProductsImagesProductIdFileIdKey = 'products_images_product_id_file_id_key',
}

/** input type for incrementing integer column in table "products_images" */
export type ProductsImagesIncInput = {
  position?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "products_images" */
export type ProductsImagesInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file?: Maybe<FilesObjRelInsertInput>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product?: Maybe<ProductsObjRelInsertInput>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductsImagesMaxFields = {
  __typename?: 'products_images_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "products_images" */
export type ProductsImagesMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductsImagesMinFields = {
  __typename?: 'products_images_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "products_images" */
export type ProductsImagesMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "products_images" */
export type ProductsImagesMutationResponse = {
  __typename?: 'products_images_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ProductsImages>;
};

/** input type for inserting object relation for remote table "products_images" */
export type ProductsImagesObjRelInsertInput = {
  data: ProductsImagesInsertInput;
  on_conflict?: Maybe<ProductsImagesOnConflict>;
};

/** on conflict condition type for table "products_images" */
export type ProductsImagesOnConflict = {
  constraint: ProductsImagesConstraint;
  update_columns: Array<ProductsImagesUpdateColumn>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** ordering options when selecting data from "products_images" */
export type ProductsImagesOrderBy = {
  created_at?: Maybe<OrderBy>;
  file?: Maybe<FilesOrderBy>;
  file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  position?: Maybe<OrderBy>;
  product?: Maybe<ProductsOrderBy>;
  product_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "products_images" */
export type ProductsImagesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "products_images" */
export enum ProductsImagesSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "products_images" */
export type ProductsImagesSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  position?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProductsImagesStddevFields = {
  __typename?: 'products_images_stddev_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products_images" */
export type ProductsImagesStddevOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProductsImagesStddevPopFields = {
  __typename?: 'products_images_stddev_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products_images" */
export type ProductsImagesStddevPopOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProductsImagesStddevSampFields = {
  __typename?: 'products_images_stddev_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products_images" */
export type ProductsImagesStddevSampOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProductsImagesSumFields = {
  __typename?: 'products_images_sum_fields';
  position?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "products_images" */
export type ProductsImagesSumOrderBy = {
  position?: Maybe<OrderBy>;
};

/** update columns of table "products_images" */
export enum ProductsImagesUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type ProductsImagesVarPopFields = {
  __typename?: 'products_images_var_pop_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products_images" */
export type ProductsImagesVarPopOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProductsImagesVarSampFields = {
  __typename?: 'products_images_var_samp_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products_images" */
export type ProductsImagesVarSampOrderBy = {
  position?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProductsImagesVarianceFields = {
  __typename?: 'products_images_variance_fields';
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products_images" */
export type ProductsImagesVarianceOrderBy = {
  position?: Maybe<OrderBy>;
};

/** input type for incrementing integer column in table "products" */
export type ProductsIncInput = {
  price?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "products" */
export type ProductsInsertInput = {
  chart?: Maybe<ChartsObjRelInsertInput>;
  chart_id?: Maybe<Scalars['uuid']>;
  chart_public?: Maybe<ChartsPublicObjRelInsertInput>;
  collections?: Maybe<ProductsCollectionsArrRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  images?: Maybe<ProductsImagesArrRelInsertInput>;
  name?: Maybe<Scalars['String']>;
  order_items?: Maybe<OrdersItemsArrRelInsertInput>;
  price?: Maybe<Scalars['Int']>;
  products_tags?: Maybe<ProductsTagsArrRelInsertInput>;
  published_at?: Maybe<Scalars['timestamptz']>;
  shop?: Maybe<ShopsObjRelInsertInput>;
  shop_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  themes?: Maybe<ThemesArrRelInsertInput>;
  themes_public?: Maybe<ThemesPublicArrRelInsertInput>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ProductsMaxFields = {
  __typename?: 'products_max_fields';
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  shop_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "products" */
export type ProductsMaxOrderBy = {
  chart_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  currency?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price?: Maybe<OrderBy>;
  published_at?: Maybe<OrderBy>;
  shop_id?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductsMinFields = {
  __typename?: 'products_min_fields';
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  shop_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "products" */
export type ProductsMinOrderBy = {
  chart_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  currency?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  price?: Maybe<OrderBy>;
  published_at?: Maybe<OrderBy>;
  shop_id?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "products" */
export type ProductsMutationResponse = {
  __typename?: 'products_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type ProductsObjRelInsertInput = {
  data: ProductsInsertInput;
  on_conflict?: Maybe<ProductsOnConflict>;
};

/** on conflict condition type for table "products" */
export type ProductsOnConflict = {
  constraint: ProductsConstraint;
  update_columns: Array<ProductsUpdateColumn>;
  where?: Maybe<ProductsBoolExp>;
};

/** ordering options when selecting data from "products" */
export type ProductsOrderBy = {
  chart?: Maybe<ChartsOrderBy>;
  chart_id?: Maybe<OrderBy>;
  chart_public?: Maybe<ChartsPublicOrderBy>;
  collections_aggregate?: Maybe<ProductsCollectionsAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  currency?: Maybe<OrderBy>;
  description?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  images_aggregate?: Maybe<ProductsImagesAggregateOrderBy>;
  name?: Maybe<OrderBy>;
  order_items_aggregate?: Maybe<OrdersItemsAggregateOrderBy>;
  price?: Maybe<OrderBy>;
  products_tags_aggregate?: Maybe<ProductsTagsAggregateOrderBy>;
  published_at?: Maybe<OrderBy>;
  shop?: Maybe<ShopsOrderBy>;
  shop_id?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  themes_aggregate?: Maybe<ThemesAggregateOrderBy>;
  themes_public_aggregate?: Maybe<ThemesPublicAggregateOrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "products" */
export type ProductsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "products" */
export enum ProductsSelectColumn {
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "products" */
export type ProductsSetInput = {
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  currency?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  published_at?: Maybe<Scalars['timestamptz']>;
  shop_id?: Maybe<Scalars['uuid']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type ProductsStddevFields = {
  __typename?: 'products_stddev_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "products" */
export type ProductsStddevOrderBy = {
  price?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ProductsStddevPopFields = {
  __typename?: 'products_stddev_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "products" */
export type ProductsStddevPopOrderBy = {
  price?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ProductsStddevSampFields = {
  __typename?: 'products_stddev_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "products" */
export type ProductsStddevSampOrderBy = {
  price?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ProductsSumFields = {
  __typename?: 'products_sum_fields';
  price?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "products" */
export type ProductsSumOrderBy = {
  price?: Maybe<OrderBy>;
};

/** columns and relationships of "products_tags" */
export type ProductsTags = {
  __typename?: 'products_tags';
  id: Scalars['uuid'];
  /** An object relationship */
  product: Products;
  product_id: Scalars['uuid'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid'];
};

/** aggregated selection of "products_tags" */
export type ProductsTagsAggregate = {
  __typename?: 'products_tags_aggregate';
  aggregate?: Maybe<ProductsTagsAggregateFields>;
  nodes: Array<ProductsTags>;
};

/** aggregate fields of "products_tags" */
export type ProductsTagsAggregateFields = {
  __typename?: 'products_tags_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProductsTagsMaxFields>;
  min?: Maybe<ProductsTagsMinFields>;
};

/** aggregate fields of "products_tags" */
export type ProductsTagsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProductsTagsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products_tags" */
export type ProductsTagsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProductsTagsMaxOrderBy>;
  min?: Maybe<ProductsTagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "products_tags" */
export type ProductsTagsArrRelInsertInput = {
  data: Array<ProductsTagsInsertInput>;
  on_conflict?: Maybe<ProductsTagsOnConflict>;
};

/** Boolean expression to filter rows from the table "products_tags". All fields are combined with a logical 'AND'. */
export type ProductsTagsBoolExp = {
  _and?: Maybe<Array<Maybe<ProductsTagsBoolExp>>>;
  _not?: Maybe<ProductsTagsBoolExp>;
  _or?: Maybe<Array<Maybe<ProductsTagsBoolExp>>>;
  id?: Maybe<UuidComparisonExp>;
  product?: Maybe<ProductsBoolExp>;
  product_id?: Maybe<UuidComparisonExp>;
  tag?: Maybe<TagsBoolExp>;
  tag_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "products_tags" */
export enum ProductsTagsConstraint {
  /** unique or primary key constraint */
  ProductsTagsPkey = 'products_tags_pkey',
  /** unique or primary key constraint */
  ProductsTagsProductIdTagIdKey = 'products_tags_product_id_tag_id_key',
}

/** input type for inserting data into table "products_tags" */
export type ProductsTagsInsertInput = {
  id?: Maybe<Scalars['uuid']>;
  product?: Maybe<ProductsObjRelInsertInput>;
  product_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<TagsObjRelInsertInput>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProductsTagsMaxFields = {
  __typename?: 'products_tags_max_fields';
  id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "products_tags" */
export type ProductsTagsMaxOrderBy = {
  id?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  tag_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProductsTagsMinFields = {
  __typename?: 'products_tags_min_fields';
  id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "products_tags" */
export type ProductsTagsMinOrderBy = {
  id?: Maybe<OrderBy>;
  product_id?: Maybe<OrderBy>;
  tag_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "products_tags" */
export type ProductsTagsMutationResponse = {
  __typename?: 'products_tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ProductsTags>;
};

/** input type for inserting object relation for remote table "products_tags" */
export type ProductsTagsObjRelInsertInput = {
  data: ProductsTagsInsertInput;
  on_conflict?: Maybe<ProductsTagsOnConflict>;
};

/** on conflict condition type for table "products_tags" */
export type ProductsTagsOnConflict = {
  constraint: ProductsTagsConstraint;
  update_columns: Array<ProductsTagsUpdateColumn>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** ordering options when selecting data from "products_tags" */
export type ProductsTagsOrderBy = {
  id?: Maybe<OrderBy>;
  product?: Maybe<ProductsOrderBy>;
  product_id?: Maybe<OrderBy>;
  tag?: Maybe<TagsOrderBy>;
  tag_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "products_tags" */
export type ProductsTagsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "products_tags" */
export enum ProductsTagsSelectColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  TagId = 'tag_id',
}

/** input type for updating data in table "products_tags" */
export type ProductsTagsSetInput = {
  id?: Maybe<Scalars['uuid']>;
  product_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "products_tags" */
export enum ProductsTagsUpdateColumn {
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  TagId = 'tag_id',
}

/** update columns of table "products" */
export enum ProductsUpdateColumn {
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  PublishedAt = 'published_at',
  /** column name */
  ShopId = 'shop_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type ProductsVarPopFields = {
  __typename?: 'products_var_pop_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "products" */
export type ProductsVarPopOrderBy = {
  price?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ProductsVarSampFields = {
  __typename?: 'products_var_samp_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "products" */
export type ProductsVarSampOrderBy = {
  price?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ProductsVarianceFields = {
  __typename?: 'products_variance_fields';
  price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "products" */
export type ProductsVarianceOrderBy = {
  price?: Maybe<OrderBy>;
};

/** columns and relationships of "projects" */
export type Projects = {
  __typename?: 'projects';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  theme: Themes;
  theme_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "projects" */
export type ProjectsAggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<ProjectsAggregateFields>;
  nodes: Array<Projects>;
};

/** aggregate fields of "projects" */
export type ProjectsAggregateFields = {
  __typename?: 'projects_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ProjectsMaxFields>;
  min?: Maybe<ProjectsMinFields>;
};

/** aggregate fields of "projects" */
export type ProjectsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ProjectsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects" */
export type ProjectsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ProjectsMaxOrderBy>;
  min?: Maybe<ProjectsMinOrderBy>;
};

/** input type for inserting array relation for remote table "projects" */
export type ProjectsArrRelInsertInput = {
  data: Array<ProjectsInsertInput>;
  on_conflict?: Maybe<ProjectsOnConflict>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type ProjectsBoolExp = {
  _and?: Maybe<Array<Maybe<ProjectsBoolExp>>>;
  _not?: Maybe<ProjectsBoolExp>;
  _or?: Maybe<Array<Maybe<ProjectsBoolExp>>>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  theme?: Maybe<ThemesBoolExp>;
  theme_id?: Maybe<UuidComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "projects" */
export enum ProjectsConstraint {
  /** unique or primary key constraint */
  ProjectsPkey = 'projects_pkey',
}

/** input type for inserting data into table "projects" */
export type ProjectsInsertInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  theme?: Maybe<ThemesObjRelInsertInput>;
  theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ProjectsMaxFields = {
  __typename?: 'projects_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "projects" */
export type ProjectsMaxOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ProjectsMinFields = {
  __typename?: 'projects_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "projects" */
export type ProjectsMinOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "projects" */
export type ProjectsMutationResponse = {
  __typename?: 'projects_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type ProjectsObjRelInsertInput = {
  data: ProjectsInsertInput;
  on_conflict?: Maybe<ProjectsOnConflict>;
};

/** on conflict condition type for table "projects" */
export type ProjectsOnConflict = {
  constraint: ProjectsConstraint;
  update_columns: Array<ProjectsUpdateColumn>;
  where?: Maybe<ProjectsBoolExp>;
};

/** ordering options when selecting data from "projects" */
export type ProjectsOrderBy = {
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  theme?: Maybe<ThemesOrderBy>;
  theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "projects" */
export type ProjectsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "projects" */
export enum ProjectsSelectColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "projects" */
export type ProjectsSetInput = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "projects" */
export enum ProjectsUpdateColumn {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** query root */
export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "auth_codes" */
  auth_codes: Array<AuthCodes>;
  /** fetch aggregated fields from the table: "auth_codes" */
  auth_codes_aggregate: AuthCodesAggregate;
  /** fetch data from the table: "auth_codes" using primary key columns */
  auth_codes_by_pk?: Maybe<AuthCodes>;
  /** fetch data from the table: "brand_colors" */
  brand_colors: Array<BrandColors>;
  /** fetch aggregated fields from the table: "brand_colors" */
  brand_colors_aggregate: BrandColorsAggregate;
  /** fetch data from the table: "brand_colors" using primary key columns */
  brand_colors_by_pk?: Maybe<BrandColors>;
  /** fetch data from the table: "brand_lines" */
  brand_lines: Array<BrandLines>;
  /** fetch aggregated fields from the table: "brand_lines" */
  brand_lines_aggregate: BrandLinesAggregate;
  /** fetch data from the table: "brand_lines" using primary key columns */
  brand_lines_by_pk?: Maybe<BrandLines>;
  /** fetch data from the table: "brands" */
  brands: Array<Brands>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: BrandsAggregate;
  /** fetch data from the table: "brands" using primary key columns */
  brands_by_pk?: Maybe<Brands>;
  /** fetch data from the table: "charts" */
  charts: Array<Charts>;
  /** fetch aggregated fields from the table: "charts" */
  charts_aggregate: ChartsAggregate;
  /** fetch data from the table: "charts" using primary key columns */
  charts_by_pk?: Maybe<Charts>;
  /** fetch data from the table: "charts_public" */
  charts_public: Array<ChartsPublic>;
  /** fetch aggregated fields from the table: "charts_public" */
  charts_public_aggregate: ChartsPublicAggregate;
  checkEmail: CheckEmailResponse;
  /** fetch data from the table: "collections" */
  collections: Array<Collections>;
  /** fetch aggregated fields from the table: "collections" */
  collections_aggregate: CollectionsAggregate;
  /** fetch data from the table: "collections" using primary key columns */
  collections_by_pk?: Maybe<Collections>;
  currentUserRole: CurrentUserRoleResponse;
  /** fetch data from the table: "fabric_types" */
  fabric_types: Array<FabricTypes>;
  /** fetch aggregated fields from the table: "fabric_types" */
  fabric_types_aggregate: FabricTypesAggregate;
  /** fetch data from the table: "fabric_types" using primary key columns */
  fabric_types_by_pk?: Maybe<FabricTypes>;
  fileUploadPresignedPost?: Maybe<FileUploadPresignedPostResponse>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "finish_types" */
  finish_types: Array<FinishTypes>;
  /** fetch aggregated fields from the table: "finish_types" */
  finish_types_aggregate: FinishTypesAggregate;
  /** fetch data from the table: "finish_types" using primary key columns */
  finish_types_by_pk?: Maybe<FinishTypes>;
  /** fetch data from the table: "mottling_types" */
  mottling_types: Array<MottlingTypes>;
  /** fetch aggregated fields from the table: "mottling_types" */
  mottling_types_aggregate: MottlingTypesAggregate;
  /** fetch data from the table: "mottling_types" using primary key columns */
  mottling_types_by_pk?: Maybe<MottlingTypes>;
  /** fetch data from the table: "order_statuses" */
  order_statuses: Array<OrderStatuses>;
  /** fetch aggregated fields from the table: "order_statuses" */
  order_statuses_aggregate: OrderStatusesAggregate;
  /** fetch data from the table: "order_statuses" using primary key columns */
  order_statuses_by_pk?: Maybe<OrderStatuses>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: OrdersAggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "orders_items" */
  orders_items: Array<OrdersItems>;
  /** fetch aggregated fields from the table: "orders_items" */
  orders_items_aggregate: OrdersItemsAggregate;
  /** fetch data from the table: "orders_items" using primary key columns */
  orders_items_by_pk?: Maybe<OrdersItems>;
  paymentMethods: Array<PaymentMethod>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: ProductsAggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "products_collections" */
  products_collections: Array<ProductsCollections>;
  /** fetch aggregated fields from the table: "products_collections" */
  products_collections_aggregate: ProductsCollectionsAggregate;
  /** fetch data from the table: "products_collections" using primary key columns */
  products_collections_by_pk?: Maybe<ProductsCollections>;
  /** fetch data from the table: "products_images" */
  products_images: Array<ProductsImages>;
  /** fetch aggregated fields from the table: "products_images" */
  products_images_aggregate: ProductsImagesAggregate;
  /** fetch data from the table: "products_images" using primary key columns */
  products_images_by_pk?: Maybe<ProductsImages>;
  /** fetch data from the table: "products_tags" */
  products_tags: Array<ProductsTags>;
  /** fetch aggregated fields from the table: "products_tags" */
  products_tags_aggregate: ProductsTagsAggregate;
  /** fetch data from the table: "products_tags" using primary key columns */
  products_tags_by_pk?: Maybe<ProductsTags>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: ProjectsAggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "shops" */
  shops: Array<Shops>;
  /** fetch aggregated fields from the table: "shops" */
  shops_aggregate: ShopsAggregate;
  /** fetch data from the table: "shops" using primary key columns */
  shops_by_pk?: Maybe<Shops>;
  /** fetch data from the table: "stitch_groups" */
  stitch_groups: Array<StitchGroups>;
  /** fetch aggregated fields from the table: "stitch_groups" */
  stitch_groups_aggregate: StitchGroupsAggregate;
  /** fetch data from the table: "stitch_groups" using primary key columns */
  stitch_groups_by_pk?: Maybe<StitchGroups>;
  /** fetch data from the table: "stitch_types" */
  stitch_types: Array<StitchTypes>;
  /** fetch aggregated fields from the table: "stitch_types" */
  stitch_types_aggregate: StitchTypesAggregate;
  /** fetch data from the table: "stitch_types" using primary key columns */
  stitch_types_by_pk?: Maybe<StitchTypes>;
  /** fetch data from the table: "stripe_payment_intent_statuses" */
  stripe_payment_intent_statuses: Array<StripePaymentIntentStatuses>;
  /** fetch aggregated fields from the table: "stripe_payment_intent_statuses" */
  stripe_payment_intent_statuses_aggregate: StripePaymentIntentStatusesAggregate;
  /** fetch data from the table: "stripe_payment_intent_statuses" using primary key columns */
  stripe_payment_intent_statuses_by_pk?: Maybe<StripePaymentIntentStatuses>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: TagsAggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "themes" */
  themes: Array<Themes>;
  /** fetch aggregated fields from the table: "themes" */
  themes_aggregate: ThemesAggregate;
  /** fetch data from the table: "themes" using primary key columns */
  themes_by_pk?: Maybe<Themes>;
  /** fetch data from the table: "themes_public" */
  themes_public: Array<ThemesPublic>;
  /** fetch aggregated fields from the table: "themes_public" */
  themes_public_aggregate: ThemesPublicAggregate;
  /** fetch data from the table: "themes_stitch_groups_threads" */
  themes_stitch_groups_threads: Array<ThemesStitchGroupsThreads>;
  /** fetch aggregated fields from the table: "themes_stitch_groups_threads" */
  themes_stitch_groups_threads_aggregate: ThemesStitchGroupsThreadsAggregate;
  /** fetch data from the table: "themes_stitch_groups_threads" using primary key columns */
  themes_stitch_groups_threads_by_pk?: Maybe<ThemesStitchGroupsThreads>;
  /** fetch data from the table: "threads" */
  threads: Array<Threads>;
  /** fetch aggregated fields from the table: "threads" */
  threads_aggregate: ThreadsAggregate;
  /** fetch data from the table: "threads" using primary key columns */
  threads_by_pk?: Maybe<Threads>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};

/** query root */
export type QueryRootAuthCodesArgs = {
  distinct_on?: Maybe<Array<AuthCodesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthCodesOrderBy>>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** query root */
export type QueryRootAuthCodesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthCodesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthCodesOrderBy>>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** query root */
export type QueryRootAuthCodesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootBrandColorsArgs = {
  distinct_on?: Maybe<Array<BrandColorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandColorsOrderBy>>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** query root */
export type QueryRootBrandColorsAggregateArgs = {
  distinct_on?: Maybe<Array<BrandColorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandColorsOrderBy>>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** query root */
export type QueryRootBrandColorsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootBrandLinesArgs = {
  distinct_on?: Maybe<Array<BrandLinesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandLinesOrderBy>>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** query root */
export type QueryRootBrandLinesAggregateArgs = {
  distinct_on?: Maybe<Array<BrandLinesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandLinesOrderBy>>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** query root */
export type QueryRootBrandLinesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootBrandsArgs = {
  distinct_on?: Maybe<Array<BrandsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandsOrderBy>>;
  where?: Maybe<BrandsBoolExp>;
};

/** query root */
export type QueryRootBrandsAggregateArgs = {
  distinct_on?: Maybe<Array<BrandsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandsOrderBy>>;
  where?: Maybe<BrandsBoolExp>;
};

/** query root */
export type QueryRootBrandsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootChartsArgs = {
  distinct_on?: Maybe<Array<ChartsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsOrderBy>>;
  where?: Maybe<ChartsBoolExp>;
};

/** query root */
export type QueryRootChartsAggregateArgs = {
  distinct_on?: Maybe<Array<ChartsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsOrderBy>>;
  where?: Maybe<ChartsBoolExp>;
};

/** query root */
export type QueryRootChartsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootChartsPublicArgs = {
  distinct_on?: Maybe<Array<ChartsPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsPublicOrderBy>>;
  where?: Maybe<ChartsPublicBoolExp>;
};

/** query root */
export type QueryRootChartsPublicAggregateArgs = {
  distinct_on?: Maybe<Array<ChartsPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsPublicOrderBy>>;
  where?: Maybe<ChartsPublicBoolExp>;
};

/** query root */
export type QueryRootCheckEmailArgs = {
  email: Scalars['String'];
};

/** query root */
export type QueryRootCollectionsArgs = {
  distinct_on?: Maybe<Array<CollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CollectionsOrderBy>>;
  where?: Maybe<CollectionsBoolExp>;
};

/** query root */
export type QueryRootCollectionsAggregateArgs = {
  distinct_on?: Maybe<Array<CollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CollectionsOrderBy>>;
  where?: Maybe<CollectionsBoolExp>;
};

/** query root */
export type QueryRootCollectionsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootFabricTypesArgs = {
  distinct_on?: Maybe<Array<FabricTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FabricTypesOrderBy>>;
  where?: Maybe<FabricTypesBoolExp>;
};

/** query root */
export type QueryRootFabricTypesAggregateArgs = {
  distinct_on?: Maybe<Array<FabricTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FabricTypesOrderBy>>;
  where?: Maybe<FabricTypesBoolExp>;
};

/** query root */
export type QueryRootFabricTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootFileUploadPresignedPostArgs = {
  fileId?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
};

/** query root */
export type QueryRootFilesArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

/** query root */
export type QueryRootFilesAggregateArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

/** query root */
export type QueryRootFilesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootFinishTypesArgs = {
  distinct_on?: Maybe<Array<FinishTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FinishTypesOrderBy>>;
  where?: Maybe<FinishTypesBoolExp>;
};

/** query root */
export type QueryRootFinishTypesAggregateArgs = {
  distinct_on?: Maybe<Array<FinishTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FinishTypesOrderBy>>;
  where?: Maybe<FinishTypesBoolExp>;
};

/** query root */
export type QueryRootFinishTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootMottlingTypesArgs = {
  distinct_on?: Maybe<Array<MottlingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MottlingTypesOrderBy>>;
  where?: Maybe<MottlingTypesBoolExp>;
};

/** query root */
export type QueryRootMottlingTypesAggregateArgs = {
  distinct_on?: Maybe<Array<MottlingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MottlingTypesOrderBy>>;
  where?: Maybe<MottlingTypesBoolExp>;
};

/** query root */
export type QueryRootMottlingTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootOrderStatusesArgs = {
  distinct_on?: Maybe<Array<OrderStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusesOrderBy>>;
  where?: Maybe<OrderStatusesBoolExp>;
};

/** query root */
export type QueryRootOrderStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<OrderStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusesOrderBy>>;
  where?: Maybe<OrderStatusesBoolExp>;
};

/** query root */
export type QueryRootOrderStatusesByPkArgs = {
  name: Scalars['String'];
};

/** query root */
export type QueryRootOrdersArgs = {
  distinct_on?: Maybe<Array<OrdersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersOrderBy>>;
  where?: Maybe<OrdersBoolExp>;
};

/** query root */
export type QueryRootOrdersAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersOrderBy>>;
  where?: Maybe<OrdersBoolExp>;
};

/** query root */
export type QueryRootOrdersByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootOrdersItemsArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** query root */
export type QueryRootOrdersItemsAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** query root */
export type QueryRootOrdersItemsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootProductsArgs = {
  distinct_on?: Maybe<Array<ProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsOrderBy>>;
  where?: Maybe<ProductsBoolExp>;
};

/** query root */
export type QueryRootProductsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsOrderBy>>;
  where?: Maybe<ProductsBoolExp>;
};

/** query root */
export type QueryRootProductsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootProductsCollectionsArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** query root */
export type QueryRootProductsCollectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** query root */
export type QueryRootProductsCollectionsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootProductsImagesArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** query root */
export type QueryRootProductsImagesAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** query root */
export type QueryRootProductsImagesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootProductsTagsArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** query root */
export type QueryRootProductsTagsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** query root */
export type QueryRootProductsTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
};

/** query root */
export type QueryRootProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
};

/** query root */
export type QueryRootProjectsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootShopsArgs = {
  distinct_on?: Maybe<Array<ShopsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ShopsOrderBy>>;
  where?: Maybe<ShopsBoolExp>;
};

/** query root */
export type QueryRootShopsAggregateArgs = {
  distinct_on?: Maybe<Array<ShopsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ShopsOrderBy>>;
  where?: Maybe<ShopsBoolExp>;
};

/** query root */
export type QueryRootShopsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootStitchGroupsArgs = {
  distinct_on?: Maybe<Array<StitchGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchGroupsOrderBy>>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** query root */
export type QueryRootStitchGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<StitchGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchGroupsOrderBy>>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** query root */
export type QueryRootStitchGroupsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootStitchTypesArgs = {
  distinct_on?: Maybe<Array<StitchTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchTypesOrderBy>>;
  where?: Maybe<StitchTypesBoolExp>;
};

/** query root */
export type QueryRootStitchTypesAggregateArgs = {
  distinct_on?: Maybe<Array<StitchTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchTypesOrderBy>>;
  where?: Maybe<StitchTypesBoolExp>;
};

/** query root */
export type QueryRootStitchTypesByPkArgs = {
  name: Scalars['String'];
};

/** query root */
export type QueryRootStripePaymentIntentStatusesArgs = {
  distinct_on?: Maybe<Array<StripePaymentIntentStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StripePaymentIntentStatusesOrderBy>>;
  where?: Maybe<StripePaymentIntentStatusesBoolExp>;
};

/** query root */
export type QueryRootStripePaymentIntentStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<StripePaymentIntentStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StripePaymentIntentStatusesOrderBy>>;
  where?: Maybe<StripePaymentIntentStatusesBoolExp>;
};

/** query root */
export type QueryRootStripePaymentIntentStatusesByPkArgs = {
  name: Scalars['String'];
};

/** query root */
export type QueryRootTagsArgs = {
  distinct_on?: Maybe<Array<TagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TagsOrderBy>>;
  where?: Maybe<TagsBoolExp>;
};

/** query root */
export type QueryRootTagsAggregateArgs = {
  distinct_on?: Maybe<Array<TagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TagsOrderBy>>;
  where?: Maybe<TagsBoolExp>;
};

/** query root */
export type QueryRootTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootThemesArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** query root */
export type QueryRootThemesAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** query root */
export type QueryRootThemesByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootThemesPublicArgs = {
  distinct_on?: Maybe<Array<ThemesPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesPublicOrderBy>>;
  where?: Maybe<ThemesPublicBoolExp>;
};

/** query root */
export type QueryRootThemesPublicAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesPublicOrderBy>>;
  where?: Maybe<ThemesPublicBoolExp>;
};

/** query root */
export type QueryRootThemesStitchGroupsThreadsArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** query root */
export type QueryRootThemesStitchGroupsThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** query root */
export type QueryRootThemesStitchGroupsThreadsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootThreadsArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** query root */
export type QueryRootThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** query root */
export type QueryRootThreadsByPkArgs = {
  id: Scalars['uuid'];
};

/** query root */
export type QueryRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** query root */
export type QueryRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** query root */
export type QueryRootUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** expression to compare columns of type rectangle_spacing. All fields are combined with logical 'AND'. */
export type RectangleSpacingComparisonExp = {
  _eq?: Maybe<Scalars['rectangle_spacing']>;
  _gt?: Maybe<Scalars['rectangle_spacing']>;
  _gte?: Maybe<Scalars['rectangle_spacing']>;
  _in?: Maybe<Array<Scalars['rectangle_spacing']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['rectangle_spacing']>;
  _lte?: Maybe<Scalars['rectangle_spacing']>;
  _neq?: Maybe<Scalars['rectangle_spacing']>;
  _nin?: Maybe<Array<Scalars['rectangle_spacing']>>;
};

/** expression to compare columns of type rgba_color. All fields are combined with logical 'AND'. */
export type RgbaColorComparisonExp = {
  _eq?: Maybe<Scalars['rgba_color']>;
  _gt?: Maybe<Scalars['rgba_color']>;
  _gte?: Maybe<Scalars['rgba_color']>;
  _in?: Maybe<Array<Scalars['rgba_color']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['rgba_color']>;
  _lte?: Maybe<Scalars['rgba_color']>;
  _neq?: Maybe<Scalars['rgba_color']>;
  _nin?: Maybe<Array<Scalars['rgba_color']>>;
};

/** columns and relationships of "shops" */
export type Shops = {
  __typename?: 'shops';
  /** An object relationship */
  background_file: Files;
  background_file_id: Scalars['uuid'];
  color: Scalars['_rgb_int'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  icon_file: Files;
  icon_file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  products: Array<Products>;
  /** An aggregated array relationship */
  products_aggregate: ProductsAggregate;
  slug: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "shops" */
export type ShopsProductsArgs = {
  distinct_on?: Maybe<Array<ProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsOrderBy>>;
  where?: Maybe<ProductsBoolExp>;
};

/** columns and relationships of "shops" */
export type ShopsProductsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsOrderBy>>;
  where?: Maybe<ProductsBoolExp>;
};

/** aggregated selection of "shops" */
export type ShopsAggregate = {
  __typename?: 'shops_aggregate';
  aggregate?: Maybe<ShopsAggregateFields>;
  nodes: Array<Shops>;
};

/** aggregate fields of "shops" */
export type ShopsAggregateFields = {
  __typename?: 'shops_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ShopsMaxFields>;
  min?: Maybe<ShopsMinFields>;
};

/** aggregate fields of "shops" */
export type ShopsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ShopsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shops" */
export type ShopsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ShopsMaxOrderBy>;
  min?: Maybe<ShopsMinOrderBy>;
};

/** input type for inserting array relation for remote table "shops" */
export type ShopsArrRelInsertInput = {
  data: Array<ShopsInsertInput>;
  on_conflict?: Maybe<ShopsOnConflict>;
};

/** Boolean expression to filter rows from the table "shops". All fields are combined with a logical 'AND'. */
export type ShopsBoolExp = {
  _and?: Maybe<Array<Maybe<ShopsBoolExp>>>;
  _not?: Maybe<ShopsBoolExp>;
  _or?: Maybe<Array<Maybe<ShopsBoolExp>>>;
  background_file?: Maybe<FilesBoolExp>;
  background_file_id?: Maybe<UuidComparisonExp>;
  color?: Maybe<RgbIntComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  icon_file?: Maybe<FilesBoolExp>;
  icon_file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  products?: Maybe<ProductsBoolExp>;
  slug?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "shops" */
export enum ShopsConstraint {
  /** unique or primary key constraint */
  ShopsNameKey = 'shops_name_key',
  /** unique or primary key constraint */
  ShopsPkey = 'shops_pkey',
  /** unique or primary key constraint */
  ShopsSlugKey = 'shops_slug_key',
}

/** input type for inserting data into table "shops" */
export type ShopsInsertInput = {
  background_file?: Maybe<FilesObjRelInsertInput>;
  background_file_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['_rgb_int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_file?: Maybe<FilesObjRelInsertInput>;
  icon_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<ProductsArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ShopsMaxFields = {
  __typename?: 'shops_max_fields';
  background_file_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "shops" */
export type ShopsMaxOrderBy = {
  background_file_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  icon_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ShopsMinFields = {
  __typename?: 'shops_min_fields';
  background_file_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "shops" */
export type ShopsMinOrderBy = {
  background_file_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  icon_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "shops" */
export type ShopsMutationResponse = {
  __typename?: 'shops_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Shops>;
};

/** input type for inserting object relation for remote table "shops" */
export type ShopsObjRelInsertInput = {
  data: ShopsInsertInput;
  on_conflict?: Maybe<ShopsOnConflict>;
};

/** on conflict condition type for table "shops" */
export type ShopsOnConflict = {
  constraint: ShopsConstraint;
  update_columns: Array<ShopsUpdateColumn>;
  where?: Maybe<ShopsBoolExp>;
};

/** ordering options when selecting data from "shops" */
export type ShopsOrderBy = {
  background_file?: Maybe<FilesOrderBy>;
  background_file_id?: Maybe<OrderBy>;
  color?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  icon_file?: Maybe<FilesOrderBy>;
  icon_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  products_aggregate?: Maybe<ProductsAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "shops" */
export type ShopsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "shops" */
export enum ShopsSelectColumn {
  /** column name */
  BackgroundFileId = 'background_file_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IconFileId = 'icon_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "shops" */
export type ShopsSetInput = {
  background_file_id?: Maybe<Scalars['uuid']>;
  color?: Maybe<Scalars['_rgb_int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  icon_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "shops" */
export enum ShopsUpdateColumn {
  /** column name */
  BackgroundFileId = 'background_file_id',
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IconFileId = 'icon_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type smallint. All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

export type StDWithinGeographyInput = {
  distance: Scalars['Float'];
  from: Scalars['geography'];
  use_spheroid?: Maybe<Scalars['Boolean']>;
};

export type StDWithinInput = {
  distance: Scalars['Float'];
  from: Scalars['geometry'];
};

/** columns and relationships of "stitch_groups" */
export type StitchGroups = {
  __typename?: 'stitch_groups';
  amount: Scalars['Int'];
  /** An object relationship */
  chart: Charts;
  chart_id: Scalars['uuid'];
  coverage: Scalars['smallint'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  import_color: Scalars['_rgb_int'];
  import_name?: Maybe<Scalars['String']>;
  import_reference_id?: Maybe<Scalars['String']>;
  lines?: Maybe<Scalars['geometry']>;
  notes?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['geometry']>;
  type: StitchTypesEnum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "stitch_groups" */
export type StitchGroupsAggregate = {
  __typename?: 'stitch_groups_aggregate';
  aggregate?: Maybe<StitchGroupsAggregateFields>;
  nodes: Array<StitchGroups>;
};

/** aggregate fields of "stitch_groups" */
export type StitchGroupsAggregateFields = {
  __typename?: 'stitch_groups_aggregate_fields';
  avg?: Maybe<StitchGroupsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<StitchGroupsMaxFields>;
  min?: Maybe<StitchGroupsMinFields>;
  stddev?: Maybe<StitchGroupsStddevFields>;
  stddev_pop?: Maybe<StitchGroupsStddevPopFields>;
  stddev_samp?: Maybe<StitchGroupsStddevSampFields>;
  sum?: Maybe<StitchGroupsSumFields>;
  var_pop?: Maybe<StitchGroupsVarPopFields>;
  var_samp?: Maybe<StitchGroupsVarSampFields>;
  variance?: Maybe<StitchGroupsVarianceFields>;
};

/** aggregate fields of "stitch_groups" */
export type StitchGroupsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<StitchGroupsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stitch_groups" */
export type StitchGroupsAggregateOrderBy = {
  avg?: Maybe<StitchGroupsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<StitchGroupsMaxOrderBy>;
  min?: Maybe<StitchGroupsMinOrderBy>;
  stddev?: Maybe<StitchGroupsStddevOrderBy>;
  stddev_pop?: Maybe<StitchGroupsStddevPopOrderBy>;
  stddev_samp?: Maybe<StitchGroupsStddevSampOrderBy>;
  sum?: Maybe<StitchGroupsSumOrderBy>;
  var_pop?: Maybe<StitchGroupsVarPopOrderBy>;
  var_samp?: Maybe<StitchGroupsVarSampOrderBy>;
  variance?: Maybe<StitchGroupsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "stitch_groups" */
export type StitchGroupsArrRelInsertInput = {
  data: Array<StitchGroupsInsertInput>;
  on_conflict?: Maybe<StitchGroupsOnConflict>;
};

/** aggregate avg on columns */
export type StitchGroupsAvgFields = {
  __typename?: 'stitch_groups_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stitch_groups" */
export type StitchGroupsAvgOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "stitch_groups". All fields are combined with a logical 'AND'. */
export type StitchGroupsBoolExp = {
  _and?: Maybe<Array<Maybe<StitchGroupsBoolExp>>>;
  _not?: Maybe<StitchGroupsBoolExp>;
  _or?: Maybe<Array<Maybe<StitchGroupsBoolExp>>>;
  amount?: Maybe<IntComparisonExp>;
  chart?: Maybe<ChartsBoolExp>;
  chart_id?: Maybe<UuidComparisonExp>;
  coverage?: Maybe<SmallintComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  import_color?: Maybe<RgbIntComparisonExp>;
  import_name?: Maybe<StringComparisonExp>;
  import_reference_id?: Maybe<StringComparisonExp>;
  lines?: Maybe<GeometryComparisonExp>;
  notes?: Maybe<StringComparisonExp>;
  points?: Maybe<GeometryComparisonExp>;
  type?: Maybe<StitchTypesEnumComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "stitch_groups" */
export enum StitchGroupsConstraint {
  /** unique or primary key constraint */
  StitchGroupsPkey = 'stitch_groups_pkey',
}

/** input type for incrementing integer column in table "stitch_groups" */
export type StitchGroupsIncInput = {
  amount?: Maybe<Scalars['Int']>;
  coverage?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "stitch_groups" */
export type StitchGroupsInsertInput = {
  amount?: Maybe<Scalars['Int']>;
  chart?: Maybe<ChartsObjRelInsertInput>;
  chart_id?: Maybe<Scalars['uuid']>;
  coverage?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  import_color?: Maybe<Scalars['_rgb_int']>;
  import_name?: Maybe<Scalars['String']>;
  import_reference_id?: Maybe<Scalars['String']>;
  lines?: Maybe<Scalars['geometry']>;
  notes?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['geometry']>;
  type?: Maybe<StitchTypesEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type StitchGroupsMaxFields = {
  __typename?: 'stitch_groups_max_fields';
  amount?: Maybe<Scalars['Int']>;
  chart_id?: Maybe<Scalars['uuid']>;
  coverage?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  import_name?: Maybe<Scalars['String']>;
  import_reference_id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stitch_groups" */
export type StitchGroupsMaxOrderBy = {
  amount?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  import_name?: Maybe<OrderBy>;
  import_reference_id?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type StitchGroupsMinFields = {
  __typename?: 'stitch_groups_min_fields';
  amount?: Maybe<Scalars['Int']>;
  chart_id?: Maybe<Scalars['uuid']>;
  coverage?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  import_name?: Maybe<Scalars['String']>;
  import_reference_id?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stitch_groups" */
export type StitchGroupsMinOrderBy = {
  amount?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  import_name?: Maybe<OrderBy>;
  import_reference_id?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "stitch_groups" */
export type StitchGroupsMutationResponse = {
  __typename?: 'stitch_groups_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<StitchGroups>;
};

/** input type for inserting object relation for remote table "stitch_groups" */
export type StitchGroupsObjRelInsertInput = {
  data: StitchGroupsInsertInput;
  on_conflict?: Maybe<StitchGroupsOnConflict>;
};

/** on conflict condition type for table "stitch_groups" */
export type StitchGroupsOnConflict = {
  constraint: StitchGroupsConstraint;
  update_columns: Array<StitchGroupsUpdateColumn>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** ordering options when selecting data from "stitch_groups" */
export type StitchGroupsOrderBy = {
  amount?: Maybe<OrderBy>;
  chart?: Maybe<ChartsOrderBy>;
  chart_id?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  import_color?: Maybe<OrderBy>;
  import_name?: Maybe<OrderBy>;
  import_reference_id?: Maybe<OrderBy>;
  lines?: Maybe<OrderBy>;
  notes?: Maybe<OrderBy>;
  points?: Maybe<OrderBy>;
  type?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "stitch_groups" */
export type StitchGroupsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "stitch_groups" */
export enum StitchGroupsSelectColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  Coverage = 'coverage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImportColor = 'import_color',
  /** column name */
  ImportName = 'import_name',
  /** column name */
  ImportReferenceId = 'import_reference_id',
  /** column name */
  Lines = 'lines',
  /** column name */
  Notes = 'notes',
  /** column name */
  Points = 'points',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "stitch_groups" */
export type StitchGroupsSetInput = {
  amount?: Maybe<Scalars['Int']>;
  chart_id?: Maybe<Scalars['uuid']>;
  coverage?: Maybe<Scalars['smallint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  import_color?: Maybe<Scalars['_rgb_int']>;
  import_name?: Maybe<Scalars['String']>;
  import_reference_id?: Maybe<Scalars['String']>;
  lines?: Maybe<Scalars['geometry']>;
  notes?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['geometry']>;
  type?: Maybe<StitchTypesEnum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type StitchGroupsStddevFields = {
  __typename?: 'stitch_groups_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stitch_groups" */
export type StitchGroupsStddevOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type StitchGroupsStddevPopFields = {
  __typename?: 'stitch_groups_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stitch_groups" */
export type StitchGroupsStddevPopOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type StitchGroupsStddevSampFields = {
  __typename?: 'stitch_groups_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stitch_groups" */
export type StitchGroupsStddevSampOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type StitchGroupsSumFields = {
  __typename?: 'stitch_groups_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  coverage?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "stitch_groups" */
export type StitchGroupsSumOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** update columns of table "stitch_groups" */
export enum StitchGroupsUpdateColumn {
  /** column name */
  Amount = 'amount',
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  Coverage = 'coverage',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImportColor = 'import_color',
  /** column name */
  ImportName = 'import_name',
  /** column name */
  ImportReferenceId = 'import_reference_id',
  /** column name */
  Lines = 'lines',
  /** column name */
  Notes = 'notes',
  /** column name */
  Points = 'points',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type StitchGroupsVarPopFields = {
  __typename?: 'stitch_groups_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stitch_groups" */
export type StitchGroupsVarPopOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type StitchGroupsVarSampFields = {
  __typename?: 'stitch_groups_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stitch_groups" */
export type StitchGroupsVarSampOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type StitchGroupsVarianceFields = {
  __typename?: 'stitch_groups_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  coverage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stitch_groups" */
export type StitchGroupsVarianceOrderBy = {
  amount?: Maybe<OrderBy>;
  coverage?: Maybe<OrderBy>;
};

/** columns and relationships of "stitch_types" */
export type StitchTypes = {
  __typename?: 'stitch_types';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "stitch_types" */
export type StitchTypesAggregate = {
  __typename?: 'stitch_types_aggregate';
  aggregate?: Maybe<StitchTypesAggregateFields>;
  nodes: Array<StitchTypes>;
};

/** aggregate fields of "stitch_types" */
export type StitchTypesAggregateFields = {
  __typename?: 'stitch_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<StitchTypesMaxFields>;
  min?: Maybe<StitchTypesMinFields>;
};

/** aggregate fields of "stitch_types" */
export type StitchTypesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<StitchTypesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stitch_types" */
export type StitchTypesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<StitchTypesMaxOrderBy>;
  min?: Maybe<StitchTypesMinOrderBy>;
};

/** input type for inserting array relation for remote table "stitch_types" */
export type StitchTypesArrRelInsertInput = {
  data: Array<StitchTypesInsertInput>;
  on_conflict?: Maybe<StitchTypesOnConflict>;
};

/** Boolean expression to filter rows from the table "stitch_types". All fields are combined with a logical 'AND'. */
export type StitchTypesBoolExp = {
  _and?: Maybe<Array<Maybe<StitchTypesBoolExp>>>;
  _not?: Maybe<StitchTypesBoolExp>;
  _or?: Maybe<Array<Maybe<StitchTypesBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "stitch_types" */
export enum StitchTypesConstraint {
  /** unique or primary key constraint */
  StitchTypesPkey = 'stitch_types_pkey',
}

export enum StitchTypesEnum {
  /** Back Stitch */
  BackStitch = 'back_stitch',
  /** Cross Stitch */
  CrossStitch = 'cross_stitch',
  /** French Knot */
  FrenchKnot = 'french_knot',
  /** Quarter Stitch */
  QuarterStitch = 'quarter_stitch',
  /** Three Quarter Stitch */
  ThreeQuarterStitch = 'three_quarter_stitch',
}

/** expression to compare columns of type stitch_types_enum. All fields are combined with logical 'AND'. */
export type StitchTypesEnumComparisonExp = {
  _eq?: Maybe<StitchTypesEnum>;
  _in?: Maybe<Array<StitchTypesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<StitchTypesEnum>;
  _nin?: Maybe<Array<StitchTypesEnum>>;
};

/** input type for inserting data into table "stitch_types" */
export type StitchTypesInsertInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type StitchTypesMaxFields = {
  __typename?: 'stitch_types_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stitch_types" */
export type StitchTypesMaxOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type StitchTypesMinFields = {
  __typename?: 'stitch_types_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stitch_types" */
export type StitchTypesMinOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "stitch_types" */
export type StitchTypesMutationResponse = {
  __typename?: 'stitch_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<StitchTypes>;
};

/** input type for inserting object relation for remote table "stitch_types" */
export type StitchTypesObjRelInsertInput = {
  data: StitchTypesInsertInput;
  on_conflict?: Maybe<StitchTypesOnConflict>;
};

/** on conflict condition type for table "stitch_types" */
export type StitchTypesOnConflict = {
  constraint: StitchTypesConstraint;
  update_columns: Array<StitchTypesUpdateColumn>;
  where?: Maybe<StitchTypesBoolExp>;
};

/** ordering options when selecting data from "stitch_types" */
export type StitchTypesOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** primary key columns input for table: "stitch_types" */
export type StitchTypesPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "stitch_types" */
export enum StitchTypesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "stitch_types" */
export type StitchTypesSetInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "stitch_types" */
export enum StitchTypesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** columns and relationships of "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatuses = {
  __typename?: 'stripe_payment_intent_statuses';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesAggregate = {
  __typename?: 'stripe_payment_intent_statuses_aggregate';
  aggregate?: Maybe<StripePaymentIntentStatusesAggregateFields>;
  nodes: Array<StripePaymentIntentStatuses>;
};

/** aggregate fields of "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesAggregateFields = {
  __typename?: 'stripe_payment_intent_statuses_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<StripePaymentIntentStatusesMaxFields>;
  min?: Maybe<StripePaymentIntentStatusesMinFields>;
};

/** aggregate fields of "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<StripePaymentIntentStatusesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<StripePaymentIntentStatusesMaxOrderBy>;
  min?: Maybe<StripePaymentIntentStatusesMinOrderBy>;
};

/** input type for inserting array relation for remote table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesArrRelInsertInput = {
  data: Array<StripePaymentIntentStatusesInsertInput>;
  on_conflict?: Maybe<StripePaymentIntentStatusesOnConflict>;
};

/** Boolean expression to filter rows from the table "stripe_payment_intent_statuses". All fields are combined with a logical 'AND'. */
export type StripePaymentIntentStatusesBoolExp = {
  _and?: Maybe<Array<Maybe<StripePaymentIntentStatusesBoolExp>>>;
  _not?: Maybe<StripePaymentIntentStatusesBoolExp>;
  _or?: Maybe<Array<Maybe<StripePaymentIntentStatusesBoolExp>>>;
  description?: Maybe<StringComparisonExp>;
  name?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "stripe_payment_intent_statuses" */
export enum StripePaymentIntentStatusesConstraint {
  /** unique or primary key constraint */
  StripePaymentIntentStatusesPkey = 'stripe_payment_intent_statuses_pkey',
}

export enum StripePaymentIntentStatusesEnum {
  /** Canceled */
  Canceled = 'canceled',
  /** Processing */
  Processing = 'processing',
  /** Requires Action */
  RequiresAction = 'requires_action',
  /** Requires Capture */
  RequiresCapture = 'requires_capture',
  /** Requires Confirmation */
  RequiresConfirmation = 'requires_confirmation',
  /** Requires Payment Method */
  RequiresPaymentMethod = 'requires_payment_method',
  /** Succeeded */
  Succeeded = 'succeeded',
}

/** expression to compare columns of type stripe_payment_intent_statuses_enum. All fields are combined with logical 'AND'. */
export type StripePaymentIntentStatusesEnumComparisonExp = {
  _eq?: Maybe<StripePaymentIntentStatusesEnum>;
  _in?: Maybe<Array<StripePaymentIntentStatusesEnum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<StripePaymentIntentStatusesEnum>;
  _nin?: Maybe<Array<StripePaymentIntentStatusesEnum>>;
};

/** input type for inserting data into table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesInsertInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type StripePaymentIntentStatusesMaxFields = {
  __typename?: 'stripe_payment_intent_statuses_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesMaxOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type StripePaymentIntentStatusesMinFields = {
  __typename?: 'stripe_payment_intent_statuses_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesMinOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** response of any mutation on the table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesMutationResponse = {
  __typename?: 'stripe_payment_intent_statuses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<StripePaymentIntentStatuses>;
};

/** input type for inserting object relation for remote table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesObjRelInsertInput = {
  data: StripePaymentIntentStatusesInsertInput;
  on_conflict?: Maybe<StripePaymentIntentStatusesOnConflict>;
};

/** on conflict condition type for table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesOnConflict = {
  constraint: StripePaymentIntentStatusesConstraint;
  update_columns: Array<StripePaymentIntentStatusesUpdateColumn>;
  where?: Maybe<StripePaymentIntentStatusesBoolExp>;
};

/** ordering options when selecting data from "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesOrderBy = {
  description?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
};

/** primary key columns input for table: "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesPkColumnsInput = {
  name: Scalars['String'];
};

/** select columns of table "stripe_payment_intent_statuses" */
export enum StripePaymentIntentStatusesSelectColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "stripe_payment_intent_statuses" */
export type StripePaymentIntentStatusesSetInput = {
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** update columns of table "stripe_payment_intent_statuses" */
export enum StripePaymentIntentStatusesUpdateColumn {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** subscription root */
export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "auth_codes" */
  auth_codes: Array<AuthCodes>;
  /** fetch aggregated fields from the table: "auth_codes" */
  auth_codes_aggregate: AuthCodesAggregate;
  /** fetch data from the table: "auth_codes" using primary key columns */
  auth_codes_by_pk?: Maybe<AuthCodes>;
  /** fetch data from the table: "brand_colors" */
  brand_colors: Array<BrandColors>;
  /** fetch aggregated fields from the table: "brand_colors" */
  brand_colors_aggregate: BrandColorsAggregate;
  /** fetch data from the table: "brand_colors" using primary key columns */
  brand_colors_by_pk?: Maybe<BrandColors>;
  /** fetch data from the table: "brand_lines" */
  brand_lines: Array<BrandLines>;
  /** fetch aggregated fields from the table: "brand_lines" */
  brand_lines_aggregate: BrandLinesAggregate;
  /** fetch data from the table: "brand_lines" using primary key columns */
  brand_lines_by_pk?: Maybe<BrandLines>;
  /** fetch data from the table: "brands" */
  brands: Array<Brands>;
  /** fetch aggregated fields from the table: "brands" */
  brands_aggregate: BrandsAggregate;
  /** fetch data from the table: "brands" using primary key columns */
  brands_by_pk?: Maybe<Brands>;
  /** fetch data from the table: "charts" */
  charts: Array<Charts>;
  /** fetch aggregated fields from the table: "charts" */
  charts_aggregate: ChartsAggregate;
  /** fetch data from the table: "charts" using primary key columns */
  charts_by_pk?: Maybe<Charts>;
  /** fetch data from the table: "charts_public" */
  charts_public: Array<ChartsPublic>;
  /** fetch aggregated fields from the table: "charts_public" */
  charts_public_aggregate: ChartsPublicAggregate;
  /** fetch data from the table: "collections" */
  collections: Array<Collections>;
  /** fetch aggregated fields from the table: "collections" */
  collections_aggregate: CollectionsAggregate;
  /** fetch data from the table: "collections" using primary key columns */
  collections_by_pk?: Maybe<Collections>;
  /** fetch data from the table: "fabric_types" */
  fabric_types: Array<FabricTypes>;
  /** fetch aggregated fields from the table: "fabric_types" */
  fabric_types_aggregate: FabricTypesAggregate;
  /** fetch data from the table: "fabric_types" using primary key columns */
  fabric_types_by_pk?: Maybe<FabricTypes>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: FilesAggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "finish_types" */
  finish_types: Array<FinishTypes>;
  /** fetch aggregated fields from the table: "finish_types" */
  finish_types_aggregate: FinishTypesAggregate;
  /** fetch data from the table: "finish_types" using primary key columns */
  finish_types_by_pk?: Maybe<FinishTypes>;
  /** fetch data from the table: "mottling_types" */
  mottling_types: Array<MottlingTypes>;
  /** fetch aggregated fields from the table: "mottling_types" */
  mottling_types_aggregate: MottlingTypesAggregate;
  /** fetch data from the table: "mottling_types" using primary key columns */
  mottling_types_by_pk?: Maybe<MottlingTypes>;
  /** fetch data from the table: "order_statuses" */
  order_statuses: Array<OrderStatuses>;
  /** fetch aggregated fields from the table: "order_statuses" */
  order_statuses_aggregate: OrderStatusesAggregate;
  /** fetch data from the table: "order_statuses" using primary key columns */
  order_statuses_by_pk?: Maybe<OrderStatuses>;
  /** fetch data from the table: "orders" */
  orders: Array<Orders>;
  /** fetch aggregated fields from the table: "orders" */
  orders_aggregate: OrdersAggregate;
  /** fetch data from the table: "orders" using primary key columns */
  orders_by_pk?: Maybe<Orders>;
  /** fetch data from the table: "orders_items" */
  orders_items: Array<OrdersItems>;
  /** fetch aggregated fields from the table: "orders_items" */
  orders_items_aggregate: OrdersItemsAggregate;
  /** fetch data from the table: "orders_items" using primary key columns */
  orders_items_by_pk?: Maybe<OrdersItems>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: ProductsAggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "products_collections" */
  products_collections: Array<ProductsCollections>;
  /** fetch aggregated fields from the table: "products_collections" */
  products_collections_aggregate: ProductsCollectionsAggregate;
  /** fetch data from the table: "products_collections" using primary key columns */
  products_collections_by_pk?: Maybe<ProductsCollections>;
  /** fetch data from the table: "products_images" */
  products_images: Array<ProductsImages>;
  /** fetch aggregated fields from the table: "products_images" */
  products_images_aggregate: ProductsImagesAggregate;
  /** fetch data from the table: "products_images" using primary key columns */
  products_images_by_pk?: Maybe<ProductsImages>;
  /** fetch data from the table: "products_tags" */
  products_tags: Array<ProductsTags>;
  /** fetch aggregated fields from the table: "products_tags" */
  products_tags_aggregate: ProductsTagsAggregate;
  /** fetch data from the table: "products_tags" using primary key columns */
  products_tags_by_pk?: Maybe<ProductsTags>;
  /** fetch data from the table: "projects" */
  projects: Array<Projects>;
  /** fetch aggregated fields from the table: "projects" */
  projects_aggregate: ProjectsAggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "shops" */
  shops: Array<Shops>;
  /** fetch aggregated fields from the table: "shops" */
  shops_aggregate: ShopsAggregate;
  /** fetch data from the table: "shops" using primary key columns */
  shops_by_pk?: Maybe<Shops>;
  /** fetch data from the table: "stitch_groups" */
  stitch_groups: Array<StitchGroups>;
  /** fetch aggregated fields from the table: "stitch_groups" */
  stitch_groups_aggregate: StitchGroupsAggregate;
  /** fetch data from the table: "stitch_groups" using primary key columns */
  stitch_groups_by_pk?: Maybe<StitchGroups>;
  /** fetch data from the table: "stitch_types" */
  stitch_types: Array<StitchTypes>;
  /** fetch aggregated fields from the table: "stitch_types" */
  stitch_types_aggregate: StitchTypesAggregate;
  /** fetch data from the table: "stitch_types" using primary key columns */
  stitch_types_by_pk?: Maybe<StitchTypes>;
  /** fetch data from the table: "stripe_payment_intent_statuses" */
  stripe_payment_intent_statuses: Array<StripePaymentIntentStatuses>;
  /** fetch aggregated fields from the table: "stripe_payment_intent_statuses" */
  stripe_payment_intent_statuses_aggregate: StripePaymentIntentStatusesAggregate;
  /** fetch data from the table: "stripe_payment_intent_statuses" using primary key columns */
  stripe_payment_intent_statuses_by_pk?: Maybe<StripePaymentIntentStatuses>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: TagsAggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "themes" */
  themes: Array<Themes>;
  /** fetch aggregated fields from the table: "themes" */
  themes_aggregate: ThemesAggregate;
  /** fetch data from the table: "themes" using primary key columns */
  themes_by_pk?: Maybe<Themes>;
  /** fetch data from the table: "themes_public" */
  themes_public: Array<ThemesPublic>;
  /** fetch aggregated fields from the table: "themes_public" */
  themes_public_aggregate: ThemesPublicAggregate;
  /** fetch data from the table: "themes_stitch_groups_threads" */
  themes_stitch_groups_threads: Array<ThemesStitchGroupsThreads>;
  /** fetch aggregated fields from the table: "themes_stitch_groups_threads" */
  themes_stitch_groups_threads_aggregate: ThemesStitchGroupsThreadsAggregate;
  /** fetch data from the table: "themes_stitch_groups_threads" using primary key columns */
  themes_stitch_groups_threads_by_pk?: Maybe<ThemesStitchGroupsThreads>;
  /** fetch data from the table: "threads" */
  threads: Array<Threads>;
  /** fetch aggregated fields from the table: "threads" */
  threads_aggregate: ThreadsAggregate;
  /** fetch data from the table: "threads" using primary key columns */
  threads_by_pk?: Maybe<Threads>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: UsersAggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};

/** subscription root */
export type SubscriptionRootAuthCodesArgs = {
  distinct_on?: Maybe<Array<AuthCodesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthCodesOrderBy>>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** subscription root */
export type SubscriptionRootAuthCodesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthCodesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthCodesOrderBy>>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** subscription root */
export type SubscriptionRootAuthCodesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootBrandColorsArgs = {
  distinct_on?: Maybe<Array<BrandColorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandColorsOrderBy>>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** subscription root */
export type SubscriptionRootBrandColorsAggregateArgs = {
  distinct_on?: Maybe<Array<BrandColorsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandColorsOrderBy>>;
  where?: Maybe<BrandColorsBoolExp>;
};

/** subscription root */
export type SubscriptionRootBrandColorsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootBrandLinesArgs = {
  distinct_on?: Maybe<Array<BrandLinesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandLinesOrderBy>>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** subscription root */
export type SubscriptionRootBrandLinesAggregateArgs = {
  distinct_on?: Maybe<Array<BrandLinesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandLinesOrderBy>>;
  where?: Maybe<BrandLinesBoolExp>;
};

/** subscription root */
export type SubscriptionRootBrandLinesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootBrandsArgs = {
  distinct_on?: Maybe<Array<BrandsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandsOrderBy>>;
  where?: Maybe<BrandsBoolExp>;
};

/** subscription root */
export type SubscriptionRootBrandsAggregateArgs = {
  distinct_on?: Maybe<Array<BrandsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<BrandsOrderBy>>;
  where?: Maybe<BrandsBoolExp>;
};

/** subscription root */
export type SubscriptionRootBrandsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootChartsArgs = {
  distinct_on?: Maybe<Array<ChartsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsOrderBy>>;
  where?: Maybe<ChartsBoolExp>;
};

/** subscription root */
export type SubscriptionRootChartsAggregateArgs = {
  distinct_on?: Maybe<Array<ChartsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsOrderBy>>;
  where?: Maybe<ChartsBoolExp>;
};

/** subscription root */
export type SubscriptionRootChartsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootChartsPublicArgs = {
  distinct_on?: Maybe<Array<ChartsPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsPublicOrderBy>>;
  where?: Maybe<ChartsPublicBoolExp>;
};

/** subscription root */
export type SubscriptionRootChartsPublicAggregateArgs = {
  distinct_on?: Maybe<Array<ChartsPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ChartsPublicOrderBy>>;
  where?: Maybe<ChartsPublicBoolExp>;
};

/** subscription root */
export type SubscriptionRootCollectionsArgs = {
  distinct_on?: Maybe<Array<CollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CollectionsOrderBy>>;
  where?: Maybe<CollectionsBoolExp>;
};

/** subscription root */
export type SubscriptionRootCollectionsAggregateArgs = {
  distinct_on?: Maybe<Array<CollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<CollectionsOrderBy>>;
  where?: Maybe<CollectionsBoolExp>;
};

/** subscription root */
export type SubscriptionRootCollectionsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootFabricTypesArgs = {
  distinct_on?: Maybe<Array<FabricTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FabricTypesOrderBy>>;
  where?: Maybe<FabricTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootFabricTypesAggregateArgs = {
  distinct_on?: Maybe<Array<FabricTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FabricTypesOrderBy>>;
  where?: Maybe<FabricTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootFabricTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootFilesArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

/** subscription root */
export type SubscriptionRootFilesAggregateArgs = {
  distinct_on?: Maybe<Array<FilesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FilesOrderBy>>;
  where?: Maybe<FilesBoolExp>;
};

/** subscription root */
export type SubscriptionRootFilesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootFinishTypesArgs = {
  distinct_on?: Maybe<Array<FinishTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FinishTypesOrderBy>>;
  where?: Maybe<FinishTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootFinishTypesAggregateArgs = {
  distinct_on?: Maybe<Array<FinishTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<FinishTypesOrderBy>>;
  where?: Maybe<FinishTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootFinishTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootMottlingTypesArgs = {
  distinct_on?: Maybe<Array<MottlingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MottlingTypesOrderBy>>;
  where?: Maybe<MottlingTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootMottlingTypesAggregateArgs = {
  distinct_on?: Maybe<Array<MottlingTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MottlingTypesOrderBy>>;
  where?: Maybe<MottlingTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootMottlingTypesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootOrderStatusesArgs = {
  distinct_on?: Maybe<Array<OrderStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusesOrderBy>>;
  where?: Maybe<OrderStatusesBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrderStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<OrderStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrderStatusesOrderBy>>;
  where?: Maybe<OrderStatusesBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrderStatusesByPkArgs = {
  name: Scalars['String'];
};

/** subscription root */
export type SubscriptionRootOrdersArgs = {
  distinct_on?: Maybe<Array<OrdersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersOrderBy>>;
  where?: Maybe<OrdersBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrdersAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersOrderBy>>;
  where?: Maybe<OrdersBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrdersByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootOrdersItemsArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrdersItemsAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersItemsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersItemsOrderBy>>;
  where?: Maybe<OrdersItemsBoolExp>;
};

/** subscription root */
export type SubscriptionRootOrdersItemsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootProductsArgs = {
  distinct_on?: Maybe<Array<ProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsOrderBy>>;
  where?: Maybe<ProductsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsOrderBy>>;
  where?: Maybe<ProductsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootProductsCollectionsArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsCollectionsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsCollectionsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsCollectionsOrderBy>>;
  where?: Maybe<ProductsCollectionsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsCollectionsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootProductsImagesArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsImagesAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsImagesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsImagesOrderBy>>;
  where?: Maybe<ProductsImagesBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsImagesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootProductsTagsArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsTagsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProductsTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
};

/** subscription root */
export type SubscriptionRootProjectsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootShopsArgs = {
  distinct_on?: Maybe<Array<ShopsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ShopsOrderBy>>;
  where?: Maybe<ShopsBoolExp>;
};

/** subscription root */
export type SubscriptionRootShopsAggregateArgs = {
  distinct_on?: Maybe<Array<ShopsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ShopsOrderBy>>;
  where?: Maybe<ShopsBoolExp>;
};

/** subscription root */
export type SubscriptionRootShopsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootStitchGroupsArgs = {
  distinct_on?: Maybe<Array<StitchGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchGroupsOrderBy>>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** subscription root */
export type SubscriptionRootStitchGroupsAggregateArgs = {
  distinct_on?: Maybe<Array<StitchGroupsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchGroupsOrderBy>>;
  where?: Maybe<StitchGroupsBoolExp>;
};

/** subscription root */
export type SubscriptionRootStitchGroupsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootStitchTypesArgs = {
  distinct_on?: Maybe<Array<StitchTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchTypesOrderBy>>;
  where?: Maybe<StitchTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootStitchTypesAggregateArgs = {
  distinct_on?: Maybe<Array<StitchTypesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StitchTypesOrderBy>>;
  where?: Maybe<StitchTypesBoolExp>;
};

/** subscription root */
export type SubscriptionRootStitchTypesByPkArgs = {
  name: Scalars['String'];
};

/** subscription root */
export type SubscriptionRootStripePaymentIntentStatusesArgs = {
  distinct_on?: Maybe<Array<StripePaymentIntentStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StripePaymentIntentStatusesOrderBy>>;
  where?: Maybe<StripePaymentIntentStatusesBoolExp>;
};

/** subscription root */
export type SubscriptionRootStripePaymentIntentStatusesAggregateArgs = {
  distinct_on?: Maybe<Array<StripePaymentIntentStatusesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<StripePaymentIntentStatusesOrderBy>>;
  where?: Maybe<StripePaymentIntentStatusesBoolExp>;
};

/** subscription root */
export type SubscriptionRootStripePaymentIntentStatusesByPkArgs = {
  name: Scalars['String'];
};

/** subscription root */
export type SubscriptionRootTagsArgs = {
  distinct_on?: Maybe<Array<TagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TagsOrderBy>>;
  where?: Maybe<TagsBoolExp>;
};

/** subscription root */
export type SubscriptionRootTagsAggregateArgs = {
  distinct_on?: Maybe<Array<TagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<TagsOrderBy>>;
  where?: Maybe<TagsBoolExp>;
};

/** subscription root */
export type SubscriptionRootTagsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootThemesArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** subscription root */
export type SubscriptionRootThemesAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** subscription root */
export type SubscriptionRootThemesByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootThemesPublicArgs = {
  distinct_on?: Maybe<Array<ThemesPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesPublicOrderBy>>;
  where?: Maybe<ThemesPublicBoolExp>;
};

/** subscription root */
export type SubscriptionRootThemesPublicAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesPublicSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesPublicOrderBy>>;
  where?: Maybe<ThemesPublicBoolExp>;
};

/** subscription root */
export type SubscriptionRootThemesStitchGroupsThreadsArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** subscription root */
export type SubscriptionRootThemesStitchGroupsThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** subscription root */
export type SubscriptionRootThemesStitchGroupsThreadsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootThreadsArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** subscription root */
export type SubscriptionRootThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThreadsOrderBy>>;
  where?: Maybe<ThreadsBoolExp>;
};

/** subscription root */
export type SubscriptionRootThreadsByPkArgs = {
  id: Scalars['uuid'];
};

/** subscription root */
export type SubscriptionRootUsersArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** subscription root */
export type SubscriptionRootUsersAggregateArgs = {
  distinct_on?: Maybe<Array<UsersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UsersOrderBy>>;
  where?: Maybe<UsersBoolExp>;
};

/** subscription root */
export type SubscriptionRootUsersByPkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  color: Scalars['_rgb_int'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  products_tags: Array<ProductsTags>;
  /** An aggregated array relationship */
  products_tags_aggregate: ProductsTagsAggregate;
  slug: Scalars['String'];
};

/** columns and relationships of "tags" */
export type TagsProductsTagsArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** columns and relationships of "tags" */
export type TagsProductsTagsAggregateArgs = {
  distinct_on?: Maybe<Array<ProductsTagsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProductsTagsOrderBy>>;
  where?: Maybe<ProductsTagsBoolExp>;
};

/** aggregated selection of "tags" */
export type TagsAggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<TagsAggregateFields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type TagsAggregateFields = {
  __typename?: 'tags_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<TagsMaxFields>;
  min?: Maybe<TagsMinFields>;
};

/** aggregate fields of "tags" */
export type TagsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<TagsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tags" */
export type TagsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<TagsMaxOrderBy>;
  min?: Maybe<TagsMinOrderBy>;
};

/** input type for inserting array relation for remote table "tags" */
export type TagsArrRelInsertInput = {
  data: Array<TagsInsertInput>;
  on_conflict?: Maybe<TagsOnConflict>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type TagsBoolExp = {
  _and?: Maybe<Array<Maybe<TagsBoolExp>>>;
  _not?: Maybe<TagsBoolExp>;
  _or?: Maybe<Array<Maybe<TagsBoolExp>>>;
  color?: Maybe<RgbIntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  products_tags?: Maybe<ProductsTagsBoolExp>;
  slug?: Maybe<StringComparisonExp>;
};

/** unique or primary key constraints on table "tags" */
export enum TagsConstraint {
  /** unique or primary key constraint */
  TagsNameKey = 'tags_name_key',
  /** unique or primary key constraint */
  TagsPkey = 'tags_pkey',
  /** unique or primary key constraint */
  TagsSlugKey = 'tags_slug_key',
}

/** input type for inserting data into table "tags" */
export type TagsInsertInput = {
  color?: Maybe<Scalars['_rgb_int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  products_tags?: Maybe<ProductsTagsArrRelInsertInput>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type TagsMaxFields = {
  __typename?: 'tags_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tags" */
export type TagsMaxOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type TagsMinFields = {
  __typename?: 'tags_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tags" */
export type TagsMinOrderBy = {
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  slug?: Maybe<OrderBy>;
};

/** response of any mutation on the table "tags" */
export type TagsMutationResponse = {
  __typename?: 'tags_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type TagsObjRelInsertInput = {
  data: TagsInsertInput;
  on_conflict?: Maybe<TagsOnConflict>;
};

/** on conflict condition type for table "tags" */
export type TagsOnConflict = {
  constraint: TagsConstraint;
  update_columns: Array<TagsUpdateColumn>;
  where?: Maybe<TagsBoolExp>;
};

/** ordering options when selecting data from "tags" */
export type TagsOrderBy = {
  color?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  products_tags_aggregate?: Maybe<ProductsTagsAggregateOrderBy>;
  slug?: Maybe<OrderBy>;
};

/** primary key columns input for table: "tags" */
export type TagsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "tags" */
export enum TagsSelectColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "tags" */
export type TagsSetInput = {
  color?: Maybe<Scalars['_rgb_int']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** update columns of table "tags" */
export enum TagsUpdateColumn {
  /** column name */
  Color = 'color',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** columns and relationships of "themes" */
export type Themes = {
  __typename?: 'themes';
  /** An object relationship */
  background_file?: Maybe<Files>;
  background_file_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  chart: Charts;
  chart_id: Scalars['uuid'];
  /** An array relationship */
  child_themes: Array<Themes>;
  /** An aggregated array relationship */
  child_themes_aggregate: ThemesAggregate;
  created_at: Scalars['timestamptz'];
  fabric_color: Scalars['_rgb_int'];
  fabric_count: Scalars['Int'];
  fabric_margin: Scalars['rectangle_spacing'];
  fabric_padding: Scalars['rectangle_spacing'];
  /** An object relationship */
  fabric_type: FabricTypes;
  fabric_type_id: Scalars['uuid'];
  finish_color: Scalars['_rgb_int'];
  finish_size?: Maybe<Scalars['dimensions']>;
  /** An object relationship */
  finish_type?: Maybe<FinishTypes>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  foreground_file?: Maybe<Files>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_published: Scalars['Boolean'];
  mottling_color: Scalars['rgba_color'];
  /** An object relationship */
  mottling_type?: Maybe<MottlingTypes>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  over: Scalars['Int'];
  /** An object relationship */
  parent_theme?: Maybe<Themes>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  product?: Maybe<Products>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregated array relationship */
  projects_aggregate: ProjectsAggregate;
  /** An array relationship */
  themes_stitch_groups_threads: Array<ThemesStitchGroupsThreads>;
  /** An aggregated array relationship */
  themes_stitch_groups_threads_aggregate: ThemesStitchGroupsThreadsAggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "themes" */
export type ThemesChildThemesArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** columns and relationships of "themes" */
export type ThemesChildThemesAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesOrderBy>>;
  where?: Maybe<ThemesBoolExp>;
};

/** columns and relationships of "themes" */
export type ThemesProjectsArgs = {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
};

/** columns and relationships of "themes" */
export type ThemesProjectsAggregateArgs = {
  distinct_on?: Maybe<Array<ProjectsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ProjectsOrderBy>>;
  where?: Maybe<ProjectsBoolExp>;
};

/** columns and relationships of "themes" */
export type ThemesThemesStitchGroupsThreadsArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** columns and relationships of "themes" */
export type ThemesThemesStitchGroupsThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** aggregated selection of "themes" */
export type ThemesAggregate = {
  __typename?: 'themes_aggregate';
  aggregate?: Maybe<ThemesAggregateFields>;
  nodes: Array<Themes>;
};

/** aggregate fields of "themes" */
export type ThemesAggregateFields = {
  __typename?: 'themes_aggregate_fields';
  avg?: Maybe<ThemesAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ThemesMaxFields>;
  min?: Maybe<ThemesMinFields>;
  stddev?: Maybe<ThemesStddevFields>;
  stddev_pop?: Maybe<ThemesStddevPopFields>;
  stddev_samp?: Maybe<ThemesStddevSampFields>;
  sum?: Maybe<ThemesSumFields>;
  var_pop?: Maybe<ThemesVarPopFields>;
  var_samp?: Maybe<ThemesVarSampFields>;
  variance?: Maybe<ThemesVarianceFields>;
};

/** aggregate fields of "themes" */
export type ThemesAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ThemesSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "themes" */
export type ThemesAggregateOrderBy = {
  avg?: Maybe<ThemesAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ThemesMaxOrderBy>;
  min?: Maybe<ThemesMinOrderBy>;
  stddev?: Maybe<ThemesStddevOrderBy>;
  stddev_pop?: Maybe<ThemesStddevPopOrderBy>;
  stddev_samp?: Maybe<ThemesStddevSampOrderBy>;
  sum?: Maybe<ThemesSumOrderBy>;
  var_pop?: Maybe<ThemesVarPopOrderBy>;
  var_samp?: Maybe<ThemesVarSampOrderBy>;
  variance?: Maybe<ThemesVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "themes" */
export type ThemesArrRelInsertInput = {
  data: Array<ThemesInsertInput>;
  on_conflict?: Maybe<ThemesOnConflict>;
};

/** aggregate avg on columns */
export type ThemesAvgFields = {
  __typename?: 'themes_avg_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "themes" */
export type ThemesAvgOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "themes". All fields are combined with a logical 'AND'. */
export type ThemesBoolExp = {
  _and?: Maybe<Array<Maybe<ThemesBoolExp>>>;
  _not?: Maybe<ThemesBoolExp>;
  _or?: Maybe<Array<Maybe<ThemesBoolExp>>>;
  background_file?: Maybe<FilesBoolExp>;
  background_file_id?: Maybe<UuidComparisonExp>;
  chart?: Maybe<ChartsBoolExp>;
  chart_id?: Maybe<UuidComparisonExp>;
  child_themes?: Maybe<ThemesBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  fabric_color?: Maybe<RgbIntComparisonExp>;
  fabric_count?: Maybe<IntComparisonExp>;
  fabric_margin?: Maybe<RectangleSpacingComparisonExp>;
  fabric_padding?: Maybe<RectangleSpacingComparisonExp>;
  fabric_type?: Maybe<FabricTypesBoolExp>;
  fabric_type_id?: Maybe<UuidComparisonExp>;
  finish_color?: Maybe<RgbIntComparisonExp>;
  finish_size?: Maybe<DimensionsComparisonExp>;
  finish_type?: Maybe<FinishTypesBoolExp>;
  finish_type_id?: Maybe<UuidComparisonExp>;
  foreground_file?: Maybe<FilesBoolExp>;
  foreground_file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_published?: Maybe<BooleanComparisonExp>;
  mottling_color?: Maybe<RgbaColorComparisonExp>;
  mottling_type?: Maybe<MottlingTypesBoolExp>;
  mottling_type_id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  over?: Maybe<IntComparisonExp>;
  parent_theme?: Maybe<ThemesBoolExp>;
  parent_theme_id?: Maybe<UuidComparisonExp>;
  product?: Maybe<ProductsBoolExp>;
  projects?: Maybe<ProjectsBoolExp>;
  themes_stitch_groups_threads?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user?: Maybe<UsersBoolExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "themes" */
export enum ThemesConstraint {
  /** unique or primary key constraint */
  ThemesPkey = 'themes_pkey',
}

/** input type for incrementing integer column in table "themes" */
export type ThemesIncInput = {
  fabric_count?: Maybe<Scalars['Int']>;
  over?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "themes" */
export type ThemesInsertInput = {
  background_file?: Maybe<FilesObjRelInsertInput>;
  background_file_id?: Maybe<Scalars['uuid']>;
  chart?: Maybe<ChartsObjRelInsertInput>;
  chart_id?: Maybe<Scalars['uuid']>;
  child_themes?: Maybe<ThemesArrRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_color?: Maybe<Scalars['_rgb_int']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_margin?: Maybe<Scalars['rectangle_spacing']>;
  fabric_padding?: Maybe<Scalars['rectangle_spacing']>;
  fabric_type?: Maybe<FabricTypesObjRelInsertInput>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_color?: Maybe<Scalars['_rgb_int']>;
  finish_size?: Maybe<Scalars['dimensions']>;
  finish_type?: Maybe<FinishTypesObjRelInsertInput>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file?: Maybe<FilesObjRelInsertInput>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  mottling_color?: Maybe<Scalars['rgba_color']>;
  mottling_type?: Maybe<MottlingTypesObjRelInsertInput>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme?: Maybe<ThemesObjRelInsertInput>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  product?: Maybe<ProductsObjRelInsertInput>;
  projects?: Maybe<ProjectsArrRelInsertInput>;
  themes_stitch_groups_threads?: Maybe<
    ThemesStitchGroupsThreadsArrRelInsertInput
  >;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<UsersObjRelInsertInput>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ThemesMaxFields = {
  __typename?: 'themes_max_fields';
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "themes" */
export type ThemesMaxOrderBy = {
  background_file_id?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  fabric_count?: Maybe<OrderBy>;
  fabric_type_id?: Maybe<OrderBy>;
  finish_type_id?: Maybe<OrderBy>;
  foreground_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mottling_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
  parent_theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ThemesMinFields = {
  __typename?: 'themes_min_fields';
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "themes" */
export type ThemesMinOrderBy = {
  background_file_id?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  fabric_count?: Maybe<OrderBy>;
  fabric_type_id?: Maybe<OrderBy>;
  finish_type_id?: Maybe<OrderBy>;
  foreground_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mottling_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
  parent_theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "themes" */
export type ThemesMutationResponse = {
  __typename?: 'themes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Themes>;
};

/** input type for inserting object relation for remote table "themes" */
export type ThemesObjRelInsertInput = {
  data: ThemesInsertInput;
  on_conflict?: Maybe<ThemesOnConflict>;
};

/** on conflict condition type for table "themes" */
export type ThemesOnConflict = {
  constraint: ThemesConstraint;
  update_columns: Array<ThemesUpdateColumn>;
  where?: Maybe<ThemesBoolExp>;
};

/** ordering options when selecting data from "themes" */
export type ThemesOrderBy = {
  background_file?: Maybe<FilesOrderBy>;
  background_file_id?: Maybe<OrderBy>;
  chart?: Maybe<ChartsOrderBy>;
  chart_id?: Maybe<OrderBy>;
  child_themes_aggregate?: Maybe<ThemesAggregateOrderBy>;
  created_at?: Maybe<OrderBy>;
  fabric_color?: Maybe<OrderBy>;
  fabric_count?: Maybe<OrderBy>;
  fabric_margin?: Maybe<OrderBy>;
  fabric_padding?: Maybe<OrderBy>;
  fabric_type?: Maybe<FabricTypesOrderBy>;
  fabric_type_id?: Maybe<OrderBy>;
  finish_color?: Maybe<OrderBy>;
  finish_size?: Maybe<OrderBy>;
  finish_type?: Maybe<FinishTypesOrderBy>;
  finish_type_id?: Maybe<OrderBy>;
  foreground_file?: Maybe<FilesOrderBy>;
  foreground_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_published?: Maybe<OrderBy>;
  mottling_color?: Maybe<OrderBy>;
  mottling_type?: Maybe<MottlingTypesOrderBy>;
  mottling_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
  parent_theme?: Maybe<ThemesOrderBy>;
  parent_theme_id?: Maybe<OrderBy>;
  product?: Maybe<ProductsOrderBy>;
  projects_aggregate?: Maybe<ProjectsAggregateOrderBy>;
  themes_stitch_groups_threads_aggregate?: Maybe<
    ThemesStitchGroupsThreadsAggregateOrderBy
  >;
  updated_at?: Maybe<OrderBy>;
  user?: Maybe<UsersOrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "themes" */
export type ThemesPkColumnsInput = {
  id: Scalars['uuid'];
};

/** columns and relationships of "themes_public" */
export type ThemesPublic = {
  __typename?: 'themes_public';
  /** An object relationship */
  background_file?: Maybe<Files>;
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  chart_public?: Maybe<ChartsPublic>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_color?: Maybe<Scalars['_rgb_int']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_margin?: Maybe<Scalars['rectangle_spacing']>;
  fabric_padding?: Maybe<Scalars['rectangle_spacing']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_color?: Maybe<Scalars['_rgb_int']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  foreground_file?: Maybe<Files>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  mottling_color?: Maybe<Scalars['rgba_color']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  themes_stitch_groups_threads: Array<ThemesStitchGroupsThreads>;
  /** An aggregated array relationship */
  themes_stitch_groups_threads_aggregate: ThemesStitchGroupsThreadsAggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "themes_public" */
export type ThemesPublicThemesStitchGroupsThreadsArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** columns and relationships of "themes_public" */
export type ThemesPublicThemesStitchGroupsThreadsAggregateArgs = {
  distinct_on?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<ThemesStitchGroupsThreadsOrderBy>>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** aggregated selection of "themes_public" */
export type ThemesPublicAggregate = {
  __typename?: 'themes_public_aggregate';
  aggregate?: Maybe<ThemesPublicAggregateFields>;
  nodes: Array<ThemesPublic>;
};

/** aggregate fields of "themes_public" */
export type ThemesPublicAggregateFields = {
  __typename?: 'themes_public_aggregate_fields';
  avg?: Maybe<ThemesPublicAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ThemesPublicMaxFields>;
  min?: Maybe<ThemesPublicMinFields>;
  stddev?: Maybe<ThemesPublicStddevFields>;
  stddev_pop?: Maybe<ThemesPublicStddevPopFields>;
  stddev_samp?: Maybe<ThemesPublicStddevSampFields>;
  sum?: Maybe<ThemesPublicSumFields>;
  var_pop?: Maybe<ThemesPublicVarPopFields>;
  var_samp?: Maybe<ThemesPublicVarSampFields>;
  variance?: Maybe<ThemesPublicVarianceFields>;
};

/** aggregate fields of "themes_public" */
export type ThemesPublicAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ThemesPublicSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "themes_public" */
export type ThemesPublicAggregateOrderBy = {
  avg?: Maybe<ThemesPublicAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ThemesPublicMaxOrderBy>;
  min?: Maybe<ThemesPublicMinOrderBy>;
  stddev?: Maybe<ThemesPublicStddevOrderBy>;
  stddev_pop?: Maybe<ThemesPublicStddevPopOrderBy>;
  stddev_samp?: Maybe<ThemesPublicStddevSampOrderBy>;
  sum?: Maybe<ThemesPublicSumOrderBy>;
  var_pop?: Maybe<ThemesPublicVarPopOrderBy>;
  var_samp?: Maybe<ThemesPublicVarSampOrderBy>;
  variance?: Maybe<ThemesPublicVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "themes_public" */
export type ThemesPublicArrRelInsertInput = {
  data: Array<ThemesPublicInsertInput>;
};

/** aggregate avg on columns */
export type ThemesPublicAvgFields = {
  __typename?: 'themes_public_avg_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "themes_public" */
export type ThemesPublicAvgOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "themes_public". All fields are combined with a logical 'AND'. */
export type ThemesPublicBoolExp = {
  _and?: Maybe<Array<Maybe<ThemesPublicBoolExp>>>;
  _not?: Maybe<ThemesPublicBoolExp>;
  _or?: Maybe<Array<Maybe<ThemesPublicBoolExp>>>;
  background_file?: Maybe<FilesBoolExp>;
  background_file_id?: Maybe<UuidComparisonExp>;
  chart_id?: Maybe<UuidComparisonExp>;
  chart_public?: Maybe<ChartsPublicBoolExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  fabric_color?: Maybe<RgbIntComparisonExp>;
  fabric_count?: Maybe<IntComparisonExp>;
  fabric_margin?: Maybe<RectangleSpacingComparisonExp>;
  fabric_padding?: Maybe<RectangleSpacingComparisonExp>;
  fabric_type_id?: Maybe<UuidComparisonExp>;
  finish_color?: Maybe<RgbIntComparisonExp>;
  finish_type_id?: Maybe<UuidComparisonExp>;
  foreground_file?: Maybe<FilesBoolExp>;
  foreground_file_id?: Maybe<UuidComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_published?: Maybe<BooleanComparisonExp>;
  mottling_color?: Maybe<RgbaColorComparisonExp>;
  mottling_type_id?: Maybe<UuidComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  over?: Maybe<IntComparisonExp>;
  parent_theme_id?: Maybe<UuidComparisonExp>;
  themes_stitch_groups_threads?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
  user_id?: Maybe<UuidComparisonExp>;
};

/** input type for incrementing integer column in table "themes_public" */
export type ThemesPublicIncInput = {
  fabric_count?: Maybe<Scalars['Int']>;
  over?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "themes_public" */
export type ThemesPublicInsertInput = {
  background_file?: Maybe<FilesObjRelInsertInput>;
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  chart_public?: Maybe<ChartsPublicObjRelInsertInput>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_color?: Maybe<Scalars['_rgb_int']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_margin?: Maybe<Scalars['rectangle_spacing']>;
  fabric_padding?: Maybe<Scalars['rectangle_spacing']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_color?: Maybe<Scalars['_rgb_int']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file?: Maybe<FilesObjRelInsertInput>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  mottling_color?: Maybe<Scalars['rgba_color']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  themes_stitch_groups_threads?: Maybe<
    ThemesStitchGroupsThreadsArrRelInsertInput
  >;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ThemesPublicMaxFields = {
  __typename?: 'themes_public_max_fields';
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "themes_public" */
export type ThemesPublicMaxOrderBy = {
  background_file_id?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  fabric_count?: Maybe<OrderBy>;
  fabric_type_id?: Maybe<OrderBy>;
  finish_type_id?: Maybe<OrderBy>;
  foreground_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mottling_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
  parent_theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ThemesPublicMinFields = {
  __typename?: 'themes_public_min_fields';
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "themes_public" */
export type ThemesPublicMinOrderBy = {
  background_file_id?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  fabric_count?: Maybe<OrderBy>;
  fabric_type_id?: Maybe<OrderBy>;
  finish_type_id?: Maybe<OrderBy>;
  foreground_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  mottling_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
  parent_theme_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "themes_public" */
export type ThemesPublicMutationResponse = {
  __typename?: 'themes_public_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ThemesPublic>;
};

/** input type for inserting object relation for remote table "themes_public" */
export type ThemesPublicObjRelInsertInput = {
  data: ThemesPublicInsertInput;
};

/** ordering options when selecting data from "themes_public" */
export type ThemesPublicOrderBy = {
  background_file?: Maybe<FilesOrderBy>;
  background_file_id?: Maybe<OrderBy>;
  chart_id?: Maybe<OrderBy>;
  chart_public?: Maybe<ChartsPublicOrderBy>;
  created_at?: Maybe<OrderBy>;
  fabric_color?: Maybe<OrderBy>;
  fabric_count?: Maybe<OrderBy>;
  fabric_margin?: Maybe<OrderBy>;
  fabric_padding?: Maybe<OrderBy>;
  fabric_type_id?: Maybe<OrderBy>;
  finish_color?: Maybe<OrderBy>;
  finish_type_id?: Maybe<OrderBy>;
  foreground_file?: Maybe<FilesOrderBy>;
  foreground_file_id?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_published?: Maybe<OrderBy>;
  mottling_color?: Maybe<OrderBy>;
  mottling_type_id?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
  parent_theme_id?: Maybe<OrderBy>;
  themes_stitch_groups_threads_aggregate?: Maybe<
    ThemesStitchGroupsThreadsAggregateOrderBy
  >;
  updated_at?: Maybe<OrderBy>;
  user_id?: Maybe<OrderBy>;
};

/** select columns of table "themes_public" */
export enum ThemesPublicSelectColumn {
  /** column name */
  BackgroundFileId = 'background_file_id',
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FabricColor = 'fabric_color',
  /** column name */
  FabricCount = 'fabric_count',
  /** column name */
  FabricMargin = 'fabric_margin',
  /** column name */
  FabricPadding = 'fabric_padding',
  /** column name */
  FabricTypeId = 'fabric_type_id',
  /** column name */
  FinishColor = 'finish_color',
  /** column name */
  FinishTypeId = 'finish_type_id',
  /** column name */
  ForegroundFileId = 'foreground_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  MottlingColor = 'mottling_color',
  /** column name */
  MottlingTypeId = 'mottling_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Over = 'over',
  /** column name */
  ParentThemeId = 'parent_theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "themes_public" */
export type ThemesPublicSetInput = {
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_color?: Maybe<Scalars['_rgb_int']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_margin?: Maybe<Scalars['rectangle_spacing']>;
  fabric_padding?: Maybe<Scalars['rectangle_spacing']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_color?: Maybe<Scalars['_rgb_int']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  mottling_color?: Maybe<Scalars['rgba_color']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ThemesPublicStddevFields = {
  __typename?: 'themes_public_stddev_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "themes_public" */
export type ThemesPublicStddevOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ThemesPublicStddevPopFields = {
  __typename?: 'themes_public_stddev_pop_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "themes_public" */
export type ThemesPublicStddevPopOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ThemesPublicStddevSampFields = {
  __typename?: 'themes_public_stddev_samp_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "themes_public" */
export type ThemesPublicStddevSampOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ThemesPublicSumFields = {
  __typename?: 'themes_public_sum_fields';
  fabric_count?: Maybe<Scalars['Int']>;
  over?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "themes_public" */
export type ThemesPublicSumOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate var_pop on columns */
export type ThemesPublicVarPopFields = {
  __typename?: 'themes_public_var_pop_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "themes_public" */
export type ThemesPublicVarPopOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ThemesPublicVarSampFields = {
  __typename?: 'themes_public_var_samp_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "themes_public" */
export type ThemesPublicVarSampOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ThemesPublicVarianceFields = {
  __typename?: 'themes_public_variance_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "themes_public" */
export type ThemesPublicVarianceOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** select columns of table "themes" */
export enum ThemesSelectColumn {
  /** column name */
  BackgroundFileId = 'background_file_id',
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FabricColor = 'fabric_color',
  /** column name */
  FabricCount = 'fabric_count',
  /** column name */
  FabricMargin = 'fabric_margin',
  /** column name */
  FabricPadding = 'fabric_padding',
  /** column name */
  FabricTypeId = 'fabric_type_id',
  /** column name */
  FinishColor = 'finish_color',
  /** column name */
  FinishSize = 'finish_size',
  /** column name */
  FinishTypeId = 'finish_type_id',
  /** column name */
  ForegroundFileId = 'foreground_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  MottlingColor = 'mottling_color',
  /** column name */
  MottlingTypeId = 'mottling_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Over = 'over',
  /** column name */
  ParentThemeId = 'parent_theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "themes" */
export type ThemesSetInput = {
  background_file_id?: Maybe<Scalars['uuid']>;
  chart_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fabric_color?: Maybe<Scalars['_rgb_int']>;
  fabric_count?: Maybe<Scalars['Int']>;
  fabric_margin?: Maybe<Scalars['rectangle_spacing']>;
  fabric_padding?: Maybe<Scalars['rectangle_spacing']>;
  fabric_type_id?: Maybe<Scalars['uuid']>;
  finish_color?: Maybe<Scalars['_rgb_int']>;
  finish_size?: Maybe<Scalars['dimensions']>;
  finish_type_id?: Maybe<Scalars['uuid']>;
  foreground_file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  is_published?: Maybe<Scalars['Boolean']>;
  mottling_color?: Maybe<Scalars['rgba_color']>;
  mottling_type_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  over?: Maybe<Scalars['Int']>;
  parent_theme_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ThemesStddevFields = {
  __typename?: 'themes_stddev_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "themes" */
export type ThemesStddevOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ThemesStddevPopFields = {
  __typename?: 'themes_stddev_pop_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "themes" */
export type ThemesStddevPopOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ThemesStddevSampFields = {
  __typename?: 'themes_stddev_samp_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "themes" */
export type ThemesStddevSampOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** columns and relationships of "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreads = {
  __typename?: 'themes_stitch_groups_threads';
  coverage: Scalars['smallint'];
  id: Scalars['uuid'];
  /** An object relationship */
  stitch_group: StitchGroups;
  stitch_group_id: Scalars['uuid'];
  /** An object relationship */
  theme: Themes;
  theme_id: Scalars['uuid'];
  /** An object relationship */
  thread: Threads;
  thread_id: Scalars['uuid'];
};

/** aggregated selection of "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsAggregate = {
  __typename?: 'themes_stitch_groups_threads_aggregate';
  aggregate?: Maybe<ThemesStitchGroupsThreadsAggregateFields>;
  nodes: Array<ThemesStitchGroupsThreads>;
};

/** aggregate fields of "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsAggregateFields = {
  __typename?: 'themes_stitch_groups_threads_aggregate_fields';
  avg?: Maybe<ThemesStitchGroupsThreadsAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ThemesStitchGroupsThreadsMaxFields>;
  min?: Maybe<ThemesStitchGroupsThreadsMinFields>;
  stddev?: Maybe<ThemesStitchGroupsThreadsStddevFields>;
  stddev_pop?: Maybe<ThemesStitchGroupsThreadsStddevPopFields>;
  stddev_samp?: Maybe<ThemesStitchGroupsThreadsStddevSampFields>;
  sum?: Maybe<ThemesStitchGroupsThreadsSumFields>;
  var_pop?: Maybe<ThemesStitchGroupsThreadsVarPopFields>;
  var_samp?: Maybe<ThemesStitchGroupsThreadsVarSampFields>;
  variance?: Maybe<ThemesStitchGroupsThreadsVarianceFields>;
};

/** aggregate fields of "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ThemesStitchGroupsThreadsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsAggregateOrderBy = {
  avg?: Maybe<ThemesStitchGroupsThreadsAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<ThemesStitchGroupsThreadsMaxOrderBy>;
  min?: Maybe<ThemesStitchGroupsThreadsMinOrderBy>;
  stddev?: Maybe<ThemesStitchGroupsThreadsStddevOrderBy>;
  stddev_pop?: Maybe<ThemesStitchGroupsThreadsStddevPopOrderBy>;
  stddev_samp?: Maybe<ThemesStitchGroupsThreadsStddevSampOrderBy>;
  sum?: Maybe<ThemesStitchGroupsThreadsSumOrderBy>;
  var_pop?: Maybe<ThemesStitchGroupsThreadsVarPopOrderBy>;
  var_samp?: Maybe<ThemesStitchGroupsThreadsVarSampOrderBy>;
  variance?: Maybe<ThemesStitchGroupsThreadsVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsArrRelInsertInput = {
  data: Array<ThemesStitchGroupsThreadsInsertInput>;
  on_conflict?: Maybe<ThemesStitchGroupsThreadsOnConflict>;
};

/** aggregate avg on columns */
export type ThemesStitchGroupsThreadsAvgFields = {
  __typename?: 'themes_stitch_groups_threads_avg_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsAvgOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "themes_stitch_groups_threads". All fields are combined with a logical 'AND'. */
export type ThemesStitchGroupsThreadsBoolExp = {
  _and?: Maybe<Array<Maybe<ThemesStitchGroupsThreadsBoolExp>>>;
  _not?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
  _or?: Maybe<Array<Maybe<ThemesStitchGroupsThreadsBoolExp>>>;
  coverage?: Maybe<SmallintComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  stitch_group?: Maybe<StitchGroupsBoolExp>;
  stitch_group_id?: Maybe<UuidComparisonExp>;
  theme?: Maybe<ThemesBoolExp>;
  theme_id?: Maybe<UuidComparisonExp>;
  thread?: Maybe<ThreadsBoolExp>;
  thread_id?: Maybe<UuidComparisonExp>;
};

/** unique or primary key constraints on table "themes_stitch_groups_threads" */
export enum ThemesStitchGroupsThreadsConstraint {
  /** unique or primary key constraint */
  ThemesStitchGroupsThreadsPkey = 'themes_stitch_groups_threads_pkey',
  /** unique or primary key constraint */
  ThemesStitchGroupsThreadsThemeIdStitchGroupIdKey = 'themes_stitch_groups_threads_theme_id_stitch_group_id_key',
}

/** input type for incrementing integer column in table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsIncInput = {
  coverage?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsInsertInput = {
  coverage?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  stitch_group?: Maybe<StitchGroupsObjRelInsertInput>;
  stitch_group_id?: Maybe<Scalars['uuid']>;
  theme?: Maybe<ThemesObjRelInsertInput>;
  theme_id?: Maybe<Scalars['uuid']>;
  thread?: Maybe<ThreadsObjRelInsertInput>;
  thread_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type ThemesStitchGroupsThreadsMaxFields = {
  __typename?: 'themes_stitch_groups_threads_max_fields';
  coverage?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  stitch_group_id?: Maybe<Scalars['uuid']>;
  theme_id?: Maybe<Scalars['uuid']>;
  thread_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsMaxOrderBy = {
  coverage?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  stitch_group_id?: Maybe<OrderBy>;
  theme_id?: Maybe<OrderBy>;
  thread_id?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ThemesStitchGroupsThreadsMinFields = {
  __typename?: 'themes_stitch_groups_threads_min_fields';
  coverage?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  stitch_group_id?: Maybe<Scalars['uuid']>;
  theme_id?: Maybe<Scalars['uuid']>;
  thread_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsMinOrderBy = {
  coverage?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  stitch_group_id?: Maybe<OrderBy>;
  theme_id?: Maybe<OrderBy>;
  thread_id?: Maybe<OrderBy>;
};

/** response of any mutation on the table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsMutationResponse = {
  __typename?: 'themes_stitch_groups_threads_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<ThemesStitchGroupsThreads>;
};

/** input type for inserting object relation for remote table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsObjRelInsertInput = {
  data: ThemesStitchGroupsThreadsInsertInput;
  on_conflict?: Maybe<ThemesStitchGroupsThreadsOnConflict>;
};

/** on conflict condition type for table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsOnConflict = {
  constraint: ThemesStitchGroupsThreadsConstraint;
  update_columns: Array<ThemesStitchGroupsThreadsUpdateColumn>;
  where?: Maybe<ThemesStitchGroupsThreadsBoolExp>;
};

/** ordering options when selecting data from "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsOrderBy = {
  coverage?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  stitch_group?: Maybe<StitchGroupsOrderBy>;
  stitch_group_id?: Maybe<OrderBy>;
  theme?: Maybe<ThemesOrderBy>;
  theme_id?: Maybe<OrderBy>;
  thread?: Maybe<ThreadsOrderBy>;
  thread_id?: Maybe<OrderBy>;
};

/** primary key columns input for table: "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "themes_stitch_groups_threads" */
export enum ThemesStitchGroupsThreadsSelectColumn {
  /** column name */
  Coverage = 'coverage',
  /** column name */
  Id = 'id',
  /** column name */
  StitchGroupId = 'stitch_group_id',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  ThreadId = 'thread_id',
}

/** input type for updating data in table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsSetInput = {
  coverage?: Maybe<Scalars['smallint']>;
  id?: Maybe<Scalars['uuid']>;
  stitch_group_id?: Maybe<Scalars['uuid']>;
  theme_id?: Maybe<Scalars['uuid']>;
  thread_id?: Maybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type ThemesStitchGroupsThreadsStddevFields = {
  __typename?: 'themes_stitch_groups_threads_stddev_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsStddevOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ThemesStitchGroupsThreadsStddevPopFields = {
  __typename?: 'themes_stitch_groups_threads_stddev_pop_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsStddevPopOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ThemesStitchGroupsThreadsStddevSampFields = {
  __typename?: 'themes_stitch_groups_threads_stddev_samp_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsStddevSampOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ThemesStitchGroupsThreadsSumFields = {
  __typename?: 'themes_stitch_groups_threads_sum_fields';
  coverage?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsSumOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** update columns of table "themes_stitch_groups_threads" */
export enum ThemesStitchGroupsThreadsUpdateColumn {
  /** column name */
  Coverage = 'coverage',
  /** column name */
  Id = 'id',
  /** column name */
  StitchGroupId = 'stitch_group_id',
  /** column name */
  ThemeId = 'theme_id',
  /** column name */
  ThreadId = 'thread_id',
}

/** aggregate var_pop on columns */
export type ThemesStitchGroupsThreadsVarPopFields = {
  __typename?: 'themes_stitch_groups_threads_var_pop_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsVarPopOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ThemesStitchGroupsThreadsVarSampFields = {
  __typename?: 'themes_stitch_groups_threads_var_samp_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsVarSampOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ThemesStitchGroupsThreadsVarianceFields = {
  __typename?: 'themes_stitch_groups_threads_variance_fields';
  coverage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "themes_stitch_groups_threads" */
export type ThemesStitchGroupsThreadsVarianceOrderBy = {
  coverage?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type ThemesSumFields = {
  __typename?: 'themes_sum_fields';
  fabric_count?: Maybe<Scalars['Int']>;
  over?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "themes" */
export type ThemesSumOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** update columns of table "themes" */
export enum ThemesUpdateColumn {
  /** column name */
  BackgroundFileId = 'background_file_id',
  /** column name */
  ChartId = 'chart_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FabricColor = 'fabric_color',
  /** column name */
  FabricCount = 'fabric_count',
  /** column name */
  FabricMargin = 'fabric_margin',
  /** column name */
  FabricPadding = 'fabric_padding',
  /** column name */
  FabricTypeId = 'fabric_type_id',
  /** column name */
  FinishColor = 'finish_color',
  /** column name */
  FinishSize = 'finish_size',
  /** column name */
  FinishTypeId = 'finish_type_id',
  /** column name */
  ForegroundFileId = 'foreground_file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsPublished = 'is_published',
  /** column name */
  MottlingColor = 'mottling_color',
  /** column name */
  MottlingTypeId = 'mottling_type_id',
  /** column name */
  Name = 'name',
  /** column name */
  Over = 'over',
  /** column name */
  ParentThemeId = 'parent_theme_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** aggregate var_pop on columns */
export type ThemesVarPopFields = {
  __typename?: 'themes_var_pop_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "themes" */
export type ThemesVarPopOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ThemesVarSampFields = {
  __typename?: 'themes_var_samp_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "themes" */
export type ThemesVarSampOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type ThemesVarianceFields = {
  __typename?: 'themes_variance_fields';
  fabric_count?: Maybe<Scalars['Float']>;
  over?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "themes" */
export type ThemesVarianceOrderBy = {
  fabric_count?: Maybe<OrderBy>;
  over?: Maybe<OrderBy>;
};

/** columns and relationships of "threads" */
export type Threads = {
  __typename?: 'threads';
  /** An object relationship */
  brand_color: BrandColors;
  brand_color_id: Scalars['uuid'];
  /** An object relationship */
  brand_line: BrandLines;
  brand_line_id: Scalars['uuid'];
  /** A computed field, executes function "thread_color" */
  color: Scalars['_rgb_int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_discontinued: Scalars['Boolean'];
  /** A computed field, executes function "thread_name" */
  name?: Maybe<Scalars['String']>;
  name_override?: Maybe<Scalars['String']>;
  /** A computed field, executes function "thread_reference_id" */
  reference_id: Scalars['String'];
  reference_id_override?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "threads" */
export type ThreadsColorArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "threads" */
export type ThreadsAggregate = {
  __typename?: 'threads_aggregate';
  aggregate?: Maybe<ThreadsAggregateFields>;
  nodes: Array<Threads>;
};

/** aggregate fields of "threads" */
export type ThreadsAggregateFields = {
  __typename?: 'threads_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<ThreadsMaxFields>;
  min?: Maybe<ThreadsMinFields>;
};

/** aggregate fields of "threads" */
export type ThreadsAggregateFieldsCountArgs = {
  columns?: Maybe<Array<ThreadsSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "threads" */
export type ThreadsAggregateOrderBy = {
  count?: Maybe<OrderBy>;
  max?: Maybe<ThreadsMaxOrderBy>;
  min?: Maybe<ThreadsMinOrderBy>;
};

/** input type for inserting array relation for remote table "threads" */
export type ThreadsArrRelInsertInput = {
  data: Array<ThreadsInsertInput>;
  on_conflict?: Maybe<ThreadsOnConflict>;
};

/** Boolean expression to filter rows from the table "threads". All fields are combined with a logical 'AND'. */
export type ThreadsBoolExp = {
  _and?: Maybe<Array<Maybe<ThreadsBoolExp>>>;
  _not?: Maybe<ThreadsBoolExp>;
  _or?: Maybe<Array<Maybe<ThreadsBoolExp>>>;
  brand_color?: Maybe<BrandColorsBoolExp>;
  brand_color_id?: Maybe<UuidComparisonExp>;
  brand_line?: Maybe<BrandLinesBoolExp>;
  brand_line_id?: Maybe<UuidComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_discontinued?: Maybe<BooleanComparisonExp>;
  name_override?: Maybe<StringComparisonExp>;
  reference_id_override?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "threads" */
export enum ThreadsConstraint {
  /** unique or primary key constraint */
  ThreadsBrandLineIdBrandColorIdKey = 'threads_brand_line_id_brand_color_id_key',
  /** unique or primary key constraint */
  ThreadsPkey = 'threads_pkey',
}

/** input type for inserting data into table "threads" */
export type ThreadsInsertInput = {
  brand_color?: Maybe<BrandColorsObjRelInsertInput>;
  brand_color_id?: Maybe<Scalars['uuid']>;
  brand_line?: Maybe<BrandLinesObjRelInsertInput>;
  brand_line_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_discontinued?: Maybe<Scalars['Boolean']>;
  name_override?: Maybe<Scalars['String']>;
  reference_id_override?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type ThreadsMaxFields = {
  __typename?: 'threads_max_fields';
  brand_color_id?: Maybe<Scalars['uuid']>;
  brand_line_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_override?: Maybe<Scalars['String']>;
  reference_id_override?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "threads" */
export type ThreadsMaxOrderBy = {
  brand_color_id?: Maybe<OrderBy>;
  brand_line_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name_override?: Maybe<OrderBy>;
  reference_id_override?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type ThreadsMinFields = {
  __typename?: 'threads_min_fields';
  brand_color_id?: Maybe<Scalars['uuid']>;
  brand_line_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name_override?: Maybe<Scalars['String']>;
  reference_id_override?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "threads" */
export type ThreadsMinOrderBy = {
  brand_color_id?: Maybe<OrderBy>;
  brand_line_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  name_override?: Maybe<OrderBy>;
  reference_id_override?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "threads" */
export type ThreadsMutationResponse = {
  __typename?: 'threads_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Threads>;
};

/** input type for inserting object relation for remote table "threads" */
export type ThreadsObjRelInsertInput = {
  data: ThreadsInsertInput;
  on_conflict?: Maybe<ThreadsOnConflict>;
};

/** on conflict condition type for table "threads" */
export type ThreadsOnConflict = {
  constraint: ThreadsConstraint;
  update_columns: Array<ThreadsUpdateColumn>;
  where?: Maybe<ThreadsBoolExp>;
};

/** ordering options when selecting data from "threads" */
export type ThreadsOrderBy = {
  brand_color?: Maybe<BrandColorsOrderBy>;
  brand_color_id?: Maybe<OrderBy>;
  brand_line?: Maybe<BrandLinesOrderBy>;
  brand_line_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_discontinued?: Maybe<OrderBy>;
  name_override?: Maybe<OrderBy>;
  reference_id_override?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "threads" */
export type ThreadsPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "threads" */
export enum ThreadsSelectColumn {
  /** column name */
  BrandColorId = 'brand_color_id',
  /** column name */
  BrandLineId = 'brand_line_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDiscontinued = 'is_discontinued',
  /** column name */
  NameOverride = 'name_override',
  /** column name */
  ReferenceIdOverride = 'reference_id_override',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "threads" */
export type ThreadsSetInput = {
  brand_color_id?: Maybe<Scalars['uuid']>;
  brand_line_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  is_discontinued?: Maybe<Scalars['Boolean']>;
  name_override?: Maybe<Scalars['String']>;
  reference_id_override?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "threads" */
export enum ThreadsUpdateColumn {
  /** column name */
  BrandColorId = 'brand_color_id',
  /** column name */
  BrandLineId = 'brand_line_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsDiscontinued = 'is_discontinued',
  /** column name */
  NameOverride = 'name_override',
  /** column name */
  ReferenceIdOverride = 'reference_id_override',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  auth_codes: Array<AuthCodes>;
  /** An aggregated array relationship */
  auth_codes_aggregate: AuthCodesAggregate;
  contact_id?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['citext'];
  failed_login_attempts: Scalars['Int'];
  id: Scalars['uuid'];
  is_admin: Scalars['Boolean'];
  is_subscribed: Scalars['Boolean'];
  list_recipient_id?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  orders: Array<Orders>;
  /** An aggregated array relationship */
  orders_aggregate: OrdersAggregate;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "users" */
export type UsersAuthCodesArgs = {
  distinct_on?: Maybe<Array<AuthCodesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthCodesOrderBy>>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** columns and relationships of "users" */
export type UsersAuthCodesAggregateArgs = {
  distinct_on?: Maybe<Array<AuthCodesSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<AuthCodesOrderBy>>;
  where?: Maybe<AuthCodesBoolExp>;
};

/** columns and relationships of "users" */
export type UsersOrdersArgs = {
  distinct_on?: Maybe<Array<OrdersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersOrderBy>>;
  where?: Maybe<OrdersBoolExp>;
};

/** columns and relationships of "users" */
export type UsersOrdersAggregateArgs = {
  distinct_on?: Maybe<Array<OrdersSelectColumn>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<OrdersOrderBy>>;
  where?: Maybe<OrdersBoolExp>;
};

/** aggregated selection of "users" */
export type UsersAggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<UsersAggregateFields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type UsersAggregateFields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<UsersAvgFields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UsersMaxFields>;
  min?: Maybe<UsersMinFields>;
  stddev?: Maybe<UsersStddevFields>;
  stddev_pop?: Maybe<UsersStddevPopFields>;
  stddev_samp?: Maybe<UsersStddevSampFields>;
  sum?: Maybe<UsersSumFields>;
  var_pop?: Maybe<UsersVarPopFields>;
  var_samp?: Maybe<UsersVarSampFields>;
  variance?: Maybe<UsersVarianceFields>;
};

/** aggregate fields of "users" */
export type UsersAggregateFieldsCountArgs = {
  columns?: Maybe<Array<UsersSelectColumn>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type UsersAggregateOrderBy = {
  avg?: Maybe<UsersAvgOrderBy>;
  count?: Maybe<OrderBy>;
  max?: Maybe<UsersMaxOrderBy>;
  min?: Maybe<UsersMinOrderBy>;
  stddev?: Maybe<UsersStddevOrderBy>;
  stddev_pop?: Maybe<UsersStddevPopOrderBy>;
  stddev_samp?: Maybe<UsersStddevSampOrderBy>;
  sum?: Maybe<UsersSumOrderBy>;
  var_pop?: Maybe<UsersVarPopOrderBy>;
  var_samp?: Maybe<UsersVarSampOrderBy>;
  variance?: Maybe<UsersVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "users" */
export type UsersArrRelInsertInput = {
  data: Array<UsersInsertInput>;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** aggregate avg on columns */
export type UsersAvgFields = {
  __typename?: 'users_avg_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type UsersAvgOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type UsersBoolExp = {
  _and?: Maybe<Array<Maybe<UsersBoolExp>>>;
  _not?: Maybe<UsersBoolExp>;
  _or?: Maybe<Array<Maybe<UsersBoolExp>>>;
  auth_codes?: Maybe<AuthCodesBoolExp>;
  contact_id?: Maybe<StringComparisonExp>;
  created_at?: Maybe<TimestamptzComparisonExp>;
  email?: Maybe<CitextComparisonExp>;
  failed_login_attempts?: Maybe<IntComparisonExp>;
  id?: Maybe<UuidComparisonExp>;
  is_admin?: Maybe<BooleanComparisonExp>;
  is_subscribed?: Maybe<BooleanComparisonExp>;
  list_recipient_id?: Maybe<StringComparisonExp>;
  locked_at?: Maybe<TimestamptzComparisonExp>;
  name?: Maybe<StringComparisonExp>;
  orders?: Maybe<OrdersBoolExp>;
  stripe_customer_id?: Maybe<StringComparisonExp>;
  updated_at?: Maybe<TimestamptzComparisonExp>;
};

/** unique or primary key constraints on table "users" */
export enum UsersConstraint {
  /** unique or primary key constraint */
  UsersContactIdKey = 'users_contact_id_key',
  /** unique or primary key constraint */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint */
  UsersListRecipientIdKey = 'users_list_recipient_id_key',
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint */
  UsersStripeCustomerIdKey = 'users_stripe_customer_id_key',
}

/** input type for incrementing integer column in table "users" */
export type UsersIncInput = {
  failed_login_attempts?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "users" */
export type UsersInsertInput = {
  auth_codes?: Maybe<AuthCodesArrRelInsertInput>;
  contact_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  failed_login_attempts?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  is_admin?: Maybe<Scalars['Boolean']>;
  is_subscribed?: Maybe<Scalars['Boolean']>;
  list_recipient_id?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  orders?: Maybe<OrdersArrRelInsertInput>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type UsersMaxFields = {
  __typename?: 'users_max_fields';
  contact_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  failed_login_attempts?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  list_recipient_id?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type UsersMaxOrderBy = {
  contact_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  failed_login_attempts?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  list_recipient_id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  stripe_customer_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** aggregate min on columns */
export type UsersMinFields = {
  __typename?: 'users_min_fields';
  contact_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  failed_login_attempts?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  list_recipient_id?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type UsersMinOrderBy = {
  contact_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  failed_login_attempts?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  list_recipient_id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  stripe_customer_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** response of any mutation on the table "users" */
export type UsersMutationResponse = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type UsersObjRelInsertInput = {
  data: UsersInsertInput;
  on_conflict?: Maybe<UsersOnConflict>;
};

/** on conflict condition type for table "users" */
export type UsersOnConflict = {
  constraint: UsersConstraint;
  update_columns: Array<UsersUpdateColumn>;
  where?: Maybe<UsersBoolExp>;
};

/** ordering options when selecting data from "users" */
export type UsersOrderBy = {
  auth_codes_aggregate?: Maybe<AuthCodesAggregateOrderBy>;
  contact_id?: Maybe<OrderBy>;
  created_at?: Maybe<OrderBy>;
  email?: Maybe<OrderBy>;
  failed_login_attempts?: Maybe<OrderBy>;
  id?: Maybe<OrderBy>;
  is_admin?: Maybe<OrderBy>;
  is_subscribed?: Maybe<OrderBy>;
  list_recipient_id?: Maybe<OrderBy>;
  locked_at?: Maybe<OrderBy>;
  name?: Maybe<OrderBy>;
  orders_aggregate?: Maybe<OrdersAggregateOrderBy>;
  stripe_customer_id?: Maybe<OrderBy>;
  updated_at?: Maybe<OrderBy>;
};

/** primary key columns input for table: "users" */
export type UsersPkColumnsInput = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum UsersSelectColumn {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FailedLoginAttempts = 'failed_login_attempts',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsSubscribed = 'is_subscribed',
  /** column name */
  ListRecipientId = 'list_recipient_id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "users" */
export type UsersSetInput = {
  contact_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['citext']>;
  failed_login_attempts?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  is_admin?: Maybe<Scalars['Boolean']>;
  is_subscribed?: Maybe<Scalars['Boolean']>;
  list_recipient_id?: Maybe<Scalars['String']>;
  locked_at?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type UsersStddevFields = {
  __typename?: 'users_stddev_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type UsersStddevOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type UsersStddevPopFields = {
  __typename?: 'users_stddev_pop_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type UsersStddevPopOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type UsersStddevSampFields = {
  __typename?: 'users_stddev_samp_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type UsersStddevSampOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** aggregate sum on columns */
export type UsersSumFields = {
  __typename?: 'users_sum_fields';
  failed_login_attempts?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "users" */
export type UsersSumOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** update columns of table "users" */
export enum UsersUpdateColumn {
  /** column name */
  ContactId = 'contact_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  FailedLoginAttempts = 'failed_login_attempts',
  /** column name */
  Id = 'id',
  /** column name */
  IsAdmin = 'is_admin',
  /** column name */
  IsSubscribed = 'is_subscribed',
  /** column name */
  ListRecipientId = 'list_recipient_id',
  /** column name */
  LockedAt = 'locked_at',
  /** column name */
  Name = 'name',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** aggregate var_pop on columns */
export type UsersVarPopFields = {
  __typename?: 'users_var_pop_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type UsersVarPopOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type UsersVarSampFields = {
  __typename?: 'users_var_samp_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type UsersVarSampOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** aggregate variance on columns */
export type UsersVarianceFields = {
  __typename?: 'users_variance_fields';
  failed_login_attempts?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type UsersVarianceOrderBy = {
  failed_login_attempts?: Maybe<OrderBy>;
};

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type AccountHomeQueryVariables = Exact<{ [key: string]: never }>;

export type AccountHomeQuery = { __typename?: 'query_root' } & {
  users: Array<
    { __typename?: 'users' } & Pick<Users, 'id' | 'created_at' | 'name'>
  >;
  projects: Array<
    { __typename?: 'projects' } & Pick<Projects, 'id'> &
      AccountProjectListItemFragment
  >;
  charts_without_projects: Array<
    { __typename?: 'charts' } & Pick<Charts, 'id'> &
      AccountChartProductListItemFragment
  >;
};

export type AccountChartProductListItemFragment = {
  __typename?: 'charts';
} & Pick<Charts, 'id'> & {
    product?: Maybe<
      { __typename?: 'products' } & Pick<Products, 'id' | 'name'> & {
          chart_public?: Maybe<
            { __typename?: 'charts_public' } & Pick<
              ChartsPublic,
              'id' | 'height' | 'width'
            >
          >;
          shop: { __typename?: 'shops' } & Pick<
            Shops,
            'id' | 'color' | 'name'
          > & {
              icon_file: { __typename?: 'files' } & Pick<
                Files,
                'id' | 's3_url'
              >;
            };
          themes_public: Array<
            { __typename?: 'themes_public' } & Pick<ThemesPublic, 'id'> &
              ThemeImageThemePublicFragment
          >;
        }
    >;
  };

export type AccountProjectListItemFragment = { __typename?: 'projects' } & Pick<
  Projects,
  'id' | 'name'
> & {
    theme: { __typename?: 'themes' } & Pick<
      Themes,
      | 'id'
      | 'fabric_color'
      | 'fabric_count'
      | 'fabric_margin'
      | 'fabric_padding'
      | 'finish_color'
      | 'finish_type_id'
      | 'name'
      | 'over'
    > & {
        chart: { __typename?: 'charts' } & Pick<
          Charts,
          'id' | 'height' | 'width'
        >;
        product?: Maybe<
          { __typename?: 'products' } & Pick<Products, 'id'> & {
              shop: { __typename?: 'shops' } & Pick<
                Shops,
                'id' | 'color' | 'name'
              > & {
                  icon_file: { __typename?: 'files' } & Pick<
                    Files,
                    'id' | 's3_url'
                  >;
                };
            }
        >;
      };
  };

export type AllCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllCollectionsQuery = { __typename?: 'query_root' } & {
  collections: Array<
    { __typename?: 'collections' } & Pick<Collections, 'id' | 'slug'>
  >;
};

export type AllFreeProductsQueryVariables = Exact<{ [key: string]: never }>;

export type AllFreeProductsQuery = { __typename?: 'query_root' } & {
  products: Array<{ __typename?: 'products' } & Pick<Products, 'id' | 'slug'>>;
};

export type AllProductsQueryVariables = Exact<{ [key: string]: never }>;

export type AllProductsQuery = { __typename?: 'query_root' } & {
  products: Array<{ __typename?: 'products' } & Pick<Products, 'id' | 'slug'>>;
};

export type AllShopsQueryVariables = Exact<{ [key: string]: never }>;

export type AllShopsQuery = { __typename?: 'query_root' } & {
  shops: Array<{ __typename?: 'shops' } & Pick<Shops, 'id' | 'slug'>>;
};

export type AllTagsQueryVariables = Exact<{ [key: string]: never }>;

export type AllTagsQuery = { __typename?: 'query_root' } & {
  tags: Array<{ __typename?: 'tags' } & Pick<Tags, 'id' | 'slug'>>;
};

export type BasketProductFragment = { __typename?: 'products' } & Pick<
  Products,
  'id' | 'currency' | 'name' | 'price' | 'slug'
> & {
    shop: { __typename?: 'shops' } & Pick<Shops, 'id' | 'name'>;
    chart_public?: Maybe<
      { __typename?: 'charts_public' } & Pick<
        ChartsPublic,
        'id' | 'height' | 'width'
      >
    >;
    themes_public: Array<
      { __typename?: 'themes_public' } & Pick<ThemesPublic, 'id'> &
        ThemeImageThemePublicFragment
    >;
  };

export type BasketProductsForIdsQueryVariables = Exact<{
  ids: Array<Scalars['uuid']>;
}>;

export type BasketProductsForIdsQuery = { __typename?: 'query_root' } & {
  products: Array<
    { __typename?: 'products' } & Pick<Products, 'id'> & BasketProductFragment
  >;
};

export type BrandLinesQueryVariables = Exact<{ [key: string]: never }>;

export type BrandLinesQuery = { __typename?: 'query_root' } & {
  brand_lines: Array<
    { __typename?: 'brand_lines' } & Pick<
      BrandLines,
      'id' | 'name' | 'brand_name'
    >
  >;
};

export type CancelPaymentIntentMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CancelPaymentIntentMutation = { __typename?: 'mutation_root' } & {
  cancelPaymentIntent: { __typename?: 'BooleanResponse' } & Pick<
    BooleanResponse,
    'success'
  >;
};

export type ChartStitchGroupFragment = { __typename?: 'stitch_groups' } & Pick<
  StitchGroups,
  'id' | 'lines' | 'points' | 'type'
>;

export type ChartThemesStitchGroupsThreadsFragment = {
  __typename?: 'themes_stitch_groups_threads';
} & Pick<ThemesStitchGroupsThreads, 'id' | 'coverage'> & {
    thread: { __typename?: 'threads' } & Pick<Threads, 'id'> &
      ChartThreadFragment;
    stitch_group: { __typename?: 'stitch_groups' } & Pick<StitchGroups, 'id'> &
      ChartStitchGroupFragment;
  };

export type ChartThreadFragment = { __typename?: 'threads' } & Pick<
  Threads,
  'id' | 'color'
>;

export type CheckEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type CheckEmailQuery = { __typename?: 'query_root' } & {
  checkEmail: { __typename?: 'CheckEmailResponse' } & Pick<
    CheckEmailResponse,
    'exists'
  >;
};

export type CheckSubscriptionStatusMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CheckSubscriptionStatusMutation = {
  __typename?: 'mutation_root';
} & {
  checkSubscriptionStatus: { __typename?: 'BooleanResponse' } & Pick<
    BooleanResponse,
    'success'
  >;
};

export type ClaimProductsMutationVariables = Exact<{
  productIds: Array<Scalars['String']>;
}>;

export type ClaimProductsMutation = { __typename?: 'mutation_root' } & {
  claimProducts: { __typename?: 'BooleanResponse' } & Pick<
    BooleanResponse,
    'success'
  >;
};

export type CollectionForSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type CollectionForSlugQuery = { __typename?: 'query_root' } & {
  collections: Array<
    { __typename?: 'collections' } & Pick<
      Collections,
      'id' | 'name' | 'slug'
    > & {
        products_collections: Array<
          { __typename?: 'products_collections' } & Pick<
            ProductsCollections,
            'id'
          > & {
              product: { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment;
            }
        >;
      }
  >;
};

export type CollectionProductFragment = { __typename?: 'products' } & Pick<
  Products,
  'id' | 'currency' | 'name' | 'price' | 'slug'
> & {
    shop: { __typename?: 'shops' } & Pick<
      Shops,
      'id' | 'color' | 'name' | 'slug'
    > & { icon_file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'> };
    chart_public?: Maybe<
      { __typename?: 'charts_public' } & Pick<
        ChartsPublic,
        'id' | 'height' | 'width'
      >
    >;
    themes_public: Array<
      { __typename?: 'themes_public' } & Pick<ThemesPublic, 'id' | 'name'> & {
          themes_stitch_groups_threads: Array<
            { __typename?: 'themes_stitch_groups_threads' } & Pick<
              ThemesStitchGroupsThreads,
              'id'
            > &
              PublicThemeThemesStitchGroupsThreadsFragment
          >;
        } & ThemeImageThemePublicFragment
    >;
  };

export type CompleteOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type CompleteOrdersQuery = { __typename?: 'query_root' } & {
  orders: Array<
    { __typename?: 'orders' } & Pick<Orders, 'id' | 'amount' | 'created_at'> & {
        items: Array<
          { __typename?: 'orders_items' } & Pick<OrdersItems, 'id'> &
            OrderPageOrderItemFragment
        >;
        items_aggregate: { __typename?: 'orders_items_aggregate' } & {
          aggregate?: Maybe<
            { __typename?: 'orders_items_aggregate_fields' } & Pick<
              OrdersItemsAggregateFields,
              'count'
            >
          >;
        };
      }
  >;
};

export type CompleteOrdersForProductQueryVariables = Exact<{
  productId?: Maybe<Scalars['uuid']>;
}>;

export type CompleteOrdersForProductQuery = { __typename?: 'query_root' } & {
  orders: Array<{ __typename?: 'orders' } & Pick<Orders, 'id'>>;
};

export type CreateFirstTimeSessionMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type CreateFirstTimeSessionMutation = {
  __typename?: 'mutation_root';
} & {
  createFirstTimeSession: { __typename?: 'UserResponse' } & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'email'>;
  };
};

export type CreatePaymentIntentMutationVariables = Exact<{
  products: Array<ProductInput>;
}>;

export type CreatePaymentIntentMutation = { __typename?: 'mutation_root' } & {
  createPaymentIntent: { __typename?: 'CreatePaymentIntentResponse' } & Pick<
    CreatePaymentIntentResponse,
    'clientSecret' | 'orderId'
  >;
};

export type CreateProjectMutationVariables = Exact<{
  project: ProjectsInsertInput;
}>;

export type CreateProjectMutation = { __typename?: 'mutation_root' } & {
  insert_projects?: Maybe<
    { __typename?: 'projects_mutation_response' } & {
      returning: Array<{ __typename?: 'projects' } & Pick<Projects, 'id'>>;
    }
  >;
};

export type CurrentUserRoleQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserRoleQuery = { __typename?: 'query_root' } & {
  currentUserRole: { __typename?: 'CurrentUserRoleResponse' } & Pick<
    CurrentUserRoleResponse,
    'role'
  >;
};

export type EndSessionMutationVariables = Exact<{ [key: string]: never }>;

export type EndSessionMutation = { __typename?: 'mutation_root' } & {
  endSession: { __typename?: 'BooleanResponse' } & Pick<
    BooleanResponse,
    'success'
  >;
};

export type ExploreShopsShopFragment = { __typename?: 'shops' } & Pick<
  Shops,
  'id' | 'name' | 'color' | 'slug'
> & {
    background_file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
    icon_file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
  };

export type FabricTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FabricTypesQuery = { __typename?: 'query_root' } & {
  fabric_types: Array<
    { __typename?: 'fabric_types' } & Pick<
      FabricTypes,
      'id' | 'name' | 'supports_fractional_stitches'
    > & { file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'> }
  >;
};

export type FinishTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FinishTypesQuery = { __typename?: 'query_root' } & {
  finish_types: Array<
    { __typename?: 'finish_types' } & Pick<FinishTypes, 'id' | 'name'> & {
        file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
      }
  >;
};

export type HomeQueryVariables = Exact<{ [key: string]: never }>;

export type HomeQuery = { __typename?: 'query_root' } & {
  free_products: Array<
    { __typename?: 'products' } & Pick<Products, 'id'> &
      CollectionProductFragment
  >;
  featured_collection_1: Array<
    { __typename?: 'collections' } & Pick<Collections, 'id' | 'name'> & {
        products_collections: Array<
          { __typename?: 'products_collections' } & Pick<
            ProductsCollections,
            'id'
          > & {
              product: { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment;
            }
        >;
      }
  >;
  featured_collection_2: Array<
    { __typename?: 'collections' } & Pick<Collections, 'id' | 'name'> & {
        products_collections: Array<
          { __typename?: 'products_collections' } & Pick<
            ProductsCollections,
            'id'
          > & {
              product: { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment;
            }
        >;
      }
  >;
  featured_collection_3: Array<
    { __typename?: 'collections' } & Pick<Collections, 'id' | 'name'> & {
        products_collections: Array<
          { __typename?: 'products_collections' } & Pick<
            ProductsCollections,
            'id'
          > & {
              product: { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment;
            }
        >;
      }
  >;
  featured_shops: Array<
    { __typename?: 'shops' } & Pick<Shops, 'id'> & {
        products: Array<
          { __typename?: 'products' } & Pick<Products, 'id'> &
            CollectionProductFragment
        >;
      } & ExploreShopsShopFragment
  >;
  explore_shops: Array<
    { __typename?: 'shops' } & Pick<Shops, 'id'> & ExploreShopsShopFragment
  >;
  tags: Array<
    { __typename?: 'tags' } & Pick<Tags, 'id' | 'name' | 'slug' | 'color'>
  >;
};

export type HowItWorksQueryVariables = Exact<{ [key: string]: never }>;

export type HowItWorksQuery = { __typename?: 'query_root' } & {
  free_products: Array<
    { __typename?: 'products' } & Pick<Products, 'id'> &
      CollectionProductFragment
  >;
  collections: Array<
    { __typename?: 'collections' } & Pick<Collections, 'id'> & {
        products_collections: Array<
          { __typename?: 'products_collections' } & Pick<
            ProductsCollections,
            'id'
          > & {
              product: { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment;
            }
        >;
      }
  >;
};

export type IncompleteOrderQueryVariables = Exact<{ [key: string]: never }>;

export type IncompleteOrderQuery = { __typename?: 'query_root' } & {
  orders: Array<
    { __typename?: 'orders' } & Pick<Orders, 'id'> & {
        items: Array<
          { __typename?: 'orders_items' } & Pick<OrdersItems, 'id'> & {
              product: { __typename?: 'products' } & Pick<
                Products,
                'id' | 'currency' | 'price'
              >;
            }
        >;
      }
  >;
};

export type MottlingTypesQueryVariables = Exact<{ [key: string]: never }>;

export type MottlingTypesQuery = { __typename?: 'query_root' } & {
  mottling_types: Array<
    { __typename?: 'mottling_types' } & Pick<MottlingTypes, 'id' | 'name'> & {
        file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
      }
  >;
};

export type OrderQueryVariables = Exact<{
  orderId: Scalars['uuid'];
}>;

export type OrderQuery = { __typename?: 'query_root' } & {
  orders_by_pk?: Maybe<
    { __typename?: 'orders' } & Pick<
      Orders,
      'id' | 'amount' | 'created_at' | 'status'
    > & {
        items: Array<
          { __typename?: 'orders_items' } & Pick<OrdersItems, 'id'> &
            OrderPageOrderItemFragment
        >;
      }
  >;
};

export type OrderPageOrderItemFragment = { __typename?: 'orders_items' } & Pick<
  OrdersItems,
  'id' | 'amount'
> & {
    product: { __typename?: 'products' } & Pick<
      Products,
      'id' | 'name' | 'slug'
    > & {
        shop: { __typename?: 'shops' } & Pick<Shops, 'id' | 'name'>;
        chart_public?: Maybe<
          { __typename?: 'charts_public' } & Pick<
            ChartsPublic,
            'id' | 'height' | 'width'
          >
        >;
        themes_public: Array<
          { __typename?: 'themes_public' } & Pick<ThemesPublic, 'id'> &
            ThemeImageThemePublicFragment
        >;
      };
  };

export type BasketPaymentMethodFragment = {
  __typename?: 'PaymentMethod';
} & Pick<PaymentMethod, 'id' | 'brand' | 'last4' | 'expMonth' | 'expYear'>;

export type PaymentMethodsQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodsQuery = { __typename?: 'query_root' } & {
  paymentMethods: Array<
    { __typename?: 'PaymentMethod' } & Pick<PaymentMethod, 'id'> &
      BasketPaymentMethodFragment
  >;
};

export type AccountThemeListItemFragment = { __typename?: 'themes' } & Pick<
  Themes,
  | 'id'
  | 'fabric_color'
  | 'fabric_count'
  | 'fabric_margin'
  | 'fabric_padding'
  | 'finish_color'
  | 'finish_type_id'
  | 'name'
  | 'over'
> & {
    background_file?: Maybe<
      { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>
    >;
    foreground_file?: Maybe<
      { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>
    >;
  };

export type ProductAndThemesForProductIdQueryVariables = Exact<{
  productId: Scalars['uuid'];
}>;

export type ProductAndThemesForProductIdQuery = {
  __typename?: 'query_root';
} & {
  products_by_pk?: Maybe<
    { __typename?: 'products' } & Pick<Products, 'id' | 'name'> & {
        chart: { __typename?: 'charts' } & Pick<
          Charts,
          'id' | 'height' | 'width'
        >;
      }
  >;
  themes: Array<
    { __typename?: 'themes' } & Pick<Themes, 'id'> &
      AccountThemeListItemFragment
  >;
};

export type ProductForSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ProductForSlugQuery = { __typename?: 'query_root' } & {
  products: Array<
    { __typename?: 'products' } & Pick<
      Products,
      'id' | 'currency' | 'description' | 'name' | 'price' | 'slug'
    > & {
        images: Array<
          { __typename?: 'products_images' } & Pick<ProductsImages, 'id'> & {
              file: { __typename?: 'files' } & Pick<
                Files,
                'id' | 'name' | 's3_url'
              >;
            }
        >;
        products_tags: Array<
          { __typename?: 'products_tags' } & Pick<ProductsTags, 'id'> & {
              tag: { __typename?: 'tags' } & Pick<
                Tags,
                'id' | 'color' | 'name' | 'slug'
              >;
            }
        >;
        chart: { __typename?: 'charts' } & Pick<
          Charts,
          'id' | 'has_fractional_stitches' | 'height' | 'width'
        >;
        themes_public: Array<
          { __typename?: 'themes_public' } & Pick<
            ThemesPublic,
            'id' | 'name'
          > & {
              themes_stitch_groups_threads: Array<
                { __typename?: 'themes_stitch_groups_threads' } & Pick<
                  ThemesStitchGroupsThreads,
                  'id'
                > &
                  PublicThemeThemesStitchGroupsThreadsFragment
              >;
            } & ThemeImageThemePublicFragment
        >;
        shop: { __typename?: 'shops' } & Pick<
          Shops,
          'id' | 'color' | 'name' | 'slug'
        > & {
            background_file: { __typename?: 'files' } & Pick<
              Files,
              'id' | 's3_url'
            >;
            icon_file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
            products: Array<
              { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment
            >;
          };
      }
  >;
};

export type ProjectAndThemeQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ProjectAndThemeQuery = { __typename?: 'query_root' } & {
  projects_by_pk?: Maybe<
    { __typename?: 'projects' } & Pick<
      Projects,
      'id' | 'name' | 'created_at'
    > & {
        theme: { __typename?: 'themes' } & Pick<Themes, 'id'> &
          ProjectThemeFragment;
      }
  >;
};

export type ProjectAndThemeStitchGroupFragment = {
  __typename?: 'stitch_groups';
} & Pick<StitchGroups, 'id' | 'amount'> &
  ChartStitchGroupFragment;

export type ProjectAndThemeThemesStitchGroupsThreadsFragment = {
  __typename?: 'themes_stitch_groups_threads';
} & Pick<ThemesStitchGroupsThreads, 'id' | 'coverage'> & {
    thread: { __typename?: 'threads' } & Pick<Threads, 'id'> &
      ProjectAndThemeThreadFragment;
    stitch_group: { __typename?: 'stitch_groups' } & Pick<StitchGroups, 'id'> &
      ProjectAndThemeStitchGroupFragment;
  };

export type ProjectAndThemeBrandLineFragment = {
  __typename?: 'brand_lines';
} & Pick<BrandLines, 'id' | 'name' | 'brand_name'>;

export type ProjectAndThemeThreadFragment = { __typename?: 'threads' } & Pick<
  Threads,
  'id' | 'color' | 'name' | 'reference_id'
> & {
    brand_line: { __typename?: 'brand_lines' } & Pick<BrandLines, 'id'> &
      ProjectAndThemeBrandLineFragment;
  };

export type ProjectAndThemeFabricTypeFragment = {
  __typename?: 'fabric_types';
} & Pick<FabricTypes, 'id' | 'name' | 'supports_fractional_stitches'> & {
    file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
  };

export type ProjectAndThemeMottlingTypeFragment = {
  __typename?: 'mottling_types';
} & Pick<MottlingTypes, 'id' | 'name'> & {
    file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
  };

export type ProjectAndThemeFinishTypeFragment = {
  __typename?: 'finish_types';
} & Pick<FinishTypes, 'id' | 'name'> & {
    file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
  };

export type ProjectThemeFragment = { __typename?: 'themes' } & Pick<
  Themes,
  | 'id'
  | 'name'
  | 'over'
  | 'fabric_count'
  | 'fabric_margin'
  | 'fabric_padding'
  | 'fabric_color'
  | 'mottling_color'
  | 'finish_color'
  | 'finish_size'
> & {
    parent_theme?: Maybe<
      { __typename?: 'themes' } & Pick<Themes, 'id' | 'name'>
    >;
    fabric_type: { __typename?: 'fabric_types' } & Pick<FabricTypes, 'id'> &
      ProjectAndThemeFabricTypeFragment;
    mottling_type?: Maybe<
      { __typename?: 'mottling_types' } & Pick<MottlingTypes, 'id'> &
        ProjectAndThemeMottlingTypeFragment
    >;
    finish_type?: Maybe<
      { __typename?: 'finish_types' } & Pick<FinishTypes, 'id'> &
        ProjectAndThemeFinishTypeFragment
    >;
    chart: { __typename?: 'charts' } & Pick<
      Charts,
      'id' | 'has_fractional_stitches' | 'height' | 'width'
    > & {
        product?: Maybe<
          { __typename?: 'products' } & Pick<Products, 'id'> & {
              shop: { __typename?: 'shops' } & Pick<
                Shops,
                'id' | 'name' | 'slug'
              >;
            }
        >;
      };
    themes_stitch_groups_threads: Array<
      { __typename?: 'themes_stitch_groups_threads' } & Pick<
        ThemesStitchGroupsThreads,
        'id'
      > &
        ProjectAndThemeThemesStitchGroupsThreadsFragment
    >;
  };

export type ProjectsAndChartsWithoutProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ProjectsAndChartsWithoutProjectsQuery = {
  __typename?: 'query_root';
} & {
  projects: Array<
    { __typename?: 'projects' } & Pick<Projects, 'id'> &
      AccountProjectListItemFragment
  >;
  charts_without_projects: Array<
    { __typename?: 'charts' } & Pick<Charts, 'id'> &
      AccountChartProductListItemFragment
  >;
};

export type PublicThemeStitchGroupFragment = {
  __typename?: 'stitch_groups';
} & Pick<StitchGroups, 'id' | 'amount' | 'type'>;

export type PublicThemeThreadFragment = { __typename?: 'threads' } & Pick<
  Threads,
  'id' | 'color'
> & {
    brand_line: { __typename?: 'brand_lines' } & Pick<BrandLines, 'id'> &
      ProjectAndThemeBrandLineFragment;
  };

export type PublicThemeThemesStitchGroupsThreadsFragment = {
  __typename?: 'themes_stitch_groups_threads';
} & Pick<ThemesStitchGroupsThreads, 'id'> & {
    thread: { __typename?: 'threads' } & Pick<Threads, 'id'> &
      PublicThemeThreadFragment;
    stitch_group: { __typename?: 'stitch_groups' } & Pick<StitchGroups, 'id'> &
      PublicThemeStitchGroupFragment;
  };

export type RefreshOrderStatusMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type RefreshOrderStatusMutation = { __typename?: 'mutation_root' } & {
  refreshOrderStatus: { __typename?: 'BooleanResponse' } & Pick<
    BooleanResponse,
    'success'
  >;
};

export type SendAuthCodeMutationVariables = Exact<{
  email: Scalars['String'];
  intent: Scalars['String'];
}>;

export type SendAuthCodeMutation = { __typename?: 'mutation_root' } & {
  sendAuthCode: { __typename?: 'BooleanResponse' } & Pick<
    BooleanResponse,
    'success'
  >;
};

export type ShopForSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type ShopForSlugQuery = { __typename?: 'query_root' } & {
  shops: Array<
    { __typename?: 'shops' } & Pick<Shops, 'id' | 'name' | 'color'> & {
        background_file: { __typename?: 'files' } & Pick<
          Files,
          'id' | 's3_url'
        >;
        icon_file: { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>;
        products: Array<
          { __typename?: 'products' } & Pick<Products, 'id'> &
            CollectionProductFragment
        >;
      }
  >;
};

export type ShopsQueryVariables = Exact<{ [key: string]: never }>;

export type ShopsQuery = { __typename?: 'query_root' } & {
  shops: Array<
    { __typename?: 'shops' } & Pick<Shops, 'id'> & ExploreShopsShopFragment
  >;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  productIds: Array<Scalars['String']>;
}>;

export type SignUpMutation = { __typename?: 'mutation_root' } & {
  signUp: { __typename?: 'User' } & Pick<User, 'id' | 'email'>;
};

export type TagForSlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;

export type TagForSlugQuery = { __typename?: 'query_root' } & {
  tags: Array<
    { __typename?: 'tags' } & Pick<Tags, 'id' | 'name' | 'color' | 'slug'> & {
        products_tags: Array<
          { __typename?: 'products_tags' } & Pick<ProductsTags, 'id'> & {
              product: { __typename?: 'products' } & Pick<Products, 'id'> &
                CollectionProductFragment;
            }
        >;
      }
  >;
};

export type ThemeForColorResetQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ThemeForColorResetQuery = { __typename?: 'query_root' } & {
  themes_by_pk?: Maybe<
    { __typename?: 'themes' } & Pick<
      Themes,
      | 'id'
      | 'fabric_color'
      | 'fabric_type_id'
      | 'mottling_color'
      | 'mottling_type_id'
      | 'finish_color'
    > & {
        themes_stitch_groups_threads: Array<
          { __typename?: 'themes_stitch_groups_threads' } & Pick<
            ThemesStitchGroupsThreads,
            'id' | 'stitch_group_id' | 'thread_id'
          >
        >;
      }
  >;
};

export type ThemeForIdToCloneQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ThemeForIdToCloneQuery = { __typename?: 'query_root' } & {
  themes_by_pk?: Maybe<
    { __typename?: 'themes' } & Pick<
      Themes,
      | 'id'
      | 'background_file_id'
      | 'chart_id'
      | 'fabric_color'
      | 'fabric_count'
      | 'fabric_margin'
      | 'fabric_padding'
      | 'fabric_type_id'
      | 'finish_color'
      | 'finish_size'
      | 'finish_type_id'
      | 'foreground_file_id'
      | 'mottling_color'
      | 'mottling_type_id'
      | 'name'
      | 'over'
    > & {
        themes_stitch_groups_threads: Array<
          { __typename?: 'themes_stitch_groups_threads' } & Pick<
            ThemesStitchGroupsThreads,
            'id' | 'coverage' | 'thread_id' | 'stitch_group_id'
          >
        >;
      }
  >;
};

export type ThemeForSizeResetQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type ThemeForSizeResetQuery = { __typename?: 'query_root' } & {
  themes_by_pk?: Maybe<
    { __typename?: 'themes' } & Pick<
      Themes,
      | 'id'
      | 'fabric_count'
      | 'fabric_margin'
      | 'fabric_padding'
      | 'fabric_type_id'
      | 'finish_size'
      | 'finish_type_id'
      | 'over'
    > & {
        themes_stitch_groups_threads: Array<
          { __typename?: 'themes_stitch_groups_threads' } & Pick<
            ThemesStitchGroupsThreads,
            'id' | 'coverage' | 'stitch_group_id'
          >
        >;
      }
  >;
};

export type ThemeImageThemePublicFragment = {
  __typename?: 'themes_public';
} & Pick<
  ThemesPublic,
  | 'id'
  | 'fabric_color'
  | 'fabric_count'
  | 'fabric_margin'
  | 'fabric_padding'
  | 'finish_color'
  | 'finish_type_id'
  | 'over'
> & {
    background_file?: Maybe<
      { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>
    >;
    foreground_file?: Maybe<
      { __typename?: 'files' } & Pick<Files, 'id' | 's3_url'>
    >;
  };

export type ThreadsForBrandLineQueryVariables = Exact<{
  brandLineId: Scalars['uuid'];
}>;

export type ThreadsForBrandLineQuery = { __typename?: 'query_root' } & {
  threads: Array<
    { __typename?: 'threads' } & Pick<Threads, 'id'> &
      ProjectAndThemeThreadFragment
  >;
};

export type UpdateThemeMutationVariables = Exact<{
  themeId: Scalars['uuid'];
  themeSetInput: ThemesSetInput;
  themesStitchGroupsThreadsOnConflict?: Maybe<
    ThemesStitchGroupsThreadsOnConflict
  >;
  themesStitchGroupsThreadsInsertInput: Array<
    ThemesStitchGroupsThreadsInsertInput
  >;
}>;

export type UpdateThemeMutation = { __typename?: 'mutation_root' } & {
  insert_themes_stitch_groups_threads?: Maybe<
    { __typename?: 'themes_stitch_groups_threads_mutation_response' } & {
      returning: Array<
        { __typename?: 'themes_stitch_groups_threads' } & Pick<
          ThemesStitchGroupsThreads,
          'id'
        >
      >;
    }
  >;
  update_themes_by_pk?: Maybe<
    { __typename?: 'themes' } & Pick<Themes, 'id'> & ProjectThemeFragment
  >;
};

export type UpdateThemesStitchGroupsThreadsMutationVariables = Exact<{
  ids: Array<Scalars['uuid']>;
  set: ThemesStitchGroupsThreadsSetInput;
}>;

export type UpdateThemesStitchGroupsThreadsMutation = {
  __typename?: 'mutation_root';
} & {
  update_themes_stitch_groups_threads?: Maybe<
    { __typename?: 'themes_stitch_groups_threads_mutation_response' } & {
      returning: Array<
        { __typename?: 'themes_stitch_groups_threads' } & Pick<
          ThemesStitchGroupsThreads,
          'id'
        > &
          ProjectAndThemeThemesStitchGroupsThreadsFragment
      >;
    }
  >;
};

export type UpdateUserFromSettingsMutationVariables = Exact<{
  id: Scalars['uuid'];
  props: UsersSetInput;
}>;

export type UpdateUserFromSettingsMutation = {
  __typename?: 'mutation_root';
} & {
  update_users_by_pk?: Maybe<
    { __typename?: 'users' } & Pick<Users, 'id' | 'email' | 'is_subscribed'>
  >;
};

export type UserQueryVariables = Exact<{ [key: string]: never }>;

export type UserQuery = { __typename?: 'query_root' } & {
  users: Array<
    { __typename?: 'users' } & Pick<
      Users,
      'id' | 'created_at' | 'email' | 'is_subscribed' | 'name'
    >
  >;
};

export type VerifyAuthCodeMutationVariables = Exact<{
  code: Scalars['Int'];
  email: Scalars['String'];
}>;

export type VerifyAuthCodeMutation = { __typename?: 'mutation_root' } & {
  verifyAuthCode: { __typename?: 'VerifyAuthCodeResponse' } & Pick<
    VerifyAuthCodeResponse,
    'intent'
  > & { user: { __typename?: 'User' } & Pick<User, 'id' | 'email'> };
};

export const ThemeImageThemePublicFragmentDoc: DocumentNode<
  ThemeImageThemePublicFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AccountChartProductListItemFragmentDoc: DocumentNode<
  AccountChartProductListItemFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ChartProductListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'charts' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_public' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'color' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon_file' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_url' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AccountProjectListItemFragmentDoc: DocumentNode<
  AccountProjectListItemFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ProjectListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'projects' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_count' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_margin' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_padding' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'over' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'icon_file' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 's3_url' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const BasketProductFragmentDoc: DocumentNode<
  BasketProductFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Basket_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ChartThreadFragmentDoc: DocumentNode<
  ChartThreadFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ChartStitchGroupFragmentDoc: DocumentNode<
  ChartStitchGroupFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ChartThemesStitchGroupsThreadsFragmentDoc: DocumentNode<
  ChartThemesStitchGroupsThreadsFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Chart_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Chart_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeBrandLineFragmentDoc: DocumentNode<
  ProjectAndThemeBrandLineFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const PublicThemeThreadFragmentDoc: DocumentNode<
  PublicThemeThreadFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const PublicThemeStitchGroupFragmentDoc: DocumentNode<
  PublicThemeStitchGroupFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const PublicThemeThemesStitchGroupsThreadsFragmentDoc: DocumentNode<
  PublicThemeThemesStitchGroupsThreadsFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CollectionProductFragmentDoc: DocumentNode<
  CollectionProductFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ExploreShopsShopFragmentDoc: DocumentNode<
  ExploreShopsShopFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExploreShops_Shop' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'shops' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const OrderPageOrderItemFragmentDoc: DocumentNode<
  OrderPageOrderItemFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderPage_OrderItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'orders_items' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_public' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const BasketPaymentMethodFragmentDoc: DocumentNode<
  BasketPaymentMethodFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Basket_PaymentMethod' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaymentMethod' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last4' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expMonth' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expYear' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const AccountThemeListItemFragmentDoc: DocumentNode<
  AccountThemeListItemFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ThemeListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeFabricTypeFragmentDoc: DocumentNode<
  ProjectAndThemeFabricTypeFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'fabric_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supports_fractional_stitches' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeMottlingTypeFragmentDoc: DocumentNode<
  ProjectAndThemeMottlingTypeFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'mottling_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeFinishTypeFragmentDoc: DocumentNode<
  ProjectAndThemeFinishTypeFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'finish_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeThreadFragmentDoc: DocumentNode<
  ProjectAndThemeThreadFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeStitchGroupFragmentDoc: DocumentNode<
  ProjectAndThemeStitchGroupFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Chart_StitchGroup' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeThemesStitchGroupsThreadsFragmentDoc: DocumentNode<
  ProjectAndThemeThemesStitchGroupsThreadsFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Chart_StitchGroup' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectThemeFragmentDoc: DocumentNode<
  ProjectThemeFragment,
  unknown
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_Theme' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_theme' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_size' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'has_fractional_stitches' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
                  },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Chart_StitchGroup' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'fabric_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supports_fractional_stitches' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'mottling_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'finish_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AccountHomeDocument: DocumentNode<
  AccountHomeQuery,
  AccountHomeQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AccountHome' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_at' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'theme' },
                      value: { kind: 'ObjectValue', fields: [] },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '5' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account_ProjectListItem' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'charts_without_projects' },
            name: { kind: 'Name', value: 'charts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_not' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'themes' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'projects' },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '5' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account_ChartProductListItem' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ChartProductListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'charts' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_public' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'color' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon_file' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_url' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ProjectListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'projects' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_count' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_margin' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_padding' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'over' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'icon_file' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 's3_url' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AllCollectionsDocument: DocumentNode<
  AllCollectionsQuery,
  AllCollectionsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllCollections' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AllFreeProductsDocument: DocumentNode<
  AllFreeProductsQuery,
  AllFreeProductsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllFreeProducts' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AllProductsDocument: DocumentNode<
  AllProductsQuery,
  AllProductsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllProducts' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AllShopsDocument: DocumentNode<
  AllShopsQuery,
  AllShopsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllShops' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const AllTagsDocument: DocumentNode<
  AllTagsQuery,
  AllTagsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AllTags' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const BasketProductsForIdsDocument: DocumentNode<
  BasketProductsForIdsQuery,
  BasketProductsForIdsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Basket_ProductsForIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Basket_Product' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Basket_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const BrandLinesDocument: DocumentNode<
  BrandLinesQuery,
  BrandLinesQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BrandLines' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_lines' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'brand_name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CancelPaymentIntentDocument: DocumentNode<
  CancelPaymentIntentMutation,
  CancelPaymentIntentMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelPaymentIntent' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelPaymentIntent' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CheckEmailDocument: DocumentNode<
  CheckEmailQuery,
  CheckEmailQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exists' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CheckSubscriptionStatusDocument: DocumentNode<
  CheckSubscriptionStatusMutation,
  CheckSubscriptionStatusMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CheckSubscriptionStatus' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkSubscriptionStatus' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ClaimProductsDocument: DocumentNode<
  ClaimProductsMutation,
  ClaimProductsMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ClaimProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'claimProducts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productIds' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CollectionForSlugDocument: DocumentNode<
  CollectionForSlugQuery,
  CollectionForSlugQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CollectionForSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_collections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'themes_public',
                                  },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CompleteOrdersDocument: DocumentNode<
  CompleteOrdersQuery,
  CompleteOrdersQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompleteOrders' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'complete' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'created_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amount' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_at' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '2' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OrderPage_OrderItem' },
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items_aggregate' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'aggregate' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'count' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderPage_OrderItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'orders_items' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_public' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CompleteOrdersForProductDocument: DocumentNode<
  CompleteOrdersForProductQuery,
  CompleteOrdersForProductQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompleteOrdersForProduct' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'items' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product_id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'productId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'complete' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CreateFirstTimeSessionDocument: DocumentNode<
  CreateFirstTimeSessionMutation,
  CreateFirstTimeSessionMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateFirstTimeSession' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createFirstTimeSession' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'email' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CreatePaymentIntentDocument: DocumentNode<
  CreatePaymentIntentMutation,
  CreatePaymentIntentMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePaymentIntent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'products' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ProductInput' },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPaymentIntent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'products' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'products' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'clientSecret' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'orderId' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CreateProjectDocument: DocumentNode<
  CreateProjectMutation,
  CreateProjectMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateProject' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'project' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'projects_insert_input' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insert_projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'Variable',
                      name: { kind: 'Name', value: 'project' },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const CurrentUserRoleDocument: DocumentNode<
  CurrentUserRoleQuery,
  CurrentUserRoleQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CurrentUserRole' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUserRole' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const EndSessionDocument: DocumentNode<
  EndSessionMutation,
  EndSessionMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EndSession' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'endSession' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const FabricTypesDocument: DocumentNode<
  FabricTypesQuery,
  FabricTypesQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FabricTypes' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_types' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'supports_fractional_stitches' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const FinishTypesDocument: DocumentNode<
  FinishTypesQuery,
  FinishTypesQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinishTypes' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_types' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const HomeDocument: DocumentNode<HomeQuery, HomeQueryVariables> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Home' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'free_products' },
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Collection_Product' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featured_collection_1' },
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'featured-1',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_collections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '6' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'themes_public',
                                  },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featured_collection_2' },
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'featured-2',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_collections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '8' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'themes_public',
                                  },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featured_collection_3' },
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'featured-3',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_collections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '8' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'themes_public',
                                  },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'featured_shops' },
            name: { kind: 'Name', value: 'shops' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '2' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'products_aggregate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'StringValue',
                              value: 'chartsy',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExploreShops_Shop' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'themes_public' },
                            value: { kind: 'ObjectValue', fields: [] },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '9' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Collection_Product' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'explore_shops' },
            name: { kind: 'Name', value: 'shops' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '5' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'IntValue', value: '2' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'products_aggregate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_neq' },
                            value: {
                              kind: 'StringValue',
                              value: 'chartsy',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExploreShops_Shop' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'ListValue',
                              values: [
                                {
                                  kind: 'StringValue',
                                  value: 'home',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'seasons',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'animals',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'space',
                                  block: false,
                                },
                                {
                                  kind: 'StringValue',
                                  value: 'holidays',
                                  block: false,
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExploreShops_Shop' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'shops' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const HowItWorksDocument: DocumentNode<
  HowItWorksQuery,
  HowItWorksQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HowItWorks' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'free_products' },
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'price' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'IntValue', value: '0' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Collection_Product' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'collections' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'StringValue',
                              value: 'featured-1',
                              block: false,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_collections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'themes_public',
                                  },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const IncompleteOrderDocument: DocumentNode<
  IncompleteOrderQuery,
  IncompleteOrderQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'IncompleteOrder' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'status' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'EnumValue', value: 'incomplete' },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const MottlingTypesDocument: DocumentNode<
  MottlingTypesQuery,
  MottlingTypesQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MottlingTypes' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_types' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const OrderDocument: DocumentNode<OrderQuery, OrderQueryVariables> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Order' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orderId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'orders_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'orderId' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amount' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_at' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'status' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'OrderPage_OrderItem' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OrderPage_OrderItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'orders_items' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_public' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const PaymentMethodsDocument: DocumentNode<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PaymentMethods' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'paymentMethods' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Basket_PaymentMethod' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Basket_PaymentMethod' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PaymentMethod' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'last4' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expMonth' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'expYear' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
  ],
};
export const ProductAndThemesForProductIdDocument: DocumentNode<
  ProductAndThemesForProductIdQuery,
  ProductAndThemesForProductIdQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductAndThemesForProductId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productId' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'product' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: '_eq' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'productId' },
                                  },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'is_published' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: { kind: 'BooleanValue', value: true },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '25' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account_ThemeListItem' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ThemeListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProductForSlugDocument: DocumentNode<
  ProductForSlugQuery,
  ProductForSlugQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProductForSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'products' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'currency' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'description' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'price' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'order_by' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'position' },
                            value: { kind: 'EnumValue', value: 'asc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '10' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'file' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_url' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_tags' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tag' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'has_fractional_stitches',
                        },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '3' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'themes_stitch_groups_threads',
                        },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'PublicTheme_ThemesStitchGroupsThreads',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'color' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'background_file' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_url' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon_file' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_url' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'products' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'where' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'chart' },
                                  value: {
                                    kind: 'ObjectValue',
                                    fields: [
                                      {
                                        kind: 'ObjectField',
                                        name: {
                                          kind: 'Name',
                                          value: 'product',
                                        },
                                        value: {
                                          kind: 'ObjectValue',
                                          fields: [
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'slug',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [
                                                  {
                                                    kind: 'ObjectField',
                                                    name: {
                                                      kind: 'Name',
                                                      value: '_neq',
                                                    },
                                                    value: {
                                                      kind: 'Variable',
                                                      name: {
                                                        kind: 'Name',
                                                        value: 'slug',
                                                      },
                                                    },
                                                  },
                                                ],
                                              },
                                            },
                                            {
                                              kind: 'ObjectField',
                                              name: {
                                                kind: 'Name',
                                                value: 'themes_public',
                                              },
                                              value: {
                                                kind: 'ObjectValue',
                                                fields: [],
                                              },
                                            },
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                },
                              ],
                            },
                          },
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'limit' },
                            value: { kind: 'IntValue', value: '3' },
                          },
                        ],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectAndThemeDocument: DocumentNode<
  ProjectAndThemeQuery,
  ProjectAndThemeQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectAndTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_at' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'theme' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Project_Theme' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Chart_StitchGroup' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'fabric_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supports_fractional_stitches' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'mottling_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'finish_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_Theme' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_theme' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_size' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'has_fractional_stitches' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
                  },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ProjectsAndChartsWithoutProjectsDocument: DocumentNode<
  ProjectsAndChartsWithoutProjectsQuery,
  ProjectsAndChartsWithoutProjectsQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ProjectsAndChartsWithoutProjects' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'projects' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'desc' },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'theme' },
                      value: { kind: 'ObjectValue', fields: [] },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account_ProjectListItem' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            alias: { kind: 'Name', value: 'charts_without_projects' },
            name: { kind: 'Name', value: 'charts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: '_not' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'themes' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: { kind: 'Name', value: 'projects' },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Account_ChartProductListItem' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ChartProductListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'charts' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'product' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_public' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shop' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'color' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'icon_file' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 's3_url' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_public' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'limit' },
                      value: { kind: 'IntValue', value: '1' },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Account_ProjectListItem' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'projects' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'theme' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_count' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_margin' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_padding' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'over' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'height' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'width' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'icon_file' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 's3_url' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const RefreshOrderStatusDocument: DocumentNode<
  RefreshOrderStatusMutation,
  RefreshOrderStatusMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RefreshOrderStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refreshOrderStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const SendAuthCodeDocument: DocumentNode<
  SendAuthCodeMutation,
  SendAuthCodeMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendAuthCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'intent' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendAuthCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'intent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'intent' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'success' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ShopForSlugDocument: DocumentNode<
  ShopForSlugQuery,
  ShopForSlugQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ShopForSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'background_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'themes_public' },
                            value: { kind: 'ObjectValue', fields: [] },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Collection_Product' },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ShopsDocument: DocumentNode<ShopsQuery, ShopsQueryVariables> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Shops' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shops' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '5' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'order_by' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'products_aggregate' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'count' },
                            value: { kind: 'EnumValue', value: 'desc' },
                          },
                        ],
                      },
                    },
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'updated_at' },
                      value: { kind: 'EnumValue', value: 'asc' },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExploreShops_Shop' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExploreShops_Shop' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'shops' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'icon_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const SignUpDocument: DocumentNode<
  SignUpMutation,
  SignUpMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SignUp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productIds' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signUp' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'name' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'name' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'productIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'productIds' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const TagForSlugDocument: DocumentNode<
  TagForSlugQuery,
  TagForSlugQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TagForSlug' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'tags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'slug' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_tags' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'where' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'product' },
                            value: {
                              kind: 'ObjectValue',
                              fields: [
                                {
                                  kind: 'ObjectField',
                                  name: {
                                    kind: 'Name',
                                    value: 'themes_public',
                                  },
                                  value: { kind: 'ObjectValue', fields: [] },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'product' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'Collection_Product',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Collection_Product' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'products' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'slug' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shop' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'slug' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'icon_file' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 's3_url' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart_public' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_public' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'IntValue', value: '1' },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'PublicTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PublicTheme_ThemesStitchGroupsThreads' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PublicTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ThemeImage_ThemePublic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_public' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'background_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'foreground_file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ThemeForColorResetDocument: DocumentNode<
  ThemeForColorResetQuery,
  ThemeForColorResetQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ThemeForColorReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mottling_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mottling_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stitch_group_id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thread_id' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ThemeForIdToCloneDocument: DocumentNode<
  ThemeForIdToCloneQuery,
  ThemeForIdToCloneQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ThemeForIdToClone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'background_file_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'chart_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_count' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_margin' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_padding' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_size' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'foreground_file_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mottling_color' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mottling_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'over' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coverage' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thread_id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stitch_group_id' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ThemeForSizeResetDocument: DocumentNode<
  ThemeForSizeResetQuery,
  ThemeForSizeResetQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ThemeForSizeReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_count' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_margin' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_padding' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'fabric_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_size' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'finish_type_id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'over' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'coverage' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'stitch_group_id' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const ThreadsForBrandLineDocument: DocumentNode<
  ThreadsForBrandLineQuery,
  ThreadsForBrandLineQueryVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ThreadsForBrandLine' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'brandLineId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'threads' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'brand_line_id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_eq' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'brandLineId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const UpdateThemeDocument: DocumentNode<
  UpdateThemeMutation,
  UpdateThemeMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateTheme' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'themeId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'themeSetInput' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'themes_set_input' },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'themesStitchGroupsThreadsOnConflict',
            },
          },
          type: {
            kind: 'NamedType',
            name: {
              kind: 'Name',
              value: 'themes_stitch_groups_threads_on_conflict',
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: {
              kind: 'Name',
              value: 'themesStitchGroupsThreadsInsertInput',
            },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: {
                    kind: 'Name',
                    value: 'themes_stitch_groups_threads_insert_input',
                  },
                },
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'insert_themes_stitch_groups_threads',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'objects' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'themesStitchGroupsThreadsInsertInput',
                  },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'on_conflict' },
                value: {
                  kind: 'Variable',
                  name: {
                    kind: 'Name',
                    value: 'themesStitchGroupsThreadsOnConflict',
                  },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_themes_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'themeId' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'themeSetInput' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Project_Theme' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Chart_StitchGroup' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'fabric_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'supports_fractional_stitches' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'mottling_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'finish_types' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'file' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 's3_url' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Project_Theme' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'over' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_count' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'parent_theme' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_margin' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_padding' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'fabric_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_FabricType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mottling_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_MottlingType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_size' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'finish_type' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_FinishType' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'chart' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'has_fractional_stitches' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'height' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'width' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'product' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shop' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'slug' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: {
                    kind: 'Name',
                    value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
                  },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const UpdateThemesStitchGroupsThreadsDocument: DocumentNode<
  UpdateThemesStitchGroupsThreadsMutation,
  UpdateThemesStitchGroupsThreadsMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateThemesStitchGroupsThreads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'uuid' },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'set' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'themes_stitch_groups_threads_set_input',
              },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'update_themes_stitch_groups_threads',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'set' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'where' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: '_in' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'ids' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'returning' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
                        },
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Chart_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'lines' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'points' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'stitch_groups' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'amount' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'Chart_StitchGroup' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'ProjectAndTheme_ThemesStitchGroupsThreads',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'themes_stitch_groups_threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverage' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thread' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stitch_group' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_StitchGroup' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'brand_lines' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_name' },
            arguments: [],
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProjectAndTheme_Thread' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'threads' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'color' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'name' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reference_id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'brand_line' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProjectAndTheme_BrandLine' },
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const UpdateUserFromSettingsDocument: DocumentNode<
  UpdateUserFromSettingsMutation,
  UpdateUserFromSettingsMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateUserFromSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'uuid' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'props' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'users_set_input' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'update_users_by_pk' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pk_columns' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: '_set' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'props' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_subscribed' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const UserDocument: DocumentNode<UserQuery, UserQueryVariables> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'User' },
      variableDefinitions: [],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'users' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'created_at' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'email' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'is_subscribed' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const VerifyAuthCodeDocument: DocumentNode<
  VerifyAuthCodeMutation,
  VerifyAuthCodeMutationVariables
> = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyAuthCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyAuthCode' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'code' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'code' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'email' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'intent' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
/* eslint-enable import/no-extraneous-dependencies, import/newline-after-import, @typescript-eslint/naming-convention */
