import apolloClient from '../apolloClient';
import { CheckEmailDocument } from '../generatedGraphql';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function checkEmail(
  email: string,
): Promise<{ email: string; exists: boolean }> {
  const { data } = await apolloClient.query({
    fetchPolicy: 'no-cache',
    query: CheckEmailDocument,
    variables: { email },
  });

  return { email, exists: Boolean(data?.checkEmail.exists) };
}

export default throwKnownApiErrors(checkEmail);
