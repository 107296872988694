// Inspired by a similar check in LocalForage
import isSsr from './isSsr';

// Check if localStorage throws when saving an item
function checkIfLocalStorageThrows() {
  const localStorageTestKey = '_local_storage_test';

  try {
    localStorage.setItem(localStorageTestKey, 'test');
    localStorage.removeItem(localStorageTestKey);
    return false;
  } catch {
    return true;
  }
}

// Check if localStorage is usable and allows to save an item
// This method checks if localStorage is usable in Safari Private Browsing
// mode, or in any other case where the available quota for localStorage
// is 0 and there wasn't any saved items yet.
function isLocalStorageUsable() {
  return !checkIfLocalStorageThrows() || localStorage.length > 0;
}

export const storageLikeObject = {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getItem: (_key: string) => null,
  setItem: (_key: string, _value: string) => {},
  removeItem: (_key: string) => {},
  /* eslint-enable @typescript-eslint/no-unused-vars */
};

export default function getStorage() {
  return isSsr()
    ? storageLikeObject
    : isLocalStorageUsable()
    ? localStorage
    : sessionStorage;
}
