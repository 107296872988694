import React from 'react';

// create context with no upfront defaultValue without having to do undefined
// check all the time
// via https://git.io/JeNfG
export default function createTypeSafeContext<A>() {
  // eslint-disable-next-line unicorn/no-useless-undefined
  const context = React.createContext<A | undefined>(undefined);
  function useContext() {
    const value = React.useContext(context);
    if (!value)
      throw new Error('useContext must be inside a Provider with a value');
    return value;
  }
  return [useContext, context.Provider] as const; // make TypeScript infer a tuple, not an array of union types
}
