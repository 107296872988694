import { ApolloError } from '@apollo/client';

import { ErrorCodes } from '@chartsy/shared/types';

import ApiError from './error';

const API_ERROR_CODES = Object.values(ErrorCodes);

function errorForAnyCode(error: ApolloError) {
  return error.graphQLErrors.find(({ extensions }) =>
    API_ERROR_CODES.includes(extensions?.code),
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function throwKnownApiErrors<A extends any[], R>(
  func: (...args: A) => Promise<R>,
): (...funcArgs: A) => Promise<R> {
  /* eslint-enable @typescript-eslint/no-explicit-any */
  return async (...args: A): Promise<R> => {
    try {
      return await func(...args);
    } catch (error) {
      if (error instanceof ApolloError) {
        const graphQLError = errorForAnyCode(error);
        if (graphQLError) {
          throw new ApiError(graphQLError);
        }
      }
      throw error;
    }
  };
}
