import apolloClient from '../apolloClient';
import { EndSessionDocument } from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function endSession() {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: EndSessionDocument,
  });

  if (!data?.endSession.success) {
    throw new ApiError('Unknown error ending session');
  }
}

export default throwKnownApiErrors(endSession);
