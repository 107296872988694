import apolloClient from '../apolloClient';
import { CreateFirstTimeSessionDocument } from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function createFirstTimeSession(email: string) {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: CreateFirstTimeSessionDocument,
    variables: { email },
  });

  if (!data) {
    throw new ApiError('Unknown error creating first time session');
  }

  return data.createFirstTimeSession.user;
}

export default throwKnownApiErrors(createFirstTimeSession);
