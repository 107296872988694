import { GraphQLError } from 'graphql';

import { ErrorCodes } from '@chartsy/shared/types';

export default class ApiError extends Error {
  graphQLError?: GraphQLError;

  code?: ErrorCodes;

  constructor(graphQLError: GraphQLError | string) {
    if (typeof graphQLError === 'string') {
      super(graphQLError);
    } else {
      super(graphQLError.message || 'Unknown server error');
      this.graphQLError = graphQLError;
      this.code = graphQLError.extensions?.code as ErrorCodes;
    }
  }
}
