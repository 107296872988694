import { from, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SentryLink } from 'apollo-link-sentry';

import { API_URL, StorageKeys } from './constants';
import {
  CurrentUserRoleDocument,
  EndSessionDocument,
  UserRoles,
} from './generatedGraphql';
import getStorage from './utils/getStorage';
import isSsr from './utils/isSsr';

const STORAGE = getStorage();

const onErrorLink = onError(({ graphQLErrors }) => {
  // validation-failed is triggered when a request is made the user doesn't
  // have access to. Remove the marker for their session and reload.
  if (
    graphQLErrors?.some(
      ({ extensions }) => extensions?.code === 'validation-failed',
    )
  ) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
      const currentUserRoleQuery = await apolloClient.query({
        query: CurrentUserRoleDocument,
      });
      if (
        ![UserRoles.Admin, UserRoles.User].includes(
          currentUserRoleQuery.data?.currentUserRole?.role,
        )
      ) {
        // Duplicated code for endSession here to avoid dependency cycle
        STORAGE.removeItem(StorageKeys.User);
        STORAGE.removeItem(StorageKeys.BasketItems);

        try {
          // eslint-disable-next-line no-use-before-define, @typescript-eslint/no-use-before-define
          await apolloClient.mutate({
            fetchPolicy: 'no-cache',
            mutation: EndSessionDocument,
          });
        } catch {
          // TODO log error
        }
        window.location.reload();
      }
    })();
  }
  // TODO log other errors to service
});

const httpLink = new HttpLink({
  credentials: 'same-origin',
  headers: process.env.HASURA_GRAPHQL_ADMIN_SECRET
    ? {
        'X-Hasura-Admin-Secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET,
      }
    : undefined,
  uri: API_URL,
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  defaultOptions: isSsr()
    ? {
        watchQuery: { fetchPolicy: 'no-cache' },
        query: { fetchPolicy: 'no-cache' },
      }
    : undefined,
  link: isSsr()
    ? from([new SentryLink(), httpLink])
    : from([new SentryLink(), onErrorLink, httpLink]),
  ssrMode: isSsr(),
});

export default apolloClient;
