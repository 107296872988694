import apolloClient from '../apolloClient';
import { SendAuthCodeDocument } from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function sendAuthCode({
  email,
  intent,
}: {
  email: string;
  intent: string;
}) {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: SendAuthCodeDocument,
    variables: { email, intent },
  });

  if (!data?.sendAuthCode.success) {
    throw new ApiError('Unknown error sending code');
  }
}

export default throwKnownApiErrors(sendAuthCode);
