import { useEffect, useState } from 'react';
import { useStorageState as useStorageHooksState } from 'react-storage-hooks';

import getStorage from './getStorage';

const STORAGE = getStorage();

export default function useLazyStorageState<T>(
  key: string,
  defaultState: T | (() => T),
) {
  const [state, setState] = useStorageHooksState(STORAGE, key, defaultState);
  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  return isFirstRender
    ? typeof defaultState === 'function'
      ? // Extra cast needed due to this bug https://github.com/microsoft/TypeScript/issues/37663
        ([(defaultState as () => T)(), setState] as const)
      : ([defaultState, setState] as const)
    : ([state, setState] as const);
}
