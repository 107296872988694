import apolloClient from '../apolloClient';
import { SignUpDocument } from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function signUp(variables: {
  email: string;
  name: string;
  productIds: string[];
}) {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: SignUpDocument,
    variables,
  });

  if (!data) {
    throw new ApiError('Unknown error signing up');
  }

  return data.signUp;
}

export default throwKnownApiErrors(signUp);
