import * as Sentry from '@sentry/node';
import React, { ReactNode, useEffect, useRef } from 'react';

import { pathForResource } from '@chartsy/shared/utils';

import * as api from '../api';
import { StorageKeys } from '../constants';
import { User } from '../generatedGraphql';
import createTypeSafeContext from '../utils/createTypeSafeContext';
import getStorage from '../utils/getStorage';
import useLazyStorageState from '../utils/useLazyStorageState';

const STORAGE = getStorage();

export type StoredShopUser = Pick<User, 'id' | 'email'> | null;
export type ShopUser = StoredShopUser & {
  signedInDuringThisSession: boolean;
};

export interface Auth {
  createFirstTimeSession: typeof api.createFirstTimeSession;
  endSession: () => void;
  sendAuthCode: typeof api.sendAuthCode;
  user?: ShopUser;
  verifyAuthCode: typeof api.verifyAuthCode;
}

const [useAuth, AuthProvider] = createTypeSafeContext<Auth>();
export { useAuth };

function useProvideAuth() {
  const [user, setUser] = useLazyStorageState<StoredShopUser>(
    StorageKeys.User,
    null,
  );

  // Track if a user signed in during this session (vs already being signed in)
  const userSignedInRef = useRef(false);

  // Track if we ever had a user, and if it goes missing -- reload the page
  // because it was a sign out.
  const hadUserRef = useRef(false);
  hadUserRef.current = !!user || hadUserRef.current;
  useEffect(() => {
    if (!user && hadUserRef.current) {
      Sentry.configureScope((scope) => scope.setUser(null));
      window.location.reload();
    } else if (user) {
      Sentry.setUser({ email: user.email, id: user.id });
    }
  }, [user]);

  const verifyAuthCode = async (email: string, code: number) => {
    const response = await api.verifyAuthCode(email, code);
    userSignedInRef.current = true;
    setUser(response.user);
    return response;
  };

  const createFirstTimeSession = async (email: string) => {
    const newUser = await api.createFirstTimeSession(email);
    userSignedInRef.current = true;
    setUser(newUser);
    return newUser;
  };

  const sendAuthCode = async (
    params: Parameters<typeof api.sendAuthCode>[0],
  ) => {
    if (user) {
      throw new Error('Already signed in');
    }
    await api.sendAuthCode(params);
  };

  const endSession = async () => {
    await api.endSession();
    Object.values(StorageKeys).forEach((key) => {
      STORAGE.removeItem(key);
    });
    window.location.pathname = pathForResource('signedOut');
  };

  return {
    createFirstTimeSession,
    endSession,
    sendAuthCode,
    user: user
      ? {
          ...user,
          signedInDuringThisSession: userSignedInRef.current,
        }
      : undefined,
    verifyAuthCode,
  };
}

export const ProvideAuth = ({ children }: { children?: ReactNode }) => {
  const auth = useProvideAuth();

  return <AuthProvider value={auth}>{children}</AuthProvider>;
};
