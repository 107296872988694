import apolloClient from '../apolloClient';
import { CancelPaymentIntentDocument } from '../generatedGraphql';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function cancelPaymentIntent() {
  await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: CancelPaymentIntentDocument,
  });
  // We don't care if this fails, and it might.
}

export default throwKnownApiErrors(cancelPaymentIntent);
