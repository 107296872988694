import apolloClient from '../apolloClient';
import { VerifyAuthCodeDocument } from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function verifyAuthCode(email: string, code: number) {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: VerifyAuthCodeDocument,
    variables: { code, email },
  });

  if (!data) {
    throw new ApiError('Unknown error verifying code');
  }

  return data.verifyAuthCode;
}

export default throwKnownApiErrors(verifyAuthCode);
