import apolloClient from '../apolloClient';
import {
  RefreshOrderStatusDocument,
  RefreshOrderStatusMutationVariables,
} from '../generatedGraphql';
import ApiError from './error';
import throwKnownApiErrors from './throwApiKnownApiErrors';

async function refreshOrderStatus({ id }: RefreshOrderStatusMutationVariables) {
  const { data } = await apolloClient.mutate({
    fetchPolicy: 'no-cache',
    mutation: RefreshOrderStatusDocument,
    variables: { id },
  });

  if (!data?.refreshOrderStatus.success) {
    throw new ApiError('Unknown error ending session');
  }
}

export default throwKnownApiErrors(refreshOrderStatus);
